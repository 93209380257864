import React from "react";
import axios from '../Axios'
import Switch from "react-switch";
import { Card, Stack, TextStyle, TextField, Tag, RadioButton, Button, Heading, Layout, List, Banner, Form, Link } from '@shopify/polaris';
import ToastMessage from '../Common/ToastMessage';
import SkeletonPageComp from './SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';
const BUILD_VERSION_EXTENSION = process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class LoginToViewPrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: false,
            page_loaded: false,
            customer_group: "has_account",
            temp__customer_tag: "",
            customer_tags: [],
            label_for_logged_in: "login to view price",
            link_for_logged_in: "account",
            html__label_for_logged_in : '<a href="/account" class="wsaio_ltv_label">login to view price</a>',
            label_for_tagged_based: "Contact us to view price",
            link_for_tagged_based: "pages/register",
            html__label_for_tagged_based : '<a href="/pages/register" class="wsaio_ltv_label">Contact us to view price</a>',
            errorList: [],
            saveButtonLoading: false,
            ltv_label_type : "text",
            ltv_tagged_label_type : "text",
            ltv_customer_type: "logged_in",
            const_data: null //for comparing data to perform certain operations such as toggle show/hide Save Button
        };
    }

    componentDidMount() {
        this.getDataForEdit();
    }

    getDataForEdit() {
        axios.get('/app/v238/get-login-to-view-price/' + this.props.shop)
            .then((response) => {
                if (response && response.data && response.data.status === 1) {
                    if (response.data.ltvPrice) {
                        var ltvPrice = response.data.ltvPrice;
                        let statesJson = {
                            enabled: ltvPrice.enabled,
                            customer_tags: ltvPrice.customer_tags,
                            customer_group: ltvPrice.customer_group,
                            label_for_logged_in: ltvPrice.label_for_logged_in,
                            link_for_logged_in: ltvPrice.link_for_logged_in,
                            html__label_for_logged_in: ltvPrice.html__label_for_logged_in,
                            label_for_tagged_based: ltvPrice.label_for_tagged_based,
                            link_for_tagged_based: ltvPrice.link_for_tagged_based,
                            html__label_for_tagged_based: ltvPrice.html__label_for_tagged_based,
                            ltv_label_type: ltvPrice.ltv_label_type,
                            ltv_tagged_label_type: ltvPrice.ltv_tagged_label_type,
                        };

                        //Set the data to set State and const_data for data comparison upon any change using detectChanges() function
                        let const_data = JSON.parse(JSON.stringify(statesJson));
                        statesJson.const_data = const_data;
                        this.setState(statesJson);

                        //Update this value seperately because we donot have to compare it in this.detectChanges() function
                        this.setState({ltv_customer_type: ltvPrice.ltv_customer_type})
                        this.setState({ page_loaded: true });
                    }
                    else{
                        this.setState({ page_loaded: true });
                    }
                }
                else {
                    this.setState({ page_loaded: true });
                }
            })
            .catch(error => {
                this.showToastMessage(error, true);
                this.setState({ page_loaded: true });
            });
    }

    detectChanges() {
        let constData = this.state.const_data;

        /* this will detect if anything changes on page */

        /**
        | const_data: constant data, that should not be change
        | compare_data: data to compare, is not match, data is changed
        */
        const _comparechange = function (const_data, compare_data) {

            /* Function that will compare both objects */
            let deepCompare = () => {
                var i, l, leftChain, rightChain;

                function compare2Objects(x, y) {
                    var p;

                    // remember that NaN === NaN returns false
                    // and isNaN(undefined) returns true
                    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                        return true;
                    }

                    // Compare primitives and functions.     
                    // Check if both arguments link to the same object.
                    // Especially useful on the step where we compare prototypes
                    if (x === y) {
                        return true;
                    }

                    // Works in case when functions are created in constructor.
                    // Comparing dates is a common scenario. Another built-ins?
                    // We can even handle functions passed across iframes
                    if ((typeof x === 'function' && typeof y === 'function') ||
                        (x instanceof Date && y instanceof Date) ||
                        (x instanceof RegExp && y instanceof RegExp) ||
                        (x instanceof String && y instanceof String) ||
                        (x instanceof Number && y instanceof Number)) {
                        return x.toString() === y.toString();
                    }

                    // At last checking prototypes as good as we can
                    if (!(x instanceof Object && y instanceof Object)) {
                        return false;
                    }

                    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                        return false;
                    }

                    if (x.constructor !== y.constructor) {
                        return false;
                    }

                    if (x.prototype !== y.prototype) {
                        return false;
                    }

                    // Check for infinitive linking loops
                    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                        return false;
                    }

                    // Quick checking of one object being a subset of another.
                    // todo: cache the structure of arguments[0] for performance
                    for (p in y) {
                        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                            return false;
                        } else if (typeof y[p] !== typeof x[p]) {
                            return false;
                        }
                    }

                    for (p in x) {
                        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                            return false;
                        } else if (typeof y[p] !== typeof x[p]) {
                            return false;
                        }

                        switch (typeof (x[p])) {
                            case 'object':
                            case 'function':

                                leftChain.push(x);
                                rightChain.push(y);

                                if (!compare2Objects(x[p], y[p])) {
                                    return false;
                                }

                                leftChain.pop();
                                rightChain.pop();
                                break;

                            default:
                                if (x[p] !== y[p]) {
                                    return false;
                                }
                                break;
                        }
                    }

                    return true;
                }

                if (arguments.length <= 1) {
                    //return true; //Die silently? Don't know how to handle such case, please help...
                    // eslint-disable-next-line
                    throw "Need two or more arguments to compare";
                }

                for (i = 1, l = arguments.length; i < l; i++) {

                    leftChain = []; //Todo: this can be cached
                    rightChain = [];

                    if (!compare2Objects(arguments[0], arguments[i])) {
                        return false;
                    }
                }

                return true;
            }

            return !deepCompare(const_data, compare_data);
        };

        /* Match whole data */
        const compareData = {
            enabled: this.state.enabled,
            customer_tags: this.state.customer_tags,
            customer_group: this.state.customer_group,
            label_for_logged_in: this.state.label_for_logged_in,
            link_for_logged_in: this.state.link_for_logged_in,
            html__label_for_logged_in: this.state.html__label_for_logged_in,
            label_for_tagged_based: this.state.label_for_tagged_based,
            link_for_tagged_based: this.state.link_for_tagged_based,
            html__label_for_tagged_based: this.state.html__label_for_tagged_based,
            ltv_label_type: this.state.ltv_label_type,
            ltv_tagged_label_type: this.state.ltv_tagged_label_type
        };
        return _comparechange(constData, compareData);
    }

    hasFeatures(value){
        var hasF = false;
        if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
            hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
        }
        return hasF;
    }

    saveData() {
        if(!this.hasFeatures("login_to_view_prices")){
            this.showToastMessage("Please subscribe to this addon to use it", true);
            return;
        }
        this.setState({ saveButtonLoading: true })
        var data = {
            shop: this.props.shop,
            enabled: this.state.enabled,
            customer_tags: this.state.customer_tags,
            customer_group: this.state.customer_group,
            label_for_logged_in: this.state.label_for_logged_in,
            link_for_logged_in: this.state.link_for_logged_in,
            html__label_for_logged_in: this.state.html__label_for_logged_in,
            label_for_tagged_based: this.state.label_for_tagged_based,
            link_for_tagged_based: this.state.link_for_tagged_based,
            html__label_for_tagged_based: this.state.html__label_for_tagged_based,
            ltv_label_type: this.state.ltv_label_type,
            ltv_tagged_label_type: this.state.ltv_tagged_label_type,
            ltv_customer_type: this.state.ltv_customer_type
        };
        //Validate data to find errors
        let validation = this.doValidation();
        let errorList = validation.errors;
        if (validation.isValid) {
            this.setState({ errorList: errorList }); //Show no errors upon new save
            axios.post('/app/v238/save-login-to-view-price', data).then(response => {
                if (response && response.data) {
                    if (response.data.status === 1) {
                        //Success in saving Data in the DB
                        this.showToastMessage(response.data.message);
                    }
                    else {
                        this.showToastMessage(response.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("An error occurred", true);
                }

                this.showToastMessage("An error occurred", true);
                this.setState({ saveButtonLoading: false })
                this.getDataForEdit();
            })
                .catch(errors => {
                    this.showToastMessage("An error occurred", true);
                    this.setState({ saveButtonLoading: false })
                })
        }
        else {
            //Show errors and scrollToTop
            let msg = `There ${errorList.length > 1 ? "are" : "is"} ${errorList.length} ${errorList.length > 1 ? "errors" : "error"}`;
            this.toast(msg, true);
            this.setState({ saveButtonLoading: false, errorList: errorList })
            this.scrollToTop();
        }
    }

    renderIncludedcustomer_tags() {
        let customer_tags = this.state.customer_tags;
        return customer_tags.map((tag, j) => {
            return <Tag key={j}
                onRemove={() => {
                    var customer_tags = [...this.state.customer_tags];
                    customer_tags.splice(j, 1);
                    this.setState({ customer_tags: customer_tags });
                }}
            >
                {
                    tag === "has_account" ?
                        "All Login Customers"
                        : tag === "tag_based" ?
                            "Specific Tagged Customers"
                            : tag
                }
            </Tag>
        })
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    addIndcustomer_tags() {
        var customer_tags = this.state.customer_tags;
        var temp__customer_tag = this.state.temp__customer_tag;
        try {
            temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
        } catch (e) { }
        if (temp__customer_tag.trim() === "") return;
        try {
            if (-1 === customer_tags.findIndex(x => x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())) {
                customer_tags.push(temp__customer_tag.trim());
            }
            else {
                this.toast("Tag already exists", true)
            }
        } catch (e) { }
        this.setState({ customer_tags, temp__customer_tag: "", });
    }

    doValidation() {
        let isValid = true;
        let errors = [];
        if (this.state.customer_group === "tag_based") {
            if (this.state.customer_tags.length === 0) {
                isValid = false;
                errors.push("Add atleast one customer group");
            }
        }
        if (this.state.enabled) {
            if(this.state.customer_group === "has_account" && this.state.ltv_label_type === 'text'){
                if(this.state.link_for_logged_in === null || this.state.link_for_logged_in.trim() === ""){
                    isValid = false;
                    errors.push("Redirect Link can not be blank");
                }
            }
            if(this.state.customer_group === "tag_based" && this.state.ltv_tagged_label_type === 'text'){
                if(this.state.link_for_tagged_based === null || this.state.link_for_tagged_based.trim() === ""){
                    isValid = false;
                    errors.push("Redirect Link can not be blank");
                }
            }
            if(this.state.customer_group === "has_account" && this.state.ltv_label_type === 'html'){
                if(this.state.html__label_for_logged_in === null || this.state.html__label_for_logged_in.trim() === ""){
                    isValid = false;
                    errors.push("HTML Field cannot be empty");
                }
            }
            if(this.state.customer_group === "tag_based" && this.state.ltv_tagged_label_type === 'html'){
                if(this.state.html__label_for_tagged_based === null || this.state.html__label_for_tagged_based.trim() === ""){
                    isValid = false;
                    errors.push("HTML Field cannot be empty");
                }
            }
        }

        return {
            errors: errors,
            isValid: isValid
        };
    }

    toast(msg, error) {
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error
        });
    }

    showToastMessage(msg, error) {
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error || false,
        });
    }

    dissmissToast = () => {
        this.setState({
            showToast: false,
        });
    }

    render() {
        const { enabled, customer_group, ltv_label_type, ltv_tagged_label_type } = this.state;
        const changes_detected = this.detectChanges();

        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1 ? "are" : "is"} ${this.state.errorList.length} ${this.state.errorList.length > 1 ? "errors" : "error"}`}>
                <List type="number">
                    {
                        this.state.errorList.map((error, iN) => {
                            return <List.Item key={iN}>{error}</List.Item>
                        })
                    }
                </List>
            </Banner>
        </div>

        var toast = this.state.showToast ? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={() => { this.setState({ showToast: false }) }} />) : (null);

        const section1 = <Card title="Login to View Prices">
            <Card.Section>
                <Stack>
                    <Stack.Item fill>
                        <TextStyle>Enable Login to view Price Feature</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                        <TextStyle>{enabled ? "Enabled" : "Disabled"}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                        <Switch 
                            checked = {enabled}
                            onChange={(enabled) => this.setState({ enabled })}
                            height={20}
                            width={42}
                        />
                    </Stack.Item>                    
                    
                </Stack>
            </Card.Section>
        </Card>

        const section2 = <Card title="Select customers to show price">
            <Card.Section>
                <div className="wsaio_discount_grid">
                    <div className="wsaio_g_left">
                        <Stack spacing="tight" vertical>
                            <RadioButton
                                label="All logged-in customers"
                                checked={"has_account" === customer_group}
                                id="has_account"
                                name="customer_group"
                                onChange={(v, id) => {
                                    this.setState({ customer_group: id })
                                    this.setState({ ltv_customer_type: "logged_in"})
                                }}
                            />

                            <RadioButton
                                label="Logged-in customers with matching tag (Recommended)"
                                checked={"tag_based" === customer_group}
                                id="tag_based"
                                name="customer_group"
                                onChange={(v, id) => {
                                    this.setState({ customer_group: id })
                                    this.setState({ ltv_customer_type: "tagged"})
                                }}
                            />

                            {
                                "tag_based" === customer_group && <div className="ws-card">
                                    <Stack vertical spacing="tight">
                                        {
                                            <Form
                                                onSubmit={() => {
                                                    this.addIndcustomer_tags();
                                                }}
                                            >
                                                <TextField
                                                    error={this.state.customer_tags.length < 1 && "Add atleast one customer tag!"}
                                                    label="Enter customers tag"
                                                    value={this.state.temp__customer_tag}
                                                    onChange={(v) => {
                                                        this.setState({ temp__customer_tag: v })
                                                    }}
                                                    connectedRight={<div style={{ marginLeft: "5px" }}>
                                                        <Button primary spacing="5px"
                                                            disabled={!this.state.temp__customer_tag}
                                                            onClick={() => {
                                                                this.addIndcustomer_tags();
                                                            }}
                                                        >
                                                            Add Tag
                                                        </Button>
                                                    </div>}
                                                />
                                            </Form>
                                        }

                                        {
                                            this.state.customer_tags.length > 0 && customer_group === "tag_based" &&
                                            <Stack>{this.renderIncludedcustomer_tags()}</Stack>
                                        }
                                    </Stack>
                                </div>
                            }
                        </Stack>
                    </div>
                    <div className="wsaio_g_right">
                        {
                            customer_group === "has_account" &&
                            <div>
                                <Heading>Only Logged in Customers</Heading>
                                <p>Price will be shown to All logged in customers</p>
                            </div>
                        }
                        {
                            customer_group === "tag_based" &&
                            <div>
                                <Heading>Only Tagged Customers</Heading>
                                <p>Price will only be shown to the customers with the specified tags.</p>
                            </div>
                        }
                    </div>
                </div>
            </Card.Section>
        </Card>

        const section3 = <Card>
            <Card.Section>
                <Stack vertical spacing = "loose">
                    <Stack>
                        <Stack.Item fill>
                            <Heading>For Non-Tag logged in Customers</Heading>
                        </Stack.Item>
                        <Stack.Item>
                            <TextStyle>Turn on HTML Mode</TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                            <Switch 
                                checked = {ltv_tagged_label_type === 'html'}
                                onChange={() => {
                                    if(ltv_tagged_label_type === 'html'){
                                        this.setState({ltv_tagged_label_type: 'text'});
                                    }
                                    else{
                                        this.setState({ltv_tagged_label_type: 'html'});
                                    }
                                }}
                                height={20}
                                width={42}
                            />
                        </Stack.Item>
                    </Stack>
                    {
                        ltv_tagged_label_type === 'text' &&

                        <Stack vertical spacing="tight">
                            <TextField
                                label="Login to view price Label"
                                value={this.state.label_for_tagged_based}
                                onChange={(label_for_tagged_based) => this.setState({ label_for_tagged_based })}
                            />
                            <TextField
                                label="Redirect Link"
                                error = {this.state.link_for_tagged_based.length < 1 && "This field cannot be empty"}
                                prefix={`https://${this.props.shop}/`}
                                value={this.state.link_for_tagged_based}
                                onChange={(link_for_tagged_based) => this.setState({ link_for_tagged_based })}
                            />
                        </Stack>
                    }
                    {
                        ltv_tagged_label_type === 'html' &&
                        
                        <Stack vertical spacing="tight">
                            <TextField
                                label="HTML Code"
                                value={this.state.html__label_for_tagged_based}
                                onChange={(html__label_for_tagged_based) => this.setState({ html__label_for_tagged_based })}
                            />
                        </Stack>
                    }
                </Stack>
            </Card.Section>
        </Card>

        const section4 = <Card>
            <Card.Section>
                <Stack vertical spaing="loose">
                    <Stack>
                        <Stack.Item fill>
                            <Heading>Labels/Links</Heading>
                        </Stack.Item>
                        <Stack.Item>
                            <TextStyle>Turn on HTML Mode</TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                            <Switch 
                                checked = {ltv_label_type === 'html'}
                                onChange={() => {
                                    if(ltv_label_type === 'html'){
                                        this.setState({ltv_label_type: 'text'});
                                    }
                                    else{
                                        this.setState({ltv_label_type: 'html'});
                                    }
                                }}
                                height={20}
                                width={42}
                            />
                        </Stack.Item>
                    </Stack>
                    {
                        ltv_label_type === 'text' &&

                        <Stack vertical spacing="tight">
                            <TextField
                                label="Login to view price Label"
                                value={this.state.label_for_logged_in}
                                onChange={(label_for_logged_in) => this.setState({ label_for_logged_in })}
                            />
                            <TextField
                                label="Redirect Link"
                                error={this.state.link_for_logged_in.length < 1 && "This field cannot be empty"}
                                prefix={`https://${this.props.shop}/`}
                                value={this.state.link_for_logged_in}
                                onChange={(link_for_logged_in) => this.setState({ link_for_logged_in })}
                            />
                        </Stack>
                    }
                    {
                        ltv_label_type === 'html' &&

                        <Stack vertical spacing="tight">
                            <TextField
                                label="HTML Code"
                                value={this.state.html__label_for_logged_in}
                                onChange={(html__label_for_logged_in) => this.setState({ html__label_for_logged_in })}
                            />
                        </Stack>
                    }
                </Stack>
            </Card.Section>
        </Card>

        const renderSection = <Layout>
            {
                this.state.errorList.length > 0 &&
                <Layout.Section>{errors_section}</Layout.Section>
            }
            <Layout.Section>{section1}</Layout.Section>
            <Layout.Section>{enabled && section2}</Layout.Section>
            <Layout.Section>{enabled && customer_group  && section4}</Layout.Section>
            <Layout.Section>{enabled && customer_group === 'tag_based' && section3}</Layout.Section>
            <Layout.Section>{toast}</Layout.Section>
        </Layout>

        if (this.state.page_loaded) {
            return (
                <div>
                    <PageTitleBar
                        title="Login to view Prices"
                        primaryAction={changes_detected ?
                            {
                                content: "Save",
                                onAction: () => {
                                    this.saveData()
                                },
                                disabled: !changes_detected || !this.hasFeatures("login_to_view_prices"),
                                loading: this.state.saveButtonLoading
                            }
                            : null}
                        breadcrumbs={[{ content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
                        secondaryActions={[{ content: changes_detected ? "Cancel" : "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
                    />
                    {
                        !this.hasFeatures("login_to_view_prices") &&
                        <div style={{marginBottom: '1rem'}}>
                        <Banner
                            title="Activation Required"
                            status="critical"
                        >
                            <p>To use the Login To View Prices addon, please activate it in the Billing section first. <Link external url='https://support.digitalcoo.com/hc/en-us/articles/360054438812'>[View details]</Link> | <Link url={BUILD_VERSION_EXTENSION+"/update-plans"}>[Go to Billing section]</Link></p>
                        </Banner>
                        </div>
                    }
                    {renderSection}
                </div>
            )
        }
        else {
            return (<SkeletonPageComp></SkeletonPageComp>);
        }
    }
}