import React, { Component } from 'react';
import {
  Card,ResourceList,TextStyle,Button,Layout,EmptyState,Badge,ButtonGroup,ResourceItem,Banner
} from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import FooterHelpLinks from './FooterHelpLinks';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import Common from '../Helpers/Common';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class CartLevelDiscountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      qb_data: [],
      filter_qb_data: [],
      searchValue: '',
      shop: this.props.shop,
      page_loaded: false,
      est_time: undefined,
      selected_quantity_breaks: [],
      selected_quantity_breaks_keys: [],
      enable_btn_loading: false,
      disable_btn_loading: false,
      delete_btn_loading: false,
      loading: false,
      import_qb_modal: false,
      sort_by: 'DATE_CREATED_DESC',
      pagination_has_next: false,
      pagination_has_previous: false,
      totalPages: 1,
      // pageNumber: 1,
      showToast: false,
      toastError: false,
      toastMsg: ""
    };
    axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }
  renderItem = (item,id) => {
    const{_id,title,customer_group,tags,state}=item;
    var url = BUILD_VERSION_EXTENSION+'/cart-discounts/edit/'+_id;
    var actionButtons = [{content: 'Edit', target:'APP' ,url: BUILD_VERSION_EXTENSION+'/cart-discounts/edit/'+_id}];
    if(Common.getPlanID() === 3){
      if(state === 'published'){
        actionButtons.push({
          content: 'Unpublish', onAction: () =>{ this.change_quantity_break(item,"unpublished");}
        });
      }
      else{
        actionButtons.push({
          content: 'Publish', onAction: () =>{ this.change_quantity_break(item,"published"); }
        });
      }
    }

    actionButtons.push({
      icon:  DeleteMinor, onAction: ()=>{ this.delete_qb(item)}
    })

    const mainTitleSection = (
      <div className="CustomerListItem__Profile ">
        <h3 className="CustomerListItem__Title">
        <TextStyle variation="strong">{
            <span>
              { title || "No title" } { item.channel === "import" && <Badge>via Import</Badge> }
            </span>
          }</TextStyle>
        </h3>
        <div className="CustomerListItem__Location">
            <TextStyle variation="subdued">
              { item.calculation_type === "cart_subtotal"?"Cart Amount (Subtotal)":"Cart Total Items (Quantity)"}
            </TextStyle>
        </div>
        <div className="CustomerListItem__Location">
          <span>
            <TextStyle variation="subdued">
              {
                (item.individual_product || customer_group === "has_tag") ?
                (tags.length > 1?"Customer tags: ":"Customer tag: "):
                customer_group === "all"?"All customers":
                customer_group === "has_account"?"Logged in customers":
                customer_group === "no_account"?"Guest customers":
                " "
              }
              {
                (customer_group === "has_tag") && <span>{ tags.toString() }</span>
              }
            </TextStyle>
          </span>
        </div>
      </div>
    );

    const contentSection = (
      <div className="CustomerListItem__Status">
        <div className="CustomerListItem__StatusBadge">
          {
            item.state === 'published'?
            <Badge status="success"> Published </Badge>
            :item.state==='unpublished'?<Badge> Unpublished </Badge>
            :null
          }
        </div>
      </div>
    );
    return (
      <ResourceItem 
        id={id}
        url={url}
        shortcutActions={actionButtons}
        verticalAlignment="center"
      >
        <div className="CustomerListItem__Main">
          {mainTitleSection}
          {contentSection}
        </div>
      </ResourceItem>
    );
  };

  componentDidMount() {
    this.fetch_all_qb(this.state.sort_by);
  }

  fetch_all_qb(sort_by, page_request){
    // let pageNumber = this.state.pageNumber || 1;
    if(page_request === "prev"){
      // pageNumber = Number(pageNumber)-1;
      this.setState({loading: true});
    }
    else if(page_request === "next"){
      // pageNumber = Number(pageNumber)+1;
      this.setState({loading: true});
    }
    axios.get('/app/cart-discounts?shop='+this.props.shop)
    .then(response => {
      var data = response.data;
      this.setState({
        page_loaded: true,
        qb_data: data,
        loading: false,
        filter_qb_data: data,
        selectedItems: []
    });
    })
    .catch(error => {
      this.setState({loading:false});
      this.showToastMessage("Error", true);
    });
  }

  delete_qb(item){
    if(window.confirm("Delete Order Discount?")){
      const newData = this.state.qb_data.filter(i => i._id !== item._id);
      this.setState({qb_data:newData,loading:false});
      this.setState({loading:true});
      axios.delete('/app/cart-discount?id='+item._id+'&shop='+this.props.shop)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
          }
          else{
            this.setState({loading:false});
            this.showToastMessage(response.data.message, true);
          }
          this.fetch_all_qb(this.state.sort_by);
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:true});
      });
    }
  }

  change_quantity_break(item, state){
      this.setState({loading:true});
      try{
        item["state"] = state || "unpublished";
      }catch(e){}
      try{
        item["shop"] = this.props.shop;
      }catch(e){}
      axios.put('/app/cart-discount?shop='+this.props.shop+'&id='+item._id,item)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }else{
            this.setState({loading:false});
            this.showToastMessage(response.data.message, true);
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:false});
        this.showToastMessage("Error", true);
      });
  }

  doNotUpdate = (e) => {
    e.preventDefault();
  }

  upgrade = () => {
    this.props.history.push(BUILD_VERSION_EXTENSION+'/pricing-plans');
  }

  duplicateTagsBanner(){
    var isDuplicated = false;
    var qb_data = this.state.qb_data;
    var tags = [];
    var duplicated_tags = [];
    qb_data.forEach(rule => {
        if(rule.customer_group === "has_tag" && rule.state === "published"){
          rule.tags.forEach(tag => {
            var ind = tags.findIndex(x => x.toLowerCase() === tag.toLowerCase());
            if(ind === -1){
              tags.push(tag)
            }
            else{
              isDuplicated = true;
              if(duplicated_tags.indexOf(tag) === -1){
                duplicated_tags.push(tag);
              }
            }
          });
        }
    });
    var html = null;
    if(isDuplicated){
      html = <div style={{margin:"1rem 0rem"}}>
        <Banner
          status="warning"
          title="Duplicate tags found!"
        >
          <p>
            You're not allowed to use a tag in multiple rules.
          </p>
          {
            duplicated_tags && duplicated_tags.length > 0 &&
            <p>
              Duplicated tags: {duplicated_tags.toString()}
            </p> 
          }
        </Banner>
      </div>
    }
    return html;
  }

  render() {
    var emptySecondaryActions = null;
    const add_qb_buttons = <div>
      <ButtonGroup>
        <Button primary size="slim" url={BUILD_VERSION_EXTENSION+"/cart-discounts/create"} target="APP">Create Order Level Discount rule</Button>
      </ButtonGroup>
    </div>
    const resourceName = {
      singular: 'Order Level Discount',
      plural: 'Order Level Discounts',
    };
    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);

    if(this.state.page_loaded === false){
      return(
        <div>
          <PageTitleBar
              title="Order Level Discount"
              breadcrumbs={[
                { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
              ]}
              secondaryActions={[{ content: "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
            />
          <SkeletonPageComp />
        </div>
      );
    }
    else{
      if(this.state.qb_data.length < 1){
        return(
          <div>
            {toast}
            <PageTitleBar
              title="Order Level Discount"
              breadcrumbs={[
                { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
              ]}
              secondaryActions={[{ content: "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
            />
            <Layout>
              <EmptyState
                heading="Order Level discounts"
                action={{content: 'Create Order Level discount', url: BUILD_VERSION_EXTENSION+'/cart-discounts/create', target: "APP"}}
                secondaryAction={emptySecondaryActions}
                image="https://cdn.shopifycloud.com/web/assets/6abcecfa5637ef0dced77481e9c7069e.svg"
              >
              <p>Allow the merchant to offer discount based on total order amount, total order quantity or/and total life time purchase of the customer.</p>
              </EmptyState>
            </Layout>
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
      else if(this.state.filter_qb_data && this.state.filter_qb_data.length){
        return (
          <div>
            {this.duplicateTagsBanner()}
            
            {toast}
            <PageTitleBar
              title="Order Level Discount"
              breadcrumbs={[
                { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
              ]}
              secondaryActions={[{ content: "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
            />
            <Layout>
              <Layout.Section>
                {add_qb_buttons}
              </Layout.Section>
              <Layout.Section>
                <Card>
                  <ResourceList
                    showHeader={true}
                    resourceName={resourceName}
                    items={this.state.filter_qb_data}
                    renderItem={this.renderItem}
                    persistActions
                    loading={this.state.loading}
                  />
                </Card>
              </Layout.Section>
              <Layout.Section><FooterHelpLinks help={this.props.help}/></Layout.Section>
            </Layout>
          </div>
        );
      }
      else{
        return (
          <div>
            <PageTitleBar
              title="Order Level Discount"
              breadcrumbs={[
                { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
              ]}
              secondaryActions={[{ content: "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
            />
            {add_qb_buttons}
            {toast}
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
    }
  }

}