import  React, { Component } from 'react';
import {Card, DataTable, ButtonGroup, Button, Badge, TextStyle, ResourceList, ResourceItem, EmptyState} from '@shopify/polaris';
import axios from '../Axios';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import {
    ArrowUpMinor,
    ArrowDownMinor
} from '@shopify/polaris-icons';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class QuickOrderForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            shop: this.props.shop,
            selected_tab: 0,
            tableColumns: [
                {
                    name: "Image",
                    position: 0,
                    state: "enabled",
                },
                {
                    name: "Product Name",
                    position: 1,
                    state: "enabled",
                },
                {
                    name: "Price",
                    position: 2,
                    state: "enabled",
                },
                {
                    name: "Compare at price",
                    position: 3,
                    state: "enabled",
                },
                {
                    name: "Quantity",
                    position: 4,
                    state: "enabled",
                },
                {
                    name: "Product SKU",
                    position: 5,
                    state: "enabled",
                },
                {
                    name: "Vendor",
                    position: 6,
                    state: "enabled",
                },
                {
                    name: "Product Type",
                    position: 7,
                    state: "enabled",
                }
            ],
            loading: true,
            filtered_qof: [],
            qof: [],
            appliedFilters: [],
            selected_qof: [],
            sortValue: "",
            setting_exists_id: null,
            setting_exists: false,
            settingBtnLoading: false,
            show_module: 0,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
    }
    componentDidMount() {
        this.fetch_all_qof();
        this.fetch_qof_settings();

    }
    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    fetch_all_qof(){
        axios.get('/app/quick-order-forms/' + this.props.shop)
        .then(response => {
            if(response && response.data && response.data.quickOrderForms){
                this.setState({
                    page_loaded: true,
                    qof: response.data.quickOrderForms,
                    filtered_qof: response.data.quickOrderForms,
                    loading: false,
                    selectedItems: []
                });
            }
        })
        .catch(error => {
            
        });
    }
    fetch_qof_settings(){
        axios.get('/app/qof-settings/' + this.props.shop)
        .then(response => {
            if(response && response.data && response.data.qof_settings){
                var { tableColumns } = response.data.qof_settings;
                console.log("tableColumns", tableColumns)
                var setting_exists_id = response.data.qof_settings._id;
                if(tableColumns && tableColumns.length > 0){
                    this.setState({
                        tableColumns: tableColumns,
                        setting_exists_id: setting_exists_id,
                        setting_exists: true
                    });
                }
            }
        })
        .catch(error => {
            // 
        });
    }
    sortToDown(item,index,el){
        var a = this.state.tableColumns;
        a[index].position = index+1;
        a[index+1].position = index;
        a.sort(function (a, b) {
            return a.position - b.position;
        });
        this.setState({
            tableColumns: a
        });
    }
    sortToUp(item,index,el){
        var a = this.state.tableColumns;
        a[index].position = index-1;
        a[index-1].position = index;
        a.sort(function (a, b) {
            return a.position - b.position;
        });
        this.setState({
            tableColumns: a
        });
    }
    handleSearchChange = (searchValue) => {
        var tem_items = this.state.qof.filter(x => x.title.match(new RegExp(searchValue, 'i')));
        if(tem_items.length < 1){
          tem_items = this.state.qof.filter(x => x.displayOnPage.match(new RegExp(searchValue, 'i')));
        }
        this.setState({
          filtered_qof: tem_items,
          searchValue: searchValue
        })
    };
    handleFiltersChange = (appliedFilters) => {
        this.setState({appliedFilters});
    };
    handleSortChange = (sortValue) => {
        this.setState({sortValue});
        this.fetch_all_qof();
    };
    handleSelectionChange = (selectedItems) => {
        var temp_items = [];
        if(selectedItems.length > 0){
            selectedItems.forEach(x=>{
                temp_items.push(this.state.qof[x]._id);
            })
        }
        this.setState({
            selectedItems: selectedItems,
            selected_qof: temp_items
        });
    };
    renderItem = (item, id) => {
        const { _id, title, collections, products } = item;
        var url = BUILD_VERSION_EXTENSION+'/quick-order-form/edit/' + _id;
        const shortcutActions = _id
            ? [
                { content: 'Edit', target: "APP", url: BUILD_VERSION_EXTENSION+'/quick-order-form/edit/' + _id },
                { content: 'published'===item.state?'Disable':'Enable' , onAction: ()=>{
                    'published'===item.state? this.disableRule(item) : this.enableRule(item)
                }},
                { content: 'Delete', onAction: ()=>{ this.delete_rule(item)}}
            ]
            : null;
        const mainTitleSection = (
            <div className="CustomerListItem__Profile">
            <h3 className="CustomerListItem__Title">
                <TextStyle variation="strong">{
                <span>
                    {title === '' ? "N/A" : title}
                </span>
                }</TextStyle>
            </h3>
            <div className="CustomerListItem__Location">
                {
                   (products.length > 0) && (products.length + (products.length > 1? " products":" product"))
                }
                {
                    (products.length > 0) && (collections.length > 0) && (" and ")
                }
                {
                   (collections.length > 0) && (collections.length + (collections.length > 1? " collections":" collection"))
                }
                {
                    ((products.length > 0) || (collections.length > 0)) && (" was selected")
                }
            </div>
            </div>
        );
        const contentSection = (
            <div className="CustomerListItem__Status">
            <div className="CustomerListItem__StatusBadge">
                {
                    item.state === 'published' ? <Badge status="success"> Published </Badge> :
                    <Badge> Unpublished </Badge>
                }
            </div>
            </div>
        );
        return (
            <div className="CustomerListItem">
            <ResourceItem
                id={id}
                url={url}
                shortcutActions={shortcutActions}
            >
                <div className="CustomerListItem__Main">
                {mainTitleSection}
                {contentSection}
                </div>
            </ResourceItem>
            </div>
        );
    };
    delete_rule(item){
        if (window.confirm("Delete rule permanently?")) {
            this.setState({loading:true});
            axios.delete('/app/quick-order-form/' + item._id + '/' + this.props.shop)
            .then(response => {
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                    const newData = this.state.qof.filter(i => i._id !== item._id);
                    this.setState({ qof: newData,filtered_qof:newData, loading: false });
                }
                else {
                    this.showToastMessage(response.data.message, true);
                    this.setState({ loading: false });
                }
                this.setState({ selectedItems:[],loading:false });
            }
            ).catch(err => {
               
            });
        }
    }
    saveQOFSettings(){
        if(!this.hasFeatures('quick_order_form')) {
            this.showToastMessage("Please subscribe to this addon to use it", true);
            return false;
        };
    this.setState({settingBtnLoading: true});
        var data = {
            shop: this.props.shop,
            tableColumns: this.state.tableColumns
        };
        if(this.state.setting_exists){
            data.id = this.state.setting_exists_id;
            axios.put('/app/qof-settings/update', data)
            .then(response => {
                this.setState({settingBtnLoading: false});
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                } else {
                    this.showToastMessage(response.data.message, true);
                    return false;
                }
            })
            .catch(error => {
               
                this.setState({settingBtnLoading: false});
            });
        }
        else{
            axios.post('/app/qof-settings/create', data)
            .then(response => {
                this.setState({settingBtnLoading: false});
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                } else {
                    this.showToastMessage(response.data.message, true);
                    return false;
                }
            })
            .catch(error => {
               
                this.setState({settingBtnLoading: false});
            });
        }
    }
    disableRule(item){
        this.setState({loading:true});
        var data = item;
        data.id = item._id;
        data.state = "unpublished";
        axios.put('/app/quick-order-form/update', data)
        .then(response => {
            this.fetch_all_qof();
            if (response.data.status === 1) {
                this.showToastMessage(response.data.message);
            }
            else {
                this.showToastMessage(response.data.message, true);
            }
            this.setState({ selectedItems:[],loading:false });
        }
        ).catch(err => {
           
        });
    }
    enableRule(item){
        this.setState({loading:true});
        var data = item;
        data.id = item._id;
        data.state = "published";
        axios.put('/app/quick-order-form/update', data)
        .then(response => {
            this.fetch_all_qof();
            if (response.data.status === 1) {
                this.showToastMessage(response.data.message);
            }
            else {
                this.showToastMessage(response.data.message, true);
            }
            this.setState({ selectedItems:[],loading:false });
        }
        ).catch(err => {
           
        });
    }

    hasFeatures(value){
        var hasF = false;
        if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
          hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
        }
        return hasF;
      }

    render(){

        const rows = this.state.tableColumns.map((column,i) => [
            <ButtonGroup segmented>
                <Button
                    icon={ArrowUpMinor}
                    size="slim"
                    onClick={this.sortToUp.bind(this, column, i)}
                    disabled={0 === i}
                ></Button>
                <Button
                    icon={ArrowDownMinor}
                    size="slim"
                    onClick={this.sortToDown.bind(this, column, i)}
                    disabled={this.state.tableColumns.length === i+1}
                ></Button>
            </ButtonGroup>,
            <span>
                {"enabled" === column.state ?<TextStyle>{column.name}</TextStyle>:
                    <TextStyle variation="subdued">{column.name}</TextStyle>
                }
            </span>,
            <span>
                {
                    "enabled" === column.state ?
                    <Badge status="success" progress="complete">Enabled</Badge>
                    :<Badge>Disabled</Badge>
                }
            </span>,
            <Button
                primary={"enabled" === column.state ?false:true}
                onClick={() => {
                    var value = "enabled" === column.state ?"disabled":"enabled";
                    var tableColumns = this.state.tableColumns;
                    tableColumns[i] = { ...tableColumns[i], "state": value };
                    this.setState({tableColumns})
                }}
            >
                {"enabled" === column.state ?"Disable":"Enable"}
            </Button>
        ]);

        var setting_section = <div>
            <DataTable
                columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                ]}
                headings={[
                    'Position in column',
                    'Column name',
                    'Status',
                    'Action',
                ]}
                rows={rows}
            />
        </div>;
        const resourceName = {
            singular: 'quick order form',
            plural: 'quick order forms',
        };

        var resourceListSection = this.state.filtered_qof && this.state.filtered_qof.length ? (<div>
            <Card>
                <ResourceList
                    resourceName={resourceName}
                    items={this.state.filtered_qof}
                    renderItem={this.renderItem}
                    selectedItems={this.state.selectedItems}
                    onSelectionChange={this.handleSelectionChange}
                    loading={this.state.loading}
                />
            </Card>
        </div>):
        <Card>
          <Card.Section>
            <EmptyState
              heading="Create a quick order form to get started"
              action={{content: 'Create a form', url:BUILD_VERSION_EXTENSION+"/quick-order-form/add", target:"APP"}}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>
                You can create multiple groups of products/variants to manage and boost sale from a single page
              </p>
            </EmptyState>
          </Card.Section>
        </Card>;
        var show_module_section = resourceListSection;
        switch(this.state.show_module){
            case 0:
                show_module_section = resourceListSection;
                break;
            case 1:
                    show_module_section = setting_section;
                break; 
            default: 
            show_module_section = resourceListSection
        }

        var primary_action_btn = {
            content: 'Save Settings',
            loading: this.state.settingBtnLoading,
            disabled: !this.hasFeatures('quick_order_form'),
            onAction: () => { this.saveQOFSettings() }
        };
        if(1!==this.state.show_module) primary_action_btn = null;
        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);

        return (
            <div>
                <PageTitleBar
                    title="Quick order form addon"
                    breadcrumbs={[
                        { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
                      ]}
                    primaryAction={primary_action_btn}
                    secondaryActions={[
                        {
                            content: 'Order forms',
                            onAction: () => {
                                if(this.state.show_module !== 0){
                                    this.setState({show_module: 0})
                                }
                            }
                        },
                        {
                            content: 'Settings',
                            onAction: () => {
                                if(this.state.show_module !== 1){
                                    this.setState({show_module: 1})
                                }
                            }
                        },
                        {
                            content: "Create new order form",
                            target: "APP",
                            url: BUILD_VERSION_EXTENSION+"/quick-order-form/add"
                        },
                        { 
                            content: "Back",
                            onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"
                        }
                    ]}
                />
                {show_module_section}
                {toast}
            </div>
        );
    }
}