import React from 'react';
import axios from '../Axios';
import store from 'store2';
import Common from '../../../Helpers/Common';
import { Layout, RadioButton, Modal, Stack, Button, TextStyle, Heading, Link } from '@shopify/polaris';

export default class TestOrLiveMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            app_mode: this.props.app_mode || "test",
            _app_mode: this.props.app_mode || "test",
            loading: false,
            expanded: this.props.app_mode === "live"? false: true,
            modal: false,
            bannerClass: ' Polaris-Banner--statusSuccess ',
            shopData: null,


            /* Toast specefic */
            showToast: false,
            toastError: false,
            toastMsg: ""
        }
    }

    componentDidMount() {
        if (store("_wsinit")) {
            let shopData = store("_wsinit");
            try {
                shopData = JSON.parse(shopData);
            } catch (e) {}
            try {
                const appMode = shopData["app_mode"];
                this.setState({
                    app_mode: appMode,
                    _app_mode: appMode
                });
                if(appMode === "live"){
                    this.setState({
                        expanded: false
                    });
                }
                else {
                    this.setState({
                        expanded: true
                    });
                }
                if(appMode === "live"){
                    this.setState({
                        bannerClass: ' Polaris-Banner--statusSuccess '
                    })
                }
                else {
                    this.setState({
                        bannerClass: ' Polaris-Banner--statusWarning '
                    })
                }
            } catch (e) {}
        }      
    }

    updateData(app_mode) {
        if (store("_wsinit")) {
        var shopData = store("_wsinit");
        try {
            shopData = JSON.parse(shopData);
        } catch (e) {}
        try {
            shopData["app_mode"] = app_mode;
        } catch (e) {}
        if (shopData.name) {
            store("_wsinit", JSON.stringify(shopData));
        }
        }

        try{ 
        // Trigger event to reload auto installation banner
        document.dispatchEvent(new CustomEvent("wsaio-reload-auto-installation-banner"));
        } catch(e){ }
    }

    handleEnableApp = () => {
        var app_mode = this.state.app_mode;
        this.setState({
          loading: true
        });
        const settingsData = {
          app_mode: app_mode,
          shop: this.props.shop
        };
        axios.put('/app/app-mode', settingsData)
        .then( response => {
            if(response.data.status === 1){
              this.updateData(app_mode);
              this.showToastMessage(response.data.message);
              this.setState({
                _app_mode: app_mode,
                loading: false,
                bannerClass: app_mode === "live" ? ' Polaris-Banner--statusSuccess ' : ' Polaris-Banner--statusWarning '
              });
            }
            else{
              this.showToastMessage(response.data.message, true);
              this.setState({
                loading: false
              });
            }
        })
        .catch(error => {
          this.showToastMessage("Server error", true);
          this.setState({
            loading: false
          });
        });
      };

      showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
      }
  
      dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
      }

    render() {
        const { app_mode, bannerClass, _app_mode } = this.state;
        const infoModal = <Modal
            open={this.state.modal}
            onClose={()=>{this.setState({modal:false})}}
            title="Test Mode & Live Mode options"
        >
            <Modal.Section>
                <Stack distribution="fillEvenly">
                    <Stack.Item>
                    <Heading>Test Mode</Heading>
                    <p>
                        Use to test the wholesale app working before making it live to the customers.
                    </p>
                    </Stack.Item>
                    <Stack.Item>
                    <Heading>Live Mode</Heading>
                    <p>
                        The wholesale app features will appear direclty to the live customers.
                        <br></br>
                        <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/360042800512">Learn More</Link>
                    </p>
                    </Stack.Item>
                </Stack>
            </Modal.Section>
        </Modal>;

        return (
            <div
                style={{
                    marginBottom: '1rem'
                }}
            >
                 <Layout>
                    {infoModal}
                    <Layout.Section>
                        <Stack>
                            <Stack.Item fill>
                            </Stack.Item>
                            <Stack.Item>
                                <Button
                                    destructive={this.state._app_mode === "test"}
                                    primary={this.state._app_mode === "live"}
                                    plain={true}
                                    disclosure={this.state.expanded ? "up" : "down"}
                                    onClick={() => {
                                        this.setState({ expanded: !this.state.expanded});
                                    }}
                                    removeUnderline={true}
                                >
                                    <div className={this.state._app_mode === "live"?"ws-appmode_live":"ws-appmode_test"} style={{display: "flex", alignItems: "center"}}>
                                    <div class="ws-circle"></div>
                                    <div class="ws-mode-text">
                                        {
                                        this.state._app_mode === "live"?
                                        "Live Mode Enabled"
                                        :"Test Mode Enabled"
                                        }
                                    </div>
                                    </div>
                                </Button>
                            </Stack.Item>
                        </Stack>
                    </Layout.Section>
                    { 
                        this.state.expanded &&
                            <Layout.Section>
                                <div className={"Polaris-Banner Polaris-Banner--withinPage " + bannerClass } tabindex="0" role="status" aria-live="polite" aria-describedby="PolarisBanner1Content">
                                    <div className="Polaris-Banner__ContentWrapper">
                                        <div className="Polaris-Banner__Content" id="PolarisBanner1Content">
                                            <Stack vertical spacing="tight">
                                                <Stack.Item>
                                                    <Stack>
                                                        <Stack.Item fill><TextStyle variation="strong">Select the application mode:</TextStyle></Stack.Item>
                                                        <Stack.Item><Link external url='https://support.digitalcoo.com/hc/en-us/articles/360042800512'>Learn more</Link></Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Stack spacing='extraTight' vertical>
                                                    <RadioButton
                                                            label="Live Mode"
                                                            checked={app_mode === 'live'}
                                                            id="live"
                                                        name="mode"
                                                            onChange={() => {  this.setState({app_mode: 'live'}); }}
                                                    />
                                                        <div style={{fontSize: '85%', marginTop: '-5px', marginLeft: '28px'}}><TextStyle variation="subdued">Live Mode makes the wholesale app features visible and accessible to live customers in real time.</TextStyle></div>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Stack spacing='extraTight' vertical>
                                                    <RadioButton
                                                        label="Test Mode"
                                                        id="test"
                                                        name="mode"
                                                        checked={app_mode === 'test'}
                                                        onChange={() => { this.setState({app_mode: 'test'}); }}
                                                    />
                                                    </Stack>
                                                    <div style={{fontSize: '85%', marginTop: '-2px', marginLeft: '28px'}}><TextStyle variation="subdued">Use Test Mode to safely test the wholesale app’s features and functionality without making them visible to live customers.</TextStyle></div>
                                                    {
                                                    this.state._app_mode === "test" &&
                                                    <div style={{fontSize: '85%', marginTop: '-2px', marginLeft: '28px'}}><TextStyle variation="subdued">Click the <Link external url={Common.getShopBaseUrl()+'?test-mode=1'}>Test Mode Preview Link</Link> to check discounts before going live.</TextStyle></div>
                                                    }
                                                </Stack.Item>
                                                <Button
                                                    onClick={this.handleEnableApp}
                                                    loading={this.state.loading}
                                                    disabled={_app_mode === app_mode}
                                                >
                                                    Update
                                                    </Button>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </Layout.Section>
                    }
                </Layout>
            </div>
        )
    }
}