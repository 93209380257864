import React, { Component } from 'react';
import {
    Layout,
    Card,
    TextStyle,
    Caption, 
    ResourceList, 
    ResourceItem,
    Subheading,
    Heading,
    Stack,
    Icon,
    Form,
    Button,
    TextContainer,
    Link,
    Badge
} from '@shopify/polaris';
import Switch from "react-switch";
import SkeletonPageComp from './SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import Common from '../../../Helpers/Common';
import {
    MinusMinor
} from '@shopify/polaris-icons';
import axios from '../Axios';
import PriceText from '../Common/PriceText';
import { Redirect } from "@shopify/app-bridge/actions";
import { Context } from '@shopify/app-bridge-react';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;
// eslint-disable-next-line
const money_format = "${{amount}}"; // BEFORE => store('ws_money_format') || null;
const store_currency = "USD"; // BEFORE => store('store_currency') || null;

export default class ManageAddons extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {

            addons: [],
            const_state: [], // will not change by state and used to detect changes
            selected_addons: [],

            /* Current subscription details */
            shop_payment: null,
            
            /* All Available subscription details */
            pricing_plans: [],
            selected_pricing_plan: null,

            /* Skeleton page until data loaded */
            page_loaded: false,
            saving: false,

            /* Toast specefic */
            showToast: false,
            toastError: false,
            toastMsg: "",

            /* For the Please wait while we make necessary.... Banner */
            enabledAddonIndexes: [],
            previoslyActiveAddons: [],
            
            /* Bulk Import Compatible or not */
            bulk_import_enabled: false,
            bulk_import_not_compatible: false,

            /* Trial Days */
            trial_days: null
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    /* Lifecycle: Begin component */
    componentDidMount() {

        this.getAddons();
    }
    
    /* Get data of single theme on create page */
    getAddons(){
        axios.get(`/payment/addons?shop=${this.props.shop}&product_count=${this.props.shopData.product_count}`)
        .then(x=>x.data)
        .then(response => {
            
            const selected_plan = response.pricing_plans.length > 0 ? response.pricing_plans.find(x=>x.selected) : null;
            const selected_pricing_plan = typeof selected_plan !== "undefined" && selected_plan ? selected_plan : null;
            
            this.setState({
                addons: response.addons,
                const_state: {
                    addons: JSON.parse(JSON.stringify(response.addons)),
                    selected_plan_id: response.shop_payment.plan_id
                },
                shop_payment: response.shop_payment,
                pricing_plans: response.pricing_plans,
                selected_pricing_plan: selected_pricing_plan,
                page_loaded: true,
                bulk_import_not_compatible: response.bulk_import_not_compatible,
                trial_days: response.trial_days
            });
            
            // Filter out previously enabled addons and check if the Bulk Import/Export feature is enabled.
            const addons = response.addons;
            // eslint-disable-next-line
            let prevAddons = addons.map((addon, index) => { 
                if(addon.tag === "import_export") {
                    if(addon.enabled === true){
                        this.setState({bulk_import_enabled: true})
                    }
                    else{
                        this.setState({bulk_import_enabled: false})
                    }
                }
                if(addon.enabled) {
                    return index.toString(); 
                }
            }).filter(item => item !== undefined); // Filter out undefined elements
            this.setState({
                previoslyActiveAddons: prevAddons
            })
        })
        .catch(error => {
            this.setState({
                page_loaded: true
            });
        });
    }

    /* Show/Hide toast */
    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }

     /* this will detect if anything changes on page */

    /**
    | const_data: constant data, that should not be change
    | compare_data: data to compare, is not match, data is changed
    */
    _comparechange(const_data, compare_data){

        /* Function that will compare both objects */
        let deepCompare = () => {
            var i, l, leftChain, rightChain;

            function compare2Objects(x, y) {
                var p;

                // remember that NaN === NaN returns false
                // and isNaN(undefined) returns true
                if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                    return true;
                }

                // Compare primitives and functions.     
                // Check if both arguments link to the same object.
                // Especially useful on the step where we compare prototypes
                if (x === y) {
                    return true;
                }

                // Works in case when functions are created in constructor.
                // Comparing dates is a common scenario. Another built-ins?
                // We can even handle functions passed across iframes
                if ((typeof x === 'function' && typeof y === 'function') ||
                    (x instanceof Date && y instanceof Date) ||
                    (x instanceof RegExp && y instanceof RegExp) ||
                    (x instanceof String && y instanceof String) ||
                    (x instanceof Number && y instanceof Number)) {
                    return x.toString() === y.toString();
                }

                // At last checking prototypes as good as we can
                if (!(x instanceof Object && y instanceof Object)) {
                    return false;
                }

                if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                    return false;
                }

                if (x.constructor !== y.constructor) {
                    return false;
                }

                if (x.prototype !== y.prototype) {
                    return false;
                }

                // Check for infinitive linking loops
                if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                    return false;
                }

                // Quick checking of one object being a subset of another.
                // todo: cache the structure of arguments[0] for performance
                for (p in y) {
                    if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                        return false;
                    } else if (typeof y[p] !== typeof x[p]) {
                        return false;
                    }
                }

                for (p in x) {
                    if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                        return false;
                    } else if (typeof y[p] !== typeof x[p]) {
                        return false;
                    }

                    switch (typeof (x[p])) {
                        case 'object':
                        case 'function':

                            leftChain.push(x);
                            rightChain.push(y);

                            if (!compare2Objects(x[p], y[p])) {
                                return false;
                            }

                            leftChain.pop();
                            rightChain.pop();
                            break;

                        default:
                            if (x[p] !== y[p]) {
                                return false;
                            }
                            break;
                    }
                }

                return true;
            }

            if (arguments.length <= 1) {
                return true; //Die silently? Don't know how to handle such case, please help...
            }

            for (i = 1, l = arguments.length; i < l; i++) {

                leftChain = []; //Todo: this can be cached
                rightChain = [];

                if (!compare2Objects(arguments[0], arguments[i])) {
                    return false;
                }
            }

            return true;
        }

        return !deepCompare(const_data, compare_data);
    }

    /* WIll detect edit and enable save button */
    detectChanges(){
        // Match objects
        
        const current_state = {
            addons: this.state.addons,
            selected_plan_id: this.state.selected_pricing_plan ? this.state.selected_pricing_plan.plan_id : null
        };
        return this._comparechange(this.state.const_state, current_state);
    }

    /* Calculate the selected addons prices */
    calculateSubtotal(){
        const current_subscription_price = this.state.selected_pricing_plan ?  Number(Common.getCurrency(this.state.selected_pricing_plan.price, money_format).amount) : 0;
        const selected_addons = this.state.addons.filter(x=>x.enabled);

        // Loop and sum addon amounts
        const addons_total = selected_addons.length > 0 ? selected_addons
        .reduce((total, addon) => {
            const priceCurrency = Common.getCurrency(addon.price, money_format);

            return total + Number(priceCurrency.amount);
        }, 0) : 0;

        const subtotal = current_subscription_price + addons_total;
        const totalCurrency = Common.getCurrency(subtotal, money_format);

        // Total amount with full currency
        return `${totalCurrency.amount_with_symbol} ${store_currency ? store_currency : ""}`;
    }

    // Generate link to recurring charge
    generateCharge(){
        /* Send update request */
        this.setState({ saving: true });

        const enabled_addons_features = this.state.addons.filter(x=>x.enabled).flatMap(x=>x.features);
        const paylaod = {
            new_installation: this.state.shop_payment.status === "Pending" ? true : false,
            shop: this.props.shop,
            pricing_plan_id: this.state.selected_pricing_plan ? this.state.selected_pricing_plan._id : null,
            enabled_addons_features: enabled_addons_features,
            host: Common.getHost()
        }

        axios.post('/payment/addons/generate-invoice', paylaod)
        .then(x=>x.data)
        .then(response => {
            
            if(typeof response.error !== "undefined" && response.error){
                this.setState({ saving: false });
                this.showToastMessage(response.error, true);
                return;
            }

            // Redirect to confirmation_url
            if(response.confirmation_url){

                const app = this.context;
                const redirect = Redirect.create(app);

                // after 5 sec, if redirect still not happen, try to use native redirect instead of app bridge
                setTimeout(() => {
                    window.top.location.href = response.confirmation_url;
                }, 5000);
                redirect.dispatch(
                    Redirect.Action.REMOTE,
                    response.confirmation_url
                );
            }

        }).catch((error) => {
            // Error
            let msg = "Server Error";
            if (error.response && error.response.data && error.response.data.error) {
                msg = error.response.data.error;
            }

            this.setState({ saving: false });
            this.showToastMessage(msg, true);
        });
    }

    /* Lifecycle: Main Event */
    render() {
        const bulk_import_enabled = this.state.bulk_import_enabled;
        /* Will show toast based on state */
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        
        /* Return Method: */
        if(this.state.page_loaded){

            // Find Selected plan
            let current_subscription = null;
            let current_subscription_price = <TextStyle variation="negative">Not Subscribed</TextStyle>;
            if(this.state.shop_payment.status === "Pending"){
                current_subscription_price = <TextStyle>-</TextStyle>;
            }
            if(this.state.pricing_plans.length > 0){
                current_subscription = this.state.pricing_plans.find(x=>x.selected);
                if(typeof current_subscription !== "undefined" && current_subscription) current_subscription_price = Common.getCurrency(current_subscription.price, money_format).amount_with_symbol;
                else current_subscription = null;
            }
        
            const selected_addons = this.state.addons.filter(x=>x.enabled);
            const changes_detected = this.detectChanges();


            // let can_change = false;
            // if(this.props.shopData && this.props.shopData.install_status && (  this.props.shopData.install_status === "completed" || this.props.shopData.install_status === "auto_completed" )){
            //     can_change = true;
            // }

            let plans = this.state.pricing_plans;
            const planOrder = {
                "Basic": 0,
                "Professional": 1,
                "Business": 2
            };
            
            plans.sort((a, b) => {
                const aOrder = planOrder[a.plan_name];
                const bOrder = planOrder[b.plan_name];
                
                if (aOrder < bOrder) {
                    return -1;
                } 
                else if (aOrder > bOrder) {
                    return 1;
                }
                return 0;
            });

            return(
                <div>
                    
                    <PageTitleBar
                        title="Subscriptions"
                        breadcrumbs={[
                            { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
                        ]}
                        secondaryActions={[{ content: "Back", onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }]}
                    />
                    <Form onSubmit={() => {
                        this.generateCharge();
                    }}>
                        <Layout>
                            <Layout.Section>

                                <Stack vertical spacing="tight">
                                    
                                    <Stack spacing="extraTight" distribution="fillEvenly">
                                        {this.state.pricing_plans.map( (pricing_plan, index) => {
                                            const priceCurrency = Common.getCurrency(pricing_plan.price, money_format);
                                            const plan_includes = typeof pricing_plan.included === "string" ? pricing_plan.included.split(",") : [];
                                            const plan_not_includes = typeof pricing_plan.not_included === "string" && pricing_plan.not_included !== "" ? pricing_plan.not_included.split(",") : [];
                                            
                                            return (
                                                <div style={
                                                    pricing_plan.selected ? 
                                                    {boxShadow: "inset 0px 0px 0px 3px var(--p-border-success)", borderRadius: "var(--p-border-radius-wide, 3px)", cursor: "default"} 
                                                    : {
                                                        boxShadow: "inset 0px 0px 0px 3px var(--p-border-subdued)", 
                                                        borderRadius: "var(--p-border-radius-wide, 3px)",
                                                        cursor: "pointer"
                                                    }
                                                    } 
                                                    onClick={() => {
                                                        let plans = this.state.pricing_plans;
                                                        plans = plans.map((plan, pIndex)=>{
                                                            plan.selected = pIndex === index; // current index is selected one now
                                                            return plan;
                                                        });

                                                        const selected_pricing_plan = plans.find(x=>x.selected);

                                                        this.setState({
                                                            pricing_plans: plans,
                                                            selected_pricing_plan: selected_pricing_plan
                                                        });
                                                    }}
                                                >
                                                    <Card key={"pricing_plans_"+index}>
                                                <div style={pricing_plan.selected ? {boxShadow: "inset 0px 0px 0px 3px var(--p-border-success)", borderRadius: "var(--p-border-radius-wide, 3px)"} : {}}>
                                                
                                                    <Card.Header
                                                        title={
                                                                    <Heading>{pricing_plan.plan_name}{pricing_plan.plan_name === "Professional" ? " (Popular)" : ""}</Heading>
                                                        }
                                                    >
                                                        {pricing_plan.subscribed && <Badge status="success">Subscribed</Badge>}
                                                    </Card.Header>

                                                    <Card.Section>

                                                        <Stack vertical spacing="tight">

                                                            <Stack distribution="center">
                                                                <PriceText
                                                                    currency={store_currency}
                                                                    symbol={priceCurrency.symbol}
                                                                    price={pricing_plan.price}
                                                                            inverval_text="/Month"
                                                                />
                                                            </Stack>

                                                            <Stack vertical spacing="extraTight">
                                                                {plan_includes.map( (plan_include, index) => {
                                                                    return (<Caption>
                                                                        <TextStyle key={"plan_include_"+index} variation="subdued">{plan_include}</TextStyle>
                                                                   </Caption>);
                                                                })}
                                                                {plan_not_includes.map( (plan_not_include, index) => {
                                                                    // Visually Hide them so height can be maintained 
                                                                    return (<span style={{visibility: "hidden"}}>
                                                                        <Caption key={"plan_not_include"+index} >
                                                                            <TextStyle variation="subdued">{index}</TextStyle>
                                                                        </Caption>
                                                                    </span>);
                                                                })}
                                                            </Stack>

                                                            <Stack distribution="center">
                                                                <Button 
                                                                    size="slim" 
                                                                    disabled={pricing_plan.selected}
                                                                    onClick={() => {
                                                                        let plans = this.state.pricing_plans;
                                                                        plans = plans.map((plan, pIndex)=>{
                                                                            plan.selected = pIndex === index; // current index is selected one now
                                                                            return plan;
                                                                        });

                                                                        const selected_pricing_plan = plans.find(x=>x.selected);

                                                                        this.setState({
                                                                            pricing_plans: plans,
                                                                            selected_pricing_plan: selected_pricing_plan
                                                                        });
                                                                    }}
                                                                >
                                                                    Select
                                                                </Button>
                                                            </Stack>

                                                        </Stack>
                                                    </Card.Section>

                                                </div>
                                                    </Card>
                                                </div>
                                            )
                                        })}
                                    </Stack>

                                    {
                                        this.state.selected_pricing_plan &&
                                    <Card title="Addons List (Optional)" sectioned>

                                    <div
                                        style={{
                                            marginBottom: "1rem",
                                            marginTop: "-2rem"
                                        }}
                                        >
                                        <TextStyle variation="subdued">You can enable only those addons that you find useful for your business.</TextStyle>
                                    </div>

                                    <ResourceList
                                        resourceName={{ singular: "addon", plural: "addons" }}
                                        items={this.state.addons}
                                        resolveItemIds={({tag}) => {
                                            return tag;
                                        }}
                                            selectable={false}
                                        renderItem={(item, index) => {
                                            
                                            const media = item.img_src ? <div className="area-settings-nav__media">
                                                    <img src={item.img_src} alt={item.title}></img>
                                                </div> 
                                            : null;

                                            const priceCurrency = Common.getCurrency(item.price, money_format);

                                            return (
                                                <ResourceItem
                                                    id={index}
                                                    media={media}
                                                    accessibilityLabel={`View details for ${item.title}`}
                                                >
                                                    <Stack vertical spacing="extraTight">
                                                        <Stack distribution="equalSpacing" spacing="extraTight">

                                                            <Stack vertical spacing="extraTight">
                                                                <Stack spacing="extraTight" alignment="center">
                                                                    <TextStyle variation="strong">
                                                                        {item.title}
                                                                    </TextStyle>
                                                                        
                                                                        { !item.is_old && <Icon source={MinusMinor} color="subdued" /> }
                                                                        { !item.is_old && <Subheading>+{priceCurrency.amount_with_symbol}</Subheading> }
                                                                </Stack>
                                                                <Caption>{item.description}</Caption>
                                                                    {item.is_old && 
                                                                        <small>
                                                                            <TextStyle >
                                                                                <span>* This addon is already active, write email for any question. </span>
                                                                                <Link url="https://support.digitalcoo.com/hc/en-us/requests/new" external>contact us</Link> 
                                                                            </TextStyle>
                                                                        </small>
                                                                    }
                                                            </Stack>

                                                            <Stack alignment="center">
                                                                <Button url={item.tag === "import_export" && this.state.bulk_import_not_compatible && !bulk_import_enabled ? "https://support.digitalcoo.com/hc/en-us/requests/new" : item.link_url } size="slim" external>{item.tag === "import_export" && this.state.bulk_import_not_compatible && !bulk_import_enabled ? "Contact us" : item.link_label}</Button>
                                                                <Switch 
                                                                    onChange={()=>{ 
                                                                        let addons = this.state.addons;
                                                                        let enabledAddons = this.state.enabledAddonIndexes;
                                                                        let prevAddons = this.state.previoslyActiveAddons;
                                                                        addons[index].enabled = !addons[index].enabled;
                                                                        if(addons[index].enabled && !enabledAddons.includes(index) && !prevAddons.includes(index)){
                                                                                enabledAddons.push(index);
                                                                        }
                                                                        else if(!addons[index].enabled && enabledAddons.includes(index)){
                                                                            enabledAddons = enabledAddons.filter(item => item !== index);
                                                                        }
                                                                        this.setState({
                                                                            addons: addons,
                                                                            enabledAddonIndexes : enabledAddons
                                                                        });
                                                                    }}
                                                                            disabled={item.is_old /* || !can_change */ || (item.tag === "import_export" && this.state.bulk_import_not_compatible && !bulk_import_enabled)}
                                                                        checked={item.is_old || (item.enabled && item.enabled === true )} 
                                                                    height={20}
                                                                    width={42}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                        <Stack>
                                                            {
                                                                (item.tag === "import_export" && this.state.bulk_import_not_compatible && !bulk_import_enabled) &&
                                                                <Badge>To activate Bulk Import, contact us.</Badge>
                                                            }
                                                            {
                                                                this.state.addons[index].enabled && 
                                                                this.state.enabledAddonIndexes.includes(index) &&
                                                                this.state.addons[index].title !== "Bulk Import/Export" &&
                                                                changes_detected && 
                                                                <Badge status="info">The addon will be available for use in 1 to 2 business days after activation.</Badge>
                                                            }
                                                            {
                                                                this.state.addons[index].enabled && 
                                                                this.state.enabledAddonIndexes.includes(index) &&
                                                                this.state.addons[index].title === "Bulk Import/Export" &&
                                                                changes_detected && 
                                                                <Badge status="info">Ready to use after activation</Badge>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                    
                                                </ResourceItem>
                                            );
                                        }}
                                    />

                                    </Card>
                                    }
                                </Stack>
                            </Layout.Section>
                            <div className="Polaris-Layout__Section Polaris-Layout__Section--secondary" style={{position:"sticky", top: selected_addons.length > 4 ? "-1rem" : "1rem"}}>
                                <Card title="Subtotal">

                                    <Card.Section subdued >
                                        <Stack vertical spacing="extraTight">

                                            <Stack distribution="equalSpacing" spacing="extraTight">
                                                <TextStyle variation="strong">Current plan {current_subscription ? `(${current_subscription.plan_name})` : ""}</TextStyle>
                                                <Subheading>
                                                    {current_subscription_price}
                                                </Subheading>
                                            </Stack>

                                            {selected_addons.length > 0 && 
                                                <>
                                                    {selected_addons.map((selected_addon, index) => {
                                                        const priceCurrency = Common.getCurrency(selected_addon.price, money_format);

                                                        return (<Stack distribution="equalSpacing" spacing="extraTight">
                                                            <TextStyle variation="subdued">{selected_addon.title}</TextStyle>
                                                            <Subheading>
                                                                {priceCurrency.amount_with_symbol}
                                                            </Subheading>
                                                        </Stack>);
                                                    })}
                                                </>
                                            }

                                        </Stack>
                                    </Card.Section>

                                    <Card.Section subdued >
                                        <Stack distribution="equalSpacing" spacing="extraTight">
                                            <TextStyle variation="strong">Subscription fee</TextStyle>
                                            <Heading>
                                                {this.calculateSubtotal() || 0}
                                            </Heading>
                                        </Stack>
                                    </Card.Section>
                                    {
                                        this.state.shop_payment.status === "Pending" 
                                        && (this.state.trial_days && this.state.trial_days > 0)
                                        && this.state.selected_pricing_plan
                                        ? 
                                        <Card.Section>
                                            <div
                                                style= {{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <TextStyle>
                                                    {this.state.trial_days} days trial
                                                </TextStyle>
                                            </div>
                                        </Card.Section>
                                        
                                        : null
                                    }
                                    {
                                        (
                                            this.state.shop_payment.status === "active"
                                            && (this.state.trial_days && this.state.trial_days > 0)
                                        )
                                        ||
                                        (
                                            this.state.shop_payment.status === "Pending"
                                            && (this.state.trial_days && this.state.trial_days > 0)
                                            && this.state.trial_days < 14
                                        )
                                        ? 
                                        <Card.Section>

                                            <Stack distribution="equalSpacing" spacing="extraTight">
                                                <TextStyle variation="strong">Remaining trial</TextStyle>
                                                <TextStyle variation="strong">{this.state.trial_days} days</TextStyle>
                                            </Stack>
                                            
                                            {14 - this.state.trial_days > 0 ?
                                                <Stack distribution="equalSpacing" spacing="extraTight">
                                                    <TextStyle variation="strong">Used trial</TextStyle>
                                                    <TextStyle variation="strong">{14 - this.state.trial_days} days</TextStyle>
                                                </Stack>
                                            : ''}
                                            {
                                                this.state.shop_payment.status === "active"
                                                && (this.state.trial_days && this.state.trial_days > 0)
                                                ? 
                                                <div
                                                    style= {{
                                                        fontSize: '85%',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    <i><TextStyle variation="subdued">You will not be charged when switching plans during trial period.</TextStyle></i>
                                                </div>
                                                : null
                                            }
                                        </Card.Section>
                                        
                                        : null
                                    }
                                    {
                                        ((Number(this.calculateSubtotal().replace(/[^\d]/g, '')) > 0 && changes_detected) || (Number(this.calculateSubtotal().replace(/[^\d]/g, '')) > 0 && this.state.shop_payment.status === "Pending"))
                                        &&
                                    <Card.Section>
                                        <Stack vertical>
                                            <TextContainer>
                                                <p>
                                                    By clicking the button, you will be redirected to the Approve Charge page.
                                                </p>
                                            </TextContainer>
                                            <Button 
                                                submit 
                                                fullWidth 
                                                primary
                                                loading={this.state.saving}
                                            >
                                                    {this.state.shop_payment.status === "Pending" ? "Activate Subscription" : "Update Subscription"}
                                            </Button>
                                                
                                        </Stack>
                                    </Card.Section>
                                    }
                                </Card>
                            </div>
                        </Layout>
                    </Form>
                    {toast}
                </div>
            )
        }
        else{
            return(
                <SkeletonPageComp />
            )
        }
    }

}