import React, { Component } from "react";
import { Card /*, Collapsible,  Button */, Link } from "@shopify/polaris";

export default class FAQ extends Component {
    
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         q1_expanded: false,
    //         q2_expanded: false,
    //         q3_expanded: false,
    //         q4_expanded: false,
    //         q5_expanded: false
    //     };
    // }

  render() {

    // const {q1_expanded,q2_expanded,q3_expanded,q4_expanded,q5_expanded} = this.state;

    // const q1 = <Card.Section>
    //     <div className="collapsible-item">
    //         <div className="collapsible-item-header">
    //             <Button plain id="text-left"
    //                 onClick={ () => {
    //                         this.setState({
    //                             q1_expanded: !q1_expanded
    //                         });
    //                     }
    //                 }
    //                 ariaExpanded={q1_expanded}
    //             >
    //                     Can I have different prices for different wholesale customers?
    //             </Button>
    //         </div>
    //         <Collapsible open={q1_expanded} id="duplicate-theme">
    //             <p className="">
    //                 Yes! You can have as many prices for as many different customers as you want.
    //             </p>
    //         </Collapsible>
    //     </div>
    // </Card.Section>;
    
    // const q2 = <Card.Section>
    //     <div className="collapsible-item">
    //         <div className="collapsible-item-header">
    //         <Button plain id="text-left"
    //             onClick={ () => {
    //                     this.setState({
    //                         q2_expanded: !q2_expanded
    //                     });
    //                 }
    //             }
    //             ariaExpanded={q2_expanded}
    //         >
    //             Can I add special shipping rates for wholesale customers?
    //         </Button>
    //         </div>
    //         <Collapsible open={q2_expanded} id="get-started">
    //             <p className="">
    //                 Yes! You can add special shipping rates for wholesale customers and regular customers.
    //             </p>
    //         </Collapsible>
    //     </div>
    // </Card.Section>;
    
    // const q3 = <Card.Section>
    //     <div className="collapsible-item">
    //         <div className="collapsible-item-header">
    //             <Button plain id="text-left"
    //                 onClick={ () => {
    //                         this.setState({
    //                             q3_expanded: !q3_expanded
    //                         });
    //                     }
    //                 }
    //                 ariaExpanded={q3_expanded}
    //             >
    //                 Can customers apply coupon code to get additional discount?
    //             </Button>
    //         </div>
    //         <Collapsible open={q3_expanded} id="usage-tracking">
    //             <p className="">
    //                 Yes! Customers can apply coupon code on the cart page to get additional discounts.
    //             </p>
    //         </Collapsible>
    //     </div>
    // </Card.Section>;

    // const q4 = <Card.Section>
    //     <div className="collapsible-item">
    //         <div className="collapsible-item-header">
    //             <Button plain id="text-left"
    //                 onClick={ () => {
    //                         this.setState({
    //                             q4_expanded: !q4_expanded
    //                         });
    //                     }
    //                 }
    //                 ariaExpanded={q4_expanded}
    //             >
    //                 Can I apply regular wholesale pricing and Quantity Breaks? 
    //             </Button>
    //         </div>
    //         <Collapsible open={q4_expanded} id="usage-tracking">
    //             <p className="">
    //                 Yes! You can create separate lists for wholesale pricing and Quantity Breaks.
    //             </p>
    //         </Collapsible>
    //     </div>
    // </Card.Section>;

    // const q5 = <Card.Section>
    //     <div className="collapsible-item">
    //         <div className="collapsible-item-header">
    //             <Button plain id="text-left"
    //                 onClick={ () => {
    //                         this.setState({
    //                             q5_expanded: !q5_expanded
    //                         });
    //                     }
    //                 }
    //                 ariaExpanded={q5_expanded}
    //             >
    //                 Can I apply Quantity Breaks at variant levels? 
    //             </Button>
    //         </div>
    //         <Collapsible open={q5_expanded} id="usage-tracking">
    //             <p className="">
    //                 Yes, You can apply Quantity Breaks discounts on variant, Product, collection and Entire store level.
    //             </p>
    //         </Collapsible>
    //     </div>
    // </Card.Section>;

    return (
      <div>
        <Card
            title="Frequently Asked Questions"
        >
            <Card.Section>
            You can access frequently asked questions in our Knowledge Base. <Link url="https://support.digitalcoo.com/hc/en-us/articles/360045114172" external>Click to view details</Link>.
            </Card.Section>
        </Card>
      </div>
    );
  }
}