import React, { Component } from 'react';
import { ResourceList, ResourceItem, Checkbox, Modal, TextField, Icon, Stack, TextStyle } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import Axios from '../Axios';
import store from 'store2';
import SkeletonPageComp from './SkeletonPageComp';

export default class AddTagsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiTags: [],
            renderingTags: [],
            selectedTags: this.props.initialTags || [],
            search: '',
            newTag: '',
            page_loaded: false
        };
    }

    componentDidMount() {
        if(store("popupTags") && store("shop") && store("shop") === this.props.shop) {
            let _popupTags = JSON.parse(store("popupTags"));
            let _renderingTags = [...new Set(this.state.selectedTags.concat(_popupTags))];
            _renderingTags = _renderingTags
                .filter((tag, index, self) => tag !== '' && self.findIndex(t => t.toLowerCase() === tag.toLowerCase()) === index)
                .sort();

            this.setState({ 
                apiTags: _popupTags,
                renderingTags: _renderingTags,
                page_loaded: true
            });
        }
        else {
            Axios.get('/customer/getTags?shop=' + this.props.shop).then((response) => {
                if(response.data && response.data.length > 0){
                    store("popupTags", JSON.stringify(response.data));
                    let _renderingTags = [...new Set(this.state.selectedTags.concat(response.data))];
                    _renderingTags = _renderingTags
                        .filter((tag, index, self) => tag !== '' && self.findIndex(t => t.toLowerCase() === tag.toLowerCase()) === index)
                        .sort();
        
                    this.setState({ 
                        apiTags: _renderingTags,
                        renderingTags: _renderingTags,
                        page_loaded: true
                    });
                }
                else {
                    this.setState({ page_loaded: true });
                }
            }).catch((error) => {
                console.error('Error: ', error);
            });
        }
    }

    searchQuery = (value) => {
        let { apiTags } = this.state;
        let _value = value?.trim()?.toLowerCase();
        if(_value !== '') {
            _value = _value.trim();
            this.setState({ search: value, newTag: value });
            let _renderingTags = [];
            
            apiTags.forEach((tag) => {
                if(tag?.trim()?.toLowerCase() === _value) {
                    this.setState({ newTag: '' });
                }
                if(tag?.trim()?.toLowerCase().startsWith(_value)) {
                    _renderingTags = _renderingTags.concat(tag);
                }
            })
            _renderingTags = _renderingTags.filter((tag) => tag !== '').sort();

            if(!apiTags.some(x => x?.trim()?.toLowerCase() === _value)) {
                _renderingTags = [value].concat(_renderingTags);
            }

            this.setState({ renderingTags: _renderingTags });
        }
        else {
            this.setState({ search: value, renderingTags: apiTags, newTag: '' });
        }
    }

    render(){
        let { selectedTags, page_loaded, search, renderingTags, newTag } = this.state;
        return(
            <Modal
                size="Medium"
                open={true}
                title="Select to add tags"
                onClose={this.props.onClose}
                primaryAction={{
                    content: 'Add selected tags',
                    onAction: () => this.props.onAdd(selectedTags) 
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: this.props.onClose
                    }
                ]}
            >
                {
                    page_loaded ?
                    <Modal.Section>
                        <TextField
                            value={search}
                            labelHidden
                            onChange={this.searchQuery}
                            prefix={<Icon source={SearchMinor} />} 
                            placeholder="Search tags"
                        />
                        <ResourceList
                            items={renderingTags}
                            renderItem={(tag) => {
                                return (
                                    <ResourceItem
                                        onClick={() => {
                                            if(selectedTags.some(x => x?.trim()?.toLowerCase() === tag?.trim()?.toLowerCase())) {
                                                this.setState({ selectedTags: selectedTags.filter((t) => t?.trim()?.toLowerCase() !== tag?.trim()?.toLowerCase()) });
                                            }
                                            else {
                                                this.setState({ selectedTags: [...selectedTags, tag] });
                                            }
                                        }}
                                    >
                                        {
                                            (newTag !== '' && tag === newTag) ?
                                                <Checkbox
                                                    label={<div>
                                                        <Stack>
                                                            <Stack.Item fill>
                                                                <pre
                                                                    style={{ display: 'inline' }}
                                                                >
                                                                    {tag}
                                                                </pre>
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <div
                                                                    style={{ fontSize: '85%' }}
                                                                >
                                                                    <TextStyle variation="subdued">(Create new tag)</TextStyle>
                                                                </div>
                                                            </Stack.Item>
                                                        </Stack>
                                                    </div>}
                                                    checked={selectedTags.some(x => x?.trim()?.toLowerCase() === tag?.trim()?.toLowerCase())}
                                                />
                                            :
                                                <Checkbox
                                                    label={<pre
                                                        style={{ display: 'inline' }}
                                                    >
                                                        {tag}
                                                    </pre>}
                                                    checked={selectedTags.some(x => x?.trim()?.toLowerCase() === tag?.trim()?.toLowerCase())}
                                                />
                                        }

                                    </ResourceItem>
                                );
                            }}
                        />
                    </Modal.Section>
                    :
                    <Modal.Section>
                        <SkeletonPageComp></SkeletonPageComp>
                    </Modal.Section>
                }
            </Modal>
        );
    }
}