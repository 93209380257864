import store from 'store2';
import querystring from 'querystring';
import _Modules from '../Json/modules.json';

var _module = {};

function isPositiveInteger(r){return/^\d+$/.test(r)}

_module.checkVersion = function(r,t){var e=r.split("."),n=t.split(".");function i(r){for(var t=0;t<r.length;++t)if(!isPositiveInteger(r[t]))return!1;return!0}if(!i(e)||!i(n))return NaN;for(var u=0;u<e.length;++u){if(n.length===u)return 1;if(e[u]!==n[u])return e[u]>n[u]?1:-1}return e.length!==n.length?-1:0};

_module.getShop = function() {
    const parsed = querystring.parse(window.location.search);
    return parsed.shop || store('shop');
}

_module.getHostName = function() {
    return process.env.REACT_APP_SERVER_URL;
}

_module.getAppHandle = function() {
    return process.env.REACT_APP_APPLICATION_HANDLE;
}

_module.appVersion = function(version) {
    switch (version) {
        case "240":
            return "2.4.0";
        case "239":
            return "2.3.9";
        case "238":
            return "2.3.8";
        case "237":
            return "2.3.7";
        case "236":
            return "2.3.6";
        case "235":
            return "2.3.5";
        case "234":
            return "2.3.4";
        case "233":
            return "2.3.3";
        case "232":
            return "2.3.2";
        case "231":
            return "2.3.1";
        case "230":
            return "2.3.0";
        case "229":
            return "2.2.9";
        case "228":
            return "2.2.8";
        case "227":
            return "2.2.7";
        case "226":
            return "2.2.6";
        case "225":
            return "2.2.5";
        default:
            return version;
    }
}

_module.getSnippetVersion = function(version) {
    const parsed = querystring.parse(window.location.search);
    var snp_version = store('snippet_version') || process.env.REACT_APP_SNIPPET_VERSION || "2.3.8";
    if(parsed && parsed.wsversion && _module.appVersion(parsed.wsversion)){
        snp_version = _module.appVersion(parsed.wsversion);
        if(snp_version === '2.3.8'){
            store('ignore_live_file',true);
        }
        
    }
    else if(parsed && parsed.wsvv && _module.appVersion(parsed.wsvv)){
        snp_version = _module.appVersion(parsed.wsvv);
        if(snp_version === '2.3.8'){
            store('ignore_live_file',true);
        }
    }
    else if(version){
        snp_version = _module.appVersion(version);
    }
    if (store("wsv_stc") !== "1" || !store('ignore_live_file')) {
        store('snippet_version', snp_version);
    }
    if(!store('snippet_version')){
        store('snippet_version', snp_version);
    }
    return snp_version;
}

_module.isAdmin = function(){
    const parsed = querystring.parse(window.location.search);
    var isAdmin = parsed.wsadmin || store("wsAdmin");
    if(isAdmin){
        store("wsAdmin", parsed.wsadmin); 
    }
    else{
        store.remove("wsAdmin");
    }
    if(isAdmin && isAdmin.trim().toLowerCase() === "yes"){
        isAdmin = true;
    }
    else{
        isAdmin = !1;
    }
    return isAdmin?!0:!1;
}

_module.getVersionComponent = function(params, callback){
    var versions = params.versions,
    default_version = params.default_version,
    snippet_version = _module.getSnippetVersion() || '2.3.8',
    counter = 0;
    function findVersion(_version, callback) {
      if (_version) {
        var index = !1;
        try {
          index = _module.checkVersion(snippet_version, _Modules.find(x => x._id === _version.id).snippet_version) > -1;
        } catch (e) {}
        if (index) {
          if (typeof callback === 'function') {
            callback(_version);
          }
        } else {
          counter++;
          findVersion(versions[counter], callback);
        }
      } else {
        if (typeof callback === 'function') {
          callback(default_version);
        }
      }
    }
    findVersion(versions[counter], callback);
}

_module.getId = function(id){
  try {
    if(id.indexOf("/")>-1){
      id = id.split("/")[id.split("/").length-1];
    }
  } catch (e) { }
  return id;
}

_module.wsId = function(length) {
  var text = "";var result = "";length = length || 6;
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for(var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  for (var j=0; j < text.length; j++) {
      result += text.charCodeAt(j).toString(16);
  }
  return result;
}

_module.checkLength = function(a){
  if(typeof a === "object"){
      return JSON.stringify(a).length
  }
  return a.length
}

_module.getSchedule2 = function(schedule) {
    let start_date = schedule.start_date || null;
    let end_date = schedule.end_date || null;
    if(start_date){
        let now = new Date();
        let start = new Date(start_date);
        if(end_date) {
            let end = new Date(end_date);
            if (now > start && now < end) {
                return 1;
            }
            else if (now < start) {
                return 0;
            }
            else if (now > end) {
                return -1;
            }
        }
        else {
            if (now > start) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }
    else {
        return -2;
    }
}

_module.getSchedule = function(schedule){
  function est_time_now() {
      var tN = new Date();
      tN.setTime(tN.getTime() + tN.getTimezoneOffset() * 60 * 1000);
      var offset = -300, estDate = new Date(tN.getTime() + offset * 60 * 1000);
      return estDate.getTime();
  }
  function getTimezoneOffset() {
      function z(n) { return (n < 10 ? '0' : '') + n }
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0 ? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset / 60 | 0) + z(offset % 60);
  }
  function date_time_value(date, time) {
      var dateTimeValue = new Date(date);
      if (typeof dateTimeValue === 'object') {
          try {
              dateTimeValue = dateTimeValue.toString();
              if (typeof dateTimeValue === 'string') {
                  dateTimeValue = dateTimeValue.replace(getTimezoneOffset(), '+0000');
              }
          } catch (e) { }
      }
      try {
          dateTimeValue = new Date(dateTimeValue).toISOString();
      } catch (e) { }
      try {
          dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
      } catch (e) { }
      return dateTimeValue;
  }
  function diff_in_schedule(date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      if (date1 === 'Invalid Date' || date2 === 'Invalid Date') {
          return 0;
      }
      if (!date1 || !date2) return 0;
      if (typeof date1 !== 'object' || typeof date2 !== 'object') {
          return 0;
      }
      if (date1 > date2) {
          var result = diff_in_schedule(date2, date1);
          result.years = -result.years;
          result.months = -result.months;
          result.days = -result.days;
          result.hours = -result.hours;
          result.minutes = -result.minutes;
          result.seconds = -result.seconds;
          return result;
      }
      result = {
          years: date2.getYear() - date1.getYear(),
          months: date2.getMonth() - date1.getMonth(),
          days: date2.getDate() - date1.getDate(),
          hours: date2.getHours() - date1.getHours(),
          minutes: date2.getMinutes() - date1.getMinutes(),
          seconds: date2.getSeconds() - date1.getSeconds()
      };
      if (result.seconds < 0) {
          result.minutes--;
          result.seconds += 60;
      }
      if (result.minutes < 0) {
          result.hours--;
          result.minutes += 60;
      }
      if (result.hours < 0) {
          result.days--;
          result.hours += 24;
      }
      if (result.days < 0) {
          result.months--;
          var copy1 = new Date(date1.getTime());
          copy1.setDate(32);
          result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
      }
      if (result.months < 0) {
          result.years--;
          result.months += 12;
      }
      return result;
  }
  var end_schedule_difference = {
      "years": 1,
      "months": 1,
      "days": 1,
      "hours": 1,
      "minutes": 1,
      "seconds": 1
  },
  start_schedule_difference = {
      "years": -1,
      "months": -1,
      "days": -1,
      "hours": -1,
      "minutes": -1,
      "seconds": -1
  },
  _est = est_time_now();
  if(schedule){
      var schedule_started;
      if(schedule.start_date && schedule.start_time_est){
          schedule_started = date_time_value(schedule.start_date, schedule.start_time_est);
          start_schedule_difference = diff_in_schedule(_est, schedule_started);
      }
      if(schedule.end_date && schedule.end_time_est){
          schedule_started = date_time_value(schedule.end_date, schedule.end_time_est);
          end_schedule_difference = diff_in_schedule(_est, schedule_started);
      }
  }
  return {
    ends_in: end_schedule_difference,
    started: start_schedule_difference,
    est_time_now: _est
  }
}

_module.getPlanID = function(plans_permissions) {
    if (_module.isAdmin()) {
        return 3;
    }
    var uid = store('_wsuuid') || null;
    var _id = 1;
    if (uid === "374d30586f55") {
        _id = 1;
    }
    if (uid === "366c51353677") {
        _id = 2;
    }
    if (uid === "30776d463454") {
        _id = 3;
    }
    return _id;
}


// ---------------------------------------------------------------------------------------
// Money format handler - Will parse the amount into store currency and return an object:
//      {
//          symbol: currency_symbol (string) =>              "$",       "¥",       "£"
//          amount_with_symbol: formatted_money (string) =>  "$50.35"   "¥50.00"   "£0.00"
//          amount: parsed_amount (string) =>                 "50.35"    "50.00"    "0.00"
//      }
// ---------------------------------------------------------------------------------------
// eslint-disable-next-line
const default_money_format = "${{amount}}";
_module.getCurrency = function(cents, format) {
    if (typeof cents !== 'number') { cents = Number( cents ); }
  var value = '';
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = (format || default_money_format);

  function defaultOption(opt, def) {
     return (typeof opt == 'undefined' ? def : opt);
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, '');
    decimal   = defaultOption(decimal, '.');

    if (isNaN(number) || number === null) { return "0.00"; }

    number = (number).toFixed(precision);

    var parts   = number.split('.'),
        dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
        cents   = parts[1] ? (decimal + parts[1]) : '';

    return dollars + cents;
  }

  switch(formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
    //   value = formatWithDelimiters(cents, 2, '.', ',');
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals_with_comma_separator':
    //   value = formatWithDelimiters(cents, 0, '.', ',');
      value = formatWithDelimiters(cents, 0);
      break;
    default:
        value = formatWithDelimiters(cents, 2);
        break;
  }

  return {
      symbol: formatString.replace(placeholderRegex, ''),
      amount_with_symbol: formatString.replace(placeholderRegex, value),
      amount: value
  };
};

export default _module;