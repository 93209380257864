import React, { Component } from 'react';
import {EmptyState,Banner,Layout,FormLayout,TextField,Card,Button,List, Form, Heading, RadioButton, Stack, TextStyle, Link } from '@shopify/polaris';
import SkeletonPageComp from './SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import AccountSetupInfo from './AccountSetupInfo';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

            shopData: null,
            shopifyData:null,

            // Staff account instructions 
            show_email_success: false,
            show_staff_instructions: false,
            show_collaborator_instructions: false,
            show_dashboard_link: false,
            later_access_link: false,
            customer_accounts_disabled: false,
            
            // Change content based on this
            reinstalled: false, 

            // clicking sumbit button 
            sending_request: false,

            // Email data 
            email_subject: "Request to integrate wholesale app",
            email_replyto: "",
            email_name: "",
            email_body: "Hi Wholesale app support team, \nCould you please integrate the Wholesale All In One app script to our website https://"+this.props.shop+"/?\nThanks",

            /* If ajax has some errors, will render the array */
            errorList: [],

            /* Skeleton page until data loaded */
            page_loaded: false,

            /* Toast specefic */
            showToast: false,
            toastError: false,
            toastMsg: "",

            access: ""
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    /* Lifecycle: Begin component */
    componentDidMount() {

        this.getShopData();
    }

    /* Get data of shop */
    getShopData(){
        axios.get('/app/v238/shop-data?shop='+this.props.shop)
        .then(x=>x.data)
        .then(response => {

            if(typeof response.error !== "undefined" && response.error){
                this.showToastMessage( response.error, true);
                return;
            }

            const install_status = response.shopData && response.shopData.install_status ? response.shopData.install_status : 'in_development'; 

             // if customer account is "DISABLED" we need to change screenshot
            // possible values => https://shopify.dev/api/admin-graphql/latest/enums/ShopCustomerAccountsSetting
            const customer_accounts_disabled = response.shopifyData && response.shopifyData.customer_accounts && response.shopifyData.customer_accounts === "DISABLED" ? true : false; 

            let state = {
                page_loaded: true,
                customer_accounts_disabled: customer_accounts_disabled,
                shopifyData: response.shopifyData,
                email_replyto: response.shopifyData.email
            }

            if(install_status === "requested"){
                state.show_staff_instructions = true;
            }

            if(install_status === "awaiting_request") {
                state.show_collaborator_instructions = true;
            }
            
            // For now don't change the text on re-installation
            // else if(install_status === "in_development" && response.shopData && response.shopData.status === "active" && response.shopData.reinstalled_on !== null){
            //     state.email_subject = "Wholesale All In One application re-integration";
            //     state.email_body = "Some reinstalled body";
            // }

            this.setState(state);
            
        })
        .catch(error => {
            // Error
            let msg = "Server Error";
            if (error.response && error.response.data && error.response.data.error) {
                msg = error.response.data.error;
            }

            this.setState({ saving: false });
            this.showToastMessage(msg, true);
            
        });
    }

    /* Show/Hide toast */
    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }

    /* After save, scroll to top */
    scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    validate(){
        var is_valid = true;
        var errors=[];

        if(this.state.email_subject.trim()===''){
            is_valid = false;
            errors.push("Email subject is required.");
        }

        if(this.state.email_body.trim()===''){
            is_valid = false;
            errors.push("Email body is required.");
        }

        if(this.state.access === ""){
            is_valid = false;
            errors.push("Please select access option.");
        }

        if(!is_valid){
            let msg = `There ${errors.length > 1?"are":"is"} ${errors.length } ${errors.length > 1?"errors":"error"}`;
            this.showToastMessage(msg, true);
            this.setState({ errorList : errors });
            this.scrollToTop();
        }
        else{
            this.setState({ errorList : [] });
        }

        return is_valid;

    }

    /* Update the config */
    handleSubmit(){

        if(this.validate()){
            /* Send update request */
            this.setState({ sending_request: true, errorList: [] });

            const url = '/app/v238/contact-us';

            axios.post(url, {
                shop:this.props.shop,
                email: {
                    subject: this.state.email_subject,
                    body: this.state.email_body,
                    replyto: this.state.email_replyto,
                    name: this.state.email_name,
                },
                access: this.state.access,
                shopifyData: this.state.shopifyData
            })
            .then(x=>x.data)
            .then(response => {
                this.setState({ sending_request: false });

                if(typeof response.error !== "undefined" && response.error){
                    this.showToastMessage("Server Error", true);
                    return;
                }
                
                if(typeof response.someInValidEmails && response.someInValidEmails && response.someInValidEmails === true){
                    this.showToastMessage(response.message);
                }
                else if(typeof response.inValidEmail && response.inValidEmail && response.inValidEmail === true){
                    this.showToastMessage(response.message);
                }
                else{
                    this.showToastMessage("Email has been sent!", false);
                }

                if(this.state.access === "collaborator"){
                    this.setState({show_collaborator_instructions: true});
                }
                else if(this.state.access === "staff"){
                    this.setState({show_staff_instructions: true});
                }
                else if(this.state.access === "already_provided"){
                    this.setState({show_dashboard_link: true});
                }
                else if(this.state.access === "access_later"){
                    this.setState({later_access_link: true});
                }

                // Load staff account creation component
                this.scrollToTop();

            }).catch(error => {
                this.setState({ sending_request: false });

                this.showToastMessage("Error", true);
            });
        }

    }

    /* Lifecycle: Main Event */
    render() {

        /* If we have errors to display */
        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are":"is"} ${this.state.errorList.length } ${this.state.errorList.length > 1?"errors":"error"}`}>
                <List type="number">
                {
                    this.state.errorList.map((error,iN) => {
                        return <List.Item key={iN}>{error}</List.Item>
                    })
                }
                </List>
            </Banner>
        </div>

        
        /* Will show toast based on state */
        const { show_collaborator_instructions, access, show_dashboard_link, show_staff_instructions, later_access_link } = this.state;
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        const staff_instructions = <AccountSetupInfo shopData={this.props.shopData} customerAccountsDisabled={this.state.customer_accounts_disabled} shop={this.props.shop}/>;
        const later_access_area = <div>
            <div style={{margin:"2rem 0"}}>
                <Banner title="Success" status='success'>
                 Your email has been successfully sent. You can now proceed to the dashboard.
                </Banner>
            </div>
            <div style={{marginTop:'10px'}}></div>
            <div style={{textAlign: 'center'}}>
                <Button
                    primary
                    url={BUILD_VERSION_EXTENSION+'/dashboard'}
                    > Go to Dashboard 
                </Button>
            </div>
        </div>
        const dashboard_area = <div>
            <div style={{margin:"2rem 0"}}>
                <Banner title="Success" status='success'>
                  Your email has been successfully sent. You can now proceed to the dashboard.
                </Banner>
            </div>
            <div style={{marginTop:'10px'}}></div>
            <div style={{textAlign: 'center'}}>
                <Button
                    primary
                    url={BUILD_VERSION_EXTENSION+'/dashboard'}
                    > Go to Dashboard 
                </Button>
            </div>
        </div>
        const collaborator_instructions = <div>
        
            <div style={{margin:"2rem 0"}}>
                <Banner title="Success" status='success'>
                Your email has been successfully sent.
                </Banner>
            </div>
            <div style={{marginTop:'10px'}}></div>
            {/* <Card title="Access Instructions" sectioned> */}
            <Card>
                <Card.Section>
                    <div style={{padding:"1rem"}}>
                        <Heading>Please wait:</Heading>
                        <p>
                        Since you have selected the ‘collaborator account’ method to grant access, please wait for an email from our installation team. We’ll send you a collaborator account request within 12 to 36 hours.
                        </p>
                        <div style={{marginTop:'18px'}}></div>
                        <div style={{fontSize: '15.5px', color: 'grey', fontWeight: '500'}}>Can’t wait?</div>
                            <p>
                            If you don’t want to wait for our email, you can alternatively follow the <Link external url="https://support.digitalcoo.com/hc/en-us/articles/360044981891" >Create Staff Account</Link> article to grant access instantly. We’ll begin the integration process as soon as possible. 
                            </p> 
                        <div style={{marginTop:'20px'}}></div>
                        <div style={{textAlign: 'center'}}>
                            <Button
                                primary
                                url={BUILD_VERSION_EXTENSION+'/dashboard'}
                                > Go to Dashboard 
                            </Button>
                        </div>
                    </div>
                </Card.Section>
            </Card>
        </div>;
        const contact_us = <Layout>
                                { this.state.errorList.length > 0 && <Layout.Section>{errors_section}</Layout.Section> }
                                <Layout.Section>
                                    <Card title="Wholesale All In One app integration request form" sectioned>

                                        {this.state.show_email_success ? 

                                            <EmptyState
                                                heading="Action required - setup staff account"
                                                action={{ 
                                                    content: "Create staff account", 
                                                    onAction: () => {

                                                        this.scrollToTop();
                                                        this.setState({show_staff_instructions: true});
                                                        
                                                    } 
                                                }}
                                                image="/images/tick.png"
                                            >
                                                <div>
                                                    <div className="install-status-description">
                                                        <p style={{ maxWidth: '750px', margin: 'auto', textAlign:'center' }} >
                                                            In order to ensure your theme will run smoothly with our app, we’ll make all of the necessary adjustments depending on other apps or scripts your theme is running. We provide this service free of charge.
                                                        </p>
                                                    </div>
                                                    <div style={{marginTop:"1rem"}}>
                                                        <div style={{ textAlign: "center" }}>
                                                            <strong>
                                                                Please allow up to 1 to 3 business days for us to complete the process.
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EmptyState>

                                        :

                                            <Form onSubmit={() => {
                                                this.handleSubmit();
                                            }}>
                                                <FormLayout>

                                                    <Stack distribution="fillEvenly">
                                                        <Stack.Item>
                                                            <TextField 
                                                                label="Name" 
                                                                value={this.state.email_name}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        email_name: value
                                                                    });
                                                                }}
                                                                autoComplete="off" 
                                                            />
                                                        </Stack.Item>

                                                        <Stack.Item>
                                                            <TextField 
                                                                label={<span>Email <span style={{fontSize: '85%'}}><TextStyle variation="subdued">(For receiving updates)</TextStyle></span></span>}
                                                                value={this.state.email_replyto}
                                                                error={this.state.email_replyto.length < 1 && "Please enter email address"}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        email_replyto: value
                                                                    });
                                                                }}
                                                                helpText="You may add multiple emails separated by commas (one@email.com,two@email.com)"
                                                                autoComplete="off" 
                                                            />
                                                        </Stack.Item>
                                                    </Stack>

                                                    <TextField 
                                                        label="Subject" 
                                                        value={this.state.email_subject}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                email_subject: value
                                                            });
                                                        }} 
                                                        autoComplete="off" 
                                                    />

                                                    

                                                    <TextField
                                                        label="Description"
                                                        value={this.state.email_body}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                email_body: value
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        multiline={4}
                                                    />

                                <Stack vertical spacing='tight'>
                                    <Heading>We require temporary access to integrate the app. Please choose the appropriate option:</Heading>
                                    <Stack spacing='tight'>
                                        <Stack vertical spacing='extraTight'>
                                            <RadioButton
                                                label="Send me a collaborator account request"
                                                name="access"
                                                checked={access === "collaborator"}
                                                onChange={() => {
                                                    this.setState({access: "collaborator"});
                                                }}
                                            />
                                            <div style={{fontSize: '85%', marginLeft: '28px', marginTop: '-5px'}}>
                                                <TextStyle variation="subdued">(This method may take some time)</TextStyle>
                                            </div>
                                        </Stack>
                                        <Stack vertical spacing='extraTight'>
                                            <RadioButton
                                                label="I will create a staff account"
                                                name="access"
                                                checked={access === "staff"}
                                                onChange={() => {
                                                    this.setState({access: "staff"});
                                                }}
                                            />
                                            <div style={{fontSize: '85%', marginLeft: '28px', marginTop: '-5px'}}>
                                                <TextStyle variation="subdued">(Grant instant access)</TextStyle>
                                            </div>
                                        </Stack>
                                        <RadioButton
                                            label="Access already granted"
                                            name="access"
                                            checked={access === "already_provided"}
                                            onChange={() => {
                                                this.setState({access: "already_provided"});
                                            }}
                                        />
                                        <RadioButton
                                            label="Skip for now"
                                            name="access"
                                            checked={access === "access_later"}
                                            onChange={() => {
                                                this.setState({access: "access_later"});
                                            }}
                                        />
                                    </Stack>
                                    {
                                        access === "collaborator" &&
                                        <TextStyle variation="subdued">The Wholesale All In One app installation team will send you a collaborator account request within 12 to 36 hours. Once you approve the request, we’ll integrate the app into your website.</TextStyle>
                                    }
                                    {
                                        access === "staff" &&
                                        <TextStyle variation="subdued">You’ll give us access to the dashboard by creating a staff account for our team. After that, we’ll integrate the Wholesale All In One app.</TextStyle>
                                    }
                                </Stack>

                                                    <Button 
                                                        submit 
                                                        primary
                                                        loading={this.state.sending_request}
                                                        disabled={this.state.sending_request || this.state.email_replyto.length < 1}
                                                    >
                                                        Send email
                                                    </Button>
                                                </FormLayout>
                                            </Form>
                                        }
                                    </Card>
                                </Layout.Section>
        </Layout>;
        return(
            <div>
                <PageTitleBar
                    title="Integration request form"
                    breadcrumbs={[
                        { content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/" }
                    ]}
                />
                {this.state.page_loaded ?
                    <>
                        {show_staff_instructions ?  staff_instructions : show_collaborator_instructions ? collaborator_instructions : show_dashboard_link ?  dashboard_area : later_access_link ? later_access_area : contact_us
                        }
                    </>
                :
                    <SkeletonPageComp />
                }
                {toast}
            </div>
        );
    }

}