import React, { Component } from 'react';
import { Card, Stack, Button, DataTable, ButtonGroup, Badge, Link, EmptyState, Banner, TextStyle } from '@shopify/polaris';
import store from 'store2';
import _Modules from '../Json/modules.json';
import waio from '../Helpers/functions';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import Common from '../Helpers/Common';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var snippet_version = store('snippet_version') || "2.3.8";
export default class ManageShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: this.props.shop,
            allShippingRules: [],
            page_loaded: false,
            settings: [],
            showToast: false,
            toastMsg: '',
            toastError: false,
            duplicatedTags: []
        };
        store.remove('products');
        store.remove('collections');
    }
    disableSave = false;
    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }

    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }
    needToUpgrade = false;

    componentDidMount(){
        this.getAllShippings();
        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }
        if(this.props.shop){
            this.getSettings();
        }
    }

    getSettings(){
        axios.get('/app/settings/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({settings:r.data});
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    getAllShippings(){
        axios.get('/app/all-shippings/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                var tags = [], duplicatedTags = [];
                try {
                    r.data.forEach(rule => {
                        rule.customer_tags.forEach(tag => {
                            var ind = tags.findIndex(x => x.toLowerCase() === tag.toLowerCase());
                            if(ind === -1){
                                tags.push(tag)
                            }
                            else{
                                duplicatedTags.push(tag);
                            }
                        });
                    });
                } catch (e) { }
                this.setState({allShippingRules:r.data,page_loaded:true, duplicatedTags: duplicatedTags});
            }
            else {
                this.showToastMessage("Server error", true);
            }
            this.setState({page_loaded:true});
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
            this.setState({page_loaded:true});
        });
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.title}?`)){
            axios.delete('/app/delete-shipping/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    showTag(item){
        var ht = item.customer_tags.map((tag,i)=>{
            var isDuplicatdTag = false;
            if(-1<this.state.duplicatedTags.findIndex(x => x.toLowerCase() === tag.toLowerCase())){
                isDuplicatdTag = true;
            }
            return <div key={i}><Badge status={isDuplicatdTag?"critical":""}>{tag}</Badge></div>
        });
        return <Stack spacing="extraTight">{ht}</Stack>;
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.title}?`)){
            var data = {
                _id: item._id,
                status: "disabled"
            };
            axios.put('/app/update-status-shipping/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    enableRule(item){
        var data = {
            _id: item._id,
            status: "active"
        };
        axios.put('/app/update-status-shipping/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllShippings();
                }
                else{
                    this.showToastMessage("Server error", true);
                }
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    duplicateTagsBanner(){
        var isDuplicated = false;
        var allShippingRules = this.state.allShippingRules;
        var tags = [];
        var provices = [];
        allShippingRules.forEach(rule => {
            if(rule.status === "active"){
                rule.customer_tags.forEach(tag => {
                    var ind = tags.findIndex(x => x.toLowerCase() === tag.toLowerCase());
                    if(ind === -1){
                        tags.push(tag)
                    }
                    else{
                        isDuplicated = true;
                    }
                });

                // Check if tags matched, need to check if country selection are different
                if(rule.country_selection === "selected" && rule.selected_countries.length > 0){
                    // Generate indexed array of province codes prefixed with country code like CA-AB, US-AL 
                    const province_codes = rule.selected_countries.flatMap(x=>x.provinces.map(y=>x.code+'-'+y.code));
                    let province_found = false;
                    province_codes.forEach(province_code => {
                            
                        const index = provices.indexOf(province_code);
                        if(index === -1)provices.push(province_code);
                        else province_found = true;
                        
                    });
                
                    if(isDuplicated && !province_found){
                        // Means provinces are unique, this is not dupllicate case, revert the variable
                        isDuplicated = false;
                    }
                }
            }

        });
        var html = null;
        if(isDuplicated){
            html = <Banner
                status="warning"
                title="Duplicate tags found!"
            >
                <p>
                You're not allowed to use a tag in multiple shipping rules with common location. <Link url="https://support.digitalcoo.com/hc/en-us/articles/7253297323924" external>Learn more</Link>
                </p>
            </Banner>
        }
        return html;
    }

    render() {
        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }
        const min_orders_list_rows = this.state.allShippingRules.map((item,i)=>[
            <span>
                {item.title.substring(0,40)}{item.title.length > 40?"...":null}
            </span>,
            <span>
            <span style={{textTransform:"capitalize"}}>{item.customer_group !== "tag_based" ?item.customer_group.replace("_"," "):null}</span>
            {
                item.customer_group === "tag_based" ?
                    <div className="show--tags">
                        {this.showTag(item)}
                    </div>
                :null
            }
            </span>,
            item.message?
            <span>
                {item.message.substring(0,40)}{item.message.length > 40?"...":null}
            </span>
            :"N/A",
            <span>
                {
                    item.status === "active"?
                    <Badge status="success">Active</Badge>
                    :<Badge>Disabled</Badge>
                }
            </span>,
            <span>
                <ButtonGroup segmented>
                    <Button size="slim"
                        url={BUILD_VERSION_EXTENSION+'/manage-shipping/'+item._id}
                        target="APP"
                    > Edit
                    </Button>
                    {
                        Common.getPlanID() !== 1?
                            item.status === "active"?
                            <Button size="slim" 
                                onClick={() => {this.disableRule(item)}}
                            >
                            Disable
                            </Button>
                            :<Button size="slim" 
                                onClick={() => {this.enableRule(item)}}
                            >
                                Enable
                            </Button>
                        :null
                    }
                    <Button
                        size="slim"
                        onClick={() => {this.deleteRule(item)}}
                    >
                        Delete
                    </Button>
                </ButtonGroup>
            </span>
        ])

        const empty_state = <EmptyState
            heading="Create a shipping rule"
            secondaryAction={{
                content: "Learn more",
                external: true,
                url: "https://support.digitalcoo.com/hc/en-us/sections/360009667372",
              }}
            action={{content: 'Add shipping', url: BUILD_VERSION_EXTENSION+'/add-manage-shipping', target: "APP"}}
            image="https://cdn.shopify.com/s/assets/admin/empty-states-fresh/emptystate-transfers-7caef90a7678b9dba63b068c63bc911796252c217b573d989bd4f0d9c15fee81.svg"
        >
        <p>
            Add custom shipping rates, it will override the default Shopify shipping.  
        </p>
      </EmptyState>

        var shippingNotWorkingBanner = null;
        if(this.state.settings){
            if(this.state.settings.discount_method && this.state.settings.discount_method === "coupon_code"){
                shippingNotWorkingBanner = <div style={{marginBottom: "2rem"}}><Banner status="critical">
                    Shipping doesn't work with Coupon Code discount method. <Link url={BUILD_VERSION_EXTENSION+"/settings"} target="APP">Change Discount Method</Link> Or Contact us
                </Banner></div>;
            }
        }

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
        ): (null);

        var duplicate_tag_rules_banner = this.duplicateTagsBanner();

        if(this.state.page_loaded){
            if(this.state.allShippingRules.length < 1){
                return (
                    <div>
                        <PageTitleBar
                            title="Manage Shipping"
                            breadcrumbs={[{content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                            secondaryActions={[{content: 'Back', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                        />
                        {toast}
                        {empty_state}
                    </div>
                );
            }
            else{
                return (
                    <div>
                        <PageTitleBar
                            title="Manage Shipping"
                            breadcrumbs={[{content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                            secondaryActions={[{content: 'Back', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                        />
                        {duplicate_tag_rules_banner}
                        {toast}
                        {shippingNotWorkingBanner}
                        {
                            this.needToUpgrade ? UpgradeBanner: null
                        }
                        <div style={{ margin: "1rem 0rem" }}>
                            <ButtonGroup>
                                <Button disabled={this.disableSave} primary size="slim" url={BUILD_VERSION_EXTENSION+"/add-manage-shipping"} target="APP">Add Shipping</Button>
                            </ButtonGroup>
                        </div>
                        {
                            this.state.allShippingRules.length > 0?
                                <Card>
                                    <DataTable
                                        columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        ]}
                                        headings={[
                                        'Title',
                                        'Customer Group',
                                        'Message',
                                        'Status',
                                        'Actions',
                                        ]}
                                        rows={min_orders_list_rows}
                                    />
                                </Card>
                            :null
                        }
                    </div>
                );
            }
        }
        else{
            return(<SkeletonPageComp ></SkeletonPageComp>)
        }
    }
}