import React from 'react';
import {Card,FormLayout,Layout, ResourceItem, TextField, RadioButton, ResourceList,Button,Stack,Checkbox,Select,Heading,Modal, Link,TextStyle, Banner } from '@shopify/polaris';
import { PlusMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import PageTitleBar from '../Common/TitleBar';
import CommonHelper from '../Helpers/Common';
import ResourcePicker from '../Common/ResourcePicker';
import SkeletonPageComp from './SkeletonPageComp';
import LoadingOverlay from './LoadingOverlay';
import axios from '../Axios';
import store from 'store2';
import ToastMessage from '../Common/ToastMessage';
import Common from '../../../Helpers/Common';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var owner_email = store("owner_email") || null;

export default class CreateLock extends React.Component{
  constructor(props){
      super(props);
      this.state = {
        "edit_id": this.props.match.params.id || null,
        "manage_lock":{
            "id": CommonHelper.wsId(7),
            "title": "",
            "applies_to": "selected_customers",
            "conditions":[[{"title":"tag_with","value":"","condition":"OR"}]],
            "access_permissions":"",
            "content_name":"products",
            "state": "published",
            "collections":{
                "selections":[],
                "hide_from_collections":true,
                "hide_product_listing":true,
            },
            "products":{
                "selections":[],
                "hide_product_listing": true
            },
            "content_custom_url":[],
            "redirect_to":{
              "login_url":"custom_message", 
              "custom_url_link":"",
              "message":'<div style="max-width:700px; margin:auto;text-align:center;">Only approved customers can access the website. <br> <a style="text-decoration:underline;" href="/account/login">Login here</a> or <a style="text-decoration:underline;" href="/pages/register">Request an account</a></div>',
              "message_when_loggedin":"This is a private page and only accessible to approved customers. "+(owner_email && owner_email !== "" ? "Please contact us at "+owner_email+" for more information." : "" ) // when logged in but no matching tag.
            },
            "hide_all_other_products": false
        },
        "state": "published",
        "active":false,
        "add_url":"",
        "select_field":"",
        "savingData": false,
        "collection_selection_open": false,
        "products_selection_open": false,
        "page_loaded": false,

        /* Toast specefic */
        "showToast": false,
        "toastError": false,
        "toastMsg": ""
      }
      this.save_settings = this.save_settings.bind(this);
  }

  componentDidMount(){
    if(this.props.match.params.id){
      axios.get("/app/manage-lock?shop="+this.props.shop+"&id="+this.props.match.params.id,).then(response => {
        if(response && response.data){

            // Add default value if not found - for old rules compatibility
            if(typeof response.data.manage_lock !== "undefined" &&
            typeof response.data.manage_lock.redirect_to !== "undefined" &&
            typeof response.data.manage_lock.redirect_to.message_when_loggedin === "undefined"){
                response.data.manage_lock.redirect_to.message_when_loggedin = this.state.manage_lock.redirect_to.message_when_loggedin;
            }

          this.setState({manage_lock: response.data.manage_lock, page_loaded: true});
        }
      })
      .catch(error => {
        this.setState({page_loaded: true});
      });
    }
    else{
        this.setState({page_loaded: true});
    }
  }

  hasFeatures(value){
    var hasF = false;
    if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
      hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
    }
    return hasF;
  }

  validation(){
    let error = "";

    let manage_lock = this.state.manage_lock;
    if(manage_lock.access_permissions === ""){
        error = "Step 3: Access permissions is required";
    }
    if(manage_lock.content_name === ""){
        error = "Step 4: Lock content is required";
    }

    if(!this.hasFeatures("managelock")) {
        error = "Please subscribe to this addon to use it";
    }

    return error;
    
}

/* Show/Hide toast */
showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
}

  save_settings(){

    const error_msg = this.validation();
    if(error_msg !== ""){
        this.showToastMessage(error_msg, true);
        return;
    }

      this.setState({savingData: true});
      var data = {
        shop: this.props.shop,
        manage_lock : this.state.manage_lock,
        state: this.state.state
      }
      if(this.props.match.params.id){
        axios.put("/app/manage-lock/"+this.props.match.params.id, data ).then(response => {
          this.setState({savingData: false});
          this.props.history.push(BUILD_VERSION_EXTENSION+"/manage-locks");
        })
        .catch(error => {
          this.setState({savingData: false});
        });
      }
      else{
        axios.post("/app/manage-lock", data ).then(response => {
          this.setState({savingData: false});
          this.props.history.push(BUILD_VERSION_EXTENSION+"/manage-locks");
        })
        .catch(error => {
          this.setState({savingData: false});
        });
      }
  }

  render(){
      const { title,applies_to,conditions,access_permissions,content_name,collections,products,content_custom_url,redirect_to }=this.state.manage_lock;
      const options = [
          {label: 'is tagged with', value: 'equal'},
          {label: 'is not tagged with', value: 'not_equal'}
      ];
      var popup_modal = <div>
        {
          this.state.collection_selection_open && 
          <ResourcePicker
            resourceType="Collection"
            initialSelectionIds={Common.getCollectionIds(this?.state?.manage_lock?.collections?.selections)}
            onSelection={(resources) => {
              var manage_lock = this.state.manage_lock;
              manage_lock["collections"]["selections"] = manage_lock["collections"]["selections"] || [];
              resources.selection.forEach(item => {
                if(manage_lock["collections"]["selections"].findIndex(x => x.handle === item.handle) === -1){
                    manage_lock["collections"]["selections"].push({
                      id: item.id,
                      handle: item.handle,
                      title: item.title,
                      wsid: item.wsid
                    });
                }
              });
              this.setState({manage_lock:manage_lock, collection_selection_open: false});
            }}
            onCancel={() => { this.setState({collection_selection_open: false}) }}
          />
        }
        {
          this.state.products_selection_open && 
          <ResourcePicker
            initialSelectionIds={Common.getProductsAndVariantsIds(this?.state?.manage_lock?.products?.selections)}
            onSelection={(resources) => {
              var manage_lock = this.state.manage_lock;
              manage_lock["products"]["selections"] = manage_lock["products"]["selections"] || [];
              resources.selection.forEach(item => {
                if(manage_lock["products"]["selections"].findIndex(x => x.handle === item.handle) === -1){
                    manage_lock["products"]["selections"].push({
                      id: item.id,
                      handle: item.handle,
                      title: item.title,
                      wsid: item.wsid,
                      products_count: item.productsCount
                    });
                }
              });
              this.setState({manage_lock:manage_lock, products_selection_open: false});
            }}
            onCancel={() => { this.setState({products_selection_open: false}) }}
          />
        }
        <Modal
          open={this.state.active}
          onClose={()=> {this.setState({active:false})}}
          title="Products"
          primaryAction={{
            content: 'Add',
            onAction: ()=>{},
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: ()=>{this.setState({active:false})},
            },
          ]}
        >
          <Modal.Section>
              <p>
                Use Instagram posts to share your products with millions of
                people. Let shoppers buy from your store without leaving
                Instagram.
              </p>
          </Modal.Section>
        </Modal>
      </div>;

    //   const textField = (
    //       <Autocomplete.TextField
    //       onChange={(value)=>{this.setState({select_field:value})}}
    //       label="Pages"
    //       labelHidden
    //       value={this.state.select_field}
    //       placeholder="Cart, Search"
    //       />
    //   );
      const page_content = <Layout>
      <Layout.Section>
          <Card sectioned title="Step 1: Lock Name">
              <TextField label="lock name"
              labelHidden 
              value={title} 
              placeholder="e.g Lock for wholesale customers"
              helpText="Write the title of your Lock Rule. This will not appear to the customers."
              onChange={(value)=> {
                  var manage_lock = this.state.manage_lock;
                  manage_lock.title = value;
                  this.setState({manage_lock})
              }}
              />
          </Card>
          <Card sectioned title="Step 2: Customers/Visitors lock applies to">
              <Stack vertical spacing="extraTight">
                  <RadioButton
                      label="Logged-in customers with matching tag (Recommended)"
                      checked={applies_to === 'selected_customers'}
                      id="selected_customers"
                      name="applies_to"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.applies_to = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="All logged-in customers"
                      id="loggedin_customers"
                      name="applies_to"
                      checked={applies_to === 'loggedin_customers'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.applies_to = value;
                          this.setState({manage_lock});
                      }}
                      />
                      {/* Removed: condition is only for old rules */}
                      {applies_to === "all_customers" && 
                          <RadioButton
                              label="All Visitors"
                              checked={applies_to === 'all_customers'}
                              id="all_customers"
                              name="applies_to"
                              onChange={(id,value) => {
                                  var manage_lock = this.state.manage_lock;
                                  manage_lock.applies_to = value;
                                  this.setState({manage_lock});
                              }}
                          />
                      }
                </Stack>
              <br/>
              { applies_to === "selected_customers"&& 
                  <Card sectioned>
                  <div className="all_tags_data">
                      {   conditions.map((data,index) =>
                          <div className="tags_data" key={index} length={data.length} >
                          {data.length>0 && data.map((data2,index2) =>
                              <div key={(index2+1)} length={data2.length} >
                              <Stack>
                                  <Stack.Item>
                                      <div className="tag_select">
                                          <Select
                                              label="lock conditions"
                                              labelHidden
                                              options={options}
                                              value={data2.title}
                                              onChange={(value)=>{
                                                  var manage_lock = this.state.manage_lock;
                                                  manage_lock.conditions[index][index2].title = value;
                                                  this.setState({manage_lock})
                                              }}
                                          />
                                      </div>
                                  </Stack.Item>
                                  <Stack.Item fill>
                                      <div className="tag_info">
                                          <TextField label="lock name"
                                          labelHidden 
                                          value={data2.value} 
                                          onChange={(value)=> {
                                              var manage_lock = this.state.manage_lock;
                                              try{value= value.toLowerCase().trim();}catch(e){};
                                              manage_lock.conditions[index][index2].value = value;
                                              this.setState({manage_lock})
                                          }}
                                          />
                                      </div>
                                  </Stack.Item>
                                  <Stack.Item>
                                  <div className="crs_btn">
                                      <Button size="slim" icon={CancelSmallMinor} plain onClick={()=>{
                                          var manage_lock = this.state.manage_lock;
                                          if(manage_lock.conditions[index].length <= 1)
                                          {
                                              manage_lock.conditions.splice(index,1);
                                          }else
                                          {
                                              manage_lock.conditions[index].splice(index2,1);
                                          }
                                          this.setState({manage_lock});
                                      }} ></Button>
                                  </div>
                              </Stack.Item>
                              </Stack>
                              </div>
                          )
                          }
                              <div style={{textAlign:"right"}}>
                              <Button size="slim" plain icon={PlusMinor} onClick={()=>{
                                  var new_obj = {"title":"tag_with","value":"","condition":"OR"};
                                  var manage_lock = this.state.manage_lock;
                                  manage_lock.conditions[index] = [...manage_lock.conditions[index],new_obj];
                                  this.setState({manage_lock});
                              }} ></Button></div>
                          </div>
                          )
                      }
                  </div>
                  <div className="add_btn">
                      <Button primary onClick={()=>{
                          var new_obj = [{"title":"tag_with","value":"","condition":"AND"}];
                          if(conditions.length===0){
                              new_obj = [{"title":"tag_with","value":"","condition":"OR"}];
                          }
                          var manage_lock = this.state.manage_lock;
                          manage_lock.conditions = [...manage_lock.conditions,new_obj];
                          this.setState({manage_lock});
                      }}>AND</Button>
                  </div>
              </Card>
              }
          </Card>

          <Card sectioned title="Step 3: Access permissions">
              <Stack vertical spacing="extraTight">
                  <RadioButton
                      label="May Access"
                      checked={access_permissions === 'access'}
                      id="access"
                      name="access_permissions"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.access_permissions = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Cannot Access"
                      id="deny"
                      name="access_permissions"
                      checked={access_permissions === 'deny'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.access_permissions = value;
                          this.setState({manage_lock});
                      }}
                  />
              </Stack>
          </Card>
          <Card sectioned title="Step 4: Lock content">
              <Stack spacing="tight" >
                <Stack spacing="extraTight">
                  <RadioButton
                      label="Products"
                      checked={content_name === 'products'}
                      id="products"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                    <div style={{marginTop: "4px", fontSize: "70%"}}>
                        <TextStyle variation="subdued">(Recommended)</TextStyle>
                    </div>
                </Stack>
                  <RadioButton
                      label="Collections"
                      checked={content_name === 'collections'}
                      id="collections"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Whole Store"
                      checked={content_name === 'entire_store'}
                      id="entire_store"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="URL"
                      id="url"
                      name="content_name"
                      checked={content_name === 'url'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
              </Stack>
              <br/>
              {content_name === "products" &&
                  <div className="products">
                      <Card>
                          <Card.Section>
                          <Stack distribution="equalSpacing">
                              <Button icon={PlusMinor} primary onClick={()=>{this.setState({products_selection_open:true})}}>Select Products</Button>
                              {
                                  products.selections.length && 
                                  <Button destructive
                                    onClick={() => {
                                        if(window.confirm("Are you sure?")){
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.products.selections = [];
                                            this.setState({manage_lock:manage_lock})
                                        }
                                    }}
                                  >Remove All</Button>
                              }
                          </Stack>
                          <br/>
                          {/* {products.products.arr.length>0 && */}
                              <div className="product_data"> 
                              <Heading>{products.selections.length} products selected</Heading>
                              <ResourceList
                                  items={products.selections}
                                  renderItem={(item) => {
                                  const {id, title, handle } = item;
                                  return (
                                      <ResourceItem
                                      id={id}
                                      >
                                      <Stack distribution="equalSpacing">
                                      <Link url={Common.getShopBaseUrl()+'/products/'+handle} external={true}>{title}</Link>
                                          <Button outline destructive
                                            onClick={() => {
                                                var manage_lock = this.state.manage_lock;
                                                manage_lock.products.selections = manage_lock.products.selections.filter(x => x.id!==item.id);
                                                this.setState({manage_lock:manage_lock});
                                            }}
                                          >Remove</Button>
                                      </Stack>
                                      </ResourceItem>
                                  );
                                  }}
                              />
                              </div>
                          {/* } */}
                          </Card.Section>
                          {access_permissions !== 'access'&&
                          <Card.Section>
                          <FormLayout>
                                  <Checkbox
                                      label="Hide locked products from Product Listing sections (Collection pages, related products and search page etc.)"
                                      checked={products.hide_product_listing}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.products.hide_product_listing = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                              </FormLayout>
                          </Card.Section>
                          }
                          {
                            access_permissions === 'access'&&
                            <Card.Section>
                                <FormLayout>
                                    <Checkbox
                                        label="Hide all other products"
                                        checked={this.state.manage_lock.hide_all_other_products}
                                        onChange={(value)=>{
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.hide_all_other_products = value;
                                            this.setState({manage_lock});
                                        }}
                                    />
                                </FormLayout>
                            </Card.Section>
                          }
                      </Card>
                  </div>
              }
              {content_name === "collections" &&
                  <div className="collections">
                    <Stack vertical>
                        <div style={{fontStyle: 'italic', fontSize: '70%'}}><TextStyle variation="subdued">Note: Manage Lock rule for collections may not work on some themes due to coding limitations. We recommend creating a 'Manage Lock rule' using the 'Products' option.</TextStyle></div>
                      <Card>
                          <Card.Section>
                          <Stack distribution="equalSpacing">
                              <Button
                                icon={PlusMinor}
                                primary onClick={()=>{this.setState({collection_selection_open:true})}}
                              >Select Collection</Button>
                              {
                                  collections.selections.length && 
                                      <Button destructive 
                                      onClick={() => {
                                        if(window.confirm("Are you sure?")){
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.collections.selections = [];
                                            this.setState({manage_lock:manage_lock})
                                        }
                                    }}
                                    >
                                    Remove All
                                    </Button>
                              }
                              
                          </Stack>
                          <br/>
                          {/* {collections.selections.length>0 && */}
                              <div className="coll_data"> 
                              <Heading>Total Collections Selected - {collections.selections.length}</Heading>
                              <ResourceList
                                  items={collections.selections}
                                  renderItem={(item) => {
                                  const {id, title,handle } = item;
                                  return (
                                      <ResourceItem
                                      id={id}
                                      >
                                      <Stack distribution="equalSpacing">
                                          <Link url={Common.getShopBaseUrl()+'/collections/'+handle} external={true}>{title}</Link>
                                          <Button outline destructive
                                            onClick={() => {
                                                var manage_lock = this.state.manage_lock;
                                                manage_lock.collections.selections = manage_lock.collections.selections.filter(x => x.id!==item.id);
                                                this.setState({manage_lock:manage_lock});
                                            }}
                                          >Remove</Button>
                                      </Stack>
                                      </ResourceItem>
                                  );
                                  }}
                              />
                              </div>
                          {/* } */}
                          </Card.Section>
                          {access_permissions !== 'access'&&
                          <Card.Section>
                              <FormLayout>
                                  <Checkbox
                                      label={"Hide locked collections from Collection Listing page [URL: https://"+this.props.shop+"/collections]"}
                                      checked={collections.hide_from_collections}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.collections.hide_from_collections = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                                  <Checkbox
                                      label="Hide locked products from Product Listing sections (Collection pages, related products and search page etc.)"
                                      checked={collections.hide_product_listing}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.collections.hide_product_listing = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                              </FormLayout>
                          </Card.Section>
                          }
                          {
                            access_permissions === 'access'&&
                            <Card.Section>
                                <FormLayout>
                                    <Checkbox
                                        label="Hide all other products"
                                        checked={this.state.manage_lock.hide_all_other_products}
                                        onChange={(value)=>{
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.hide_all_other_products = value;
                                            this.setState({manage_lock});
                                        }}
                                    />
                                </FormLayout>
                            </Card.Section>
                          }
                      </Card>
                    </Stack>
                        
                  </div>
              }
              {content_name === "url" &&
                  <>
                  <TextField label="lock name"
                      labelHidden 
                      prefix={"https://"+this.props.shop+'/'}
                      value={this.state.add_url} 
                      onChange={(value)=> {
                          this.setState({add_url:value});
                      }}
                      connectedRight={<Button primary onClick={()=>{
                          var manage_lock = this.state.manage_lock;
                          if(content_custom_url.findIndex(x=>x.url === this.state.add_url)>-1)
                          {alert("URL Already Exist")}else
                          {
                              manage_lock.content_custom_url.push({"url":this.state.add_url});
                              this.setState({manage_lock});
                          }
                          this.setState({add_url:''})
                      }}>ADD</Button>} 
                  />
                  <br/>
                  <div className="coll_data"> 
                      <Heading>Total Selected Pages - {content_custom_url.length}</Heading>
                      <ResourceList
                          items={content_custom_url}
                          renderItem={(item,id,index) => {
                          const {url} = item;
                          return (
                              <ResourceItem
                              id={index}
                              >
                              <Stack distribution="equalSpacing">
                              <Link url={"https://"+this.props.shop+'/'+url} external={true}>https://{this.props.shop}/{url}</Link>
                                  <Button size="slim" outline destructive onClick={()=>{
                                      var manage_lock = this.state.manage_lock;
                                      manage_lock.content_custom_url.splice(index,1);
                                      this.setState({manage_lock})
                                  }}>Remove</Button>
                              </Stack>
                              </ResourceItem>
                          );
                          }}
                      />
                  </div>
                  </>
              }
          </Card>
          <Card sectioned title="Step 5: Redirect rules and locked messages">
                <Stack vertical spacing="extraTight">
                    <RadioButton
                        label="Show Custom Message"
                        id="custom_message"
                        name="redirect_to"
                        checked={redirect_to.login_url === 'custom_message'}
                        onChange={(id,value) => {
                            var manage_lock = this.state.manage_lock;
                            manage_lock.redirect_to.custom_url_link = '';
                            manage_lock.redirect_to.login_url = value;
                            this.setState({manage_lock});
                        }}
                    />
                    { redirect_to.login_url === 'custom_message' &&
                        <Stack vertical>
                            <TextField
                                multiline={4}
                                label="Message"
                                value={redirect_to.message}
                                name="redirect_to_Message"
                                id="redirect_to_Message"
                                onChange={(value) => {
                                    var manage_lock = this.state.manage_lock;
                                    manage_lock.redirect_to.message = value;
                                    this.setState({manage_lock});
                                }}
                            />
                            {applies_to === "selected_customers" && access_permissions === 'access' &&
                                <TextField
                                    multiline={4}
                                    label="Show message when customer is logged in but with no matching tag"
                                    value={redirect_to.message_when_loggedin}
                                    onChange={(value) => {
                                        var manage_lock = this.state.manage_lock;
                                        manage_lock.redirect_to.message_when_loggedin = value;
                                        this.setState({manage_lock});
                                    }}
                                />
                            }
                            
                        </Stack>
                    }

                    <RadioButton
                        label="Login Page"
                        checked={redirect_to.login_url === 'login_page'}
                        name="redirect_to"
                        id="login_page"
                        onChange={(id,value) => {
                            var manage_lock = this.state.manage_lock;
                            manage_lock.redirect_to.login_url = value;
                            manage_lock.redirect_to.custom_url_link = '/account/login';
                            this.setState({manage_lock});
                        }}
                    />
                    { ( redirect_to.login_url === 'login_page' && applies_to === "selected_customers" ) &&
                        
                        <TextField
                            multiline={4}
                            label="Show message when customer is logged in but with no matching tag"
                            value={redirect_to.message_when_loggedin}
                            onChange={(value) => {
                                var manage_lock = this.state.manage_lock;
                                manage_lock.redirect_to.message_when_loggedin = value;
                                this.setState({manage_lock});
                            }}
                        />
                            
                    }
                    <RadioButton
                        label="Custom URL"
                        id="specific_url"
                        name="redirect_to"
                        checked={redirect_to.login_url === 'specific_url'}
                        onChange={(id,value) => {
                            var manage_lock = this.state.manage_lock;
                            manage_lock.redirect_to.custom_url_link = '';
                            manage_lock.redirect_to.login_url = value;
                            this.setState({manage_lock});
                        }}
                    />
                    { redirect_to.login_url === "specific_url" &&
                        <Stack vertical>
                            <TextField 
                                label="URL" 
                                value={redirect_to.custom_url_link}
                                onChange={(value)=> {
                                    var manage_lock = this.state.manage_lock;
                                    manage_lock.redirect_to.custom_url_link = value;
                                    this.setState({manage_lock})
                                }} 
                            />
                            {applies_to === "selected_customers" && access_permissions === 'access' &&
                                
                                <TextField
                                    multiline={4}
                                    label="Show message when customer is logged in but with no matching tag"
                                    value={redirect_to.message_when_loggedin}
                                    onChange={(value) => {
                                        var manage_lock = this.state.manage_lock;
                                        manage_lock.redirect_to.message_when_loggedin = value;
                                        this.setState({manage_lock});
                                    }}
                                />
                            }
                        </Stack>
                    }
                  
                </Stack>
            </Card>
        </Layout.Section>
      <Layout.Section>
          <Stack>
              <Stack.Item fill></Stack.Item>
              <Stack.Item><Button url={BUILD_VERSION_EXTENSION+"/manage-locks"} target="APP">Cancel</Button></Stack.Item>
              <Stack.Item><Button primary loading={this.state.savingData} onClick={this.save_settings} disabled={!this.hasFeatures('managelock')}>Save</Button></Stack.Item>
          </Stack>
      </Layout.Section>
      </Layout>;
      
      /* Will show toast based on state */
      var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        
      if(this.state.page_loaded){
        return(
            <div>
              <PageTitleBar
                title={this.props.show === "add"?"Create Lock":"Edit Lock"}
                breadcrumbs={[{content: 'Manage Locks', url: BUILD_VERSION_EXTENSION+'/manage-locks'}]}
                primaryAction={{
                  disabled: this.state.savingData || !this.hasFeatures('managelock'),
                  content: "Save",
                  onAction: this.save_settings
                }}
                secondaryActions={[
                  {
                    target: "APP",
                    content: "Cancel",
                    url: BUILD_VERSION_EXTENSION+"/manage-locks"
                  }
                ]}
              />
              {
                !this.hasFeatures("managelock") &&
                <div style={{marginBottom: '1rem'}}>
                <Banner
                    title="Activation Required"
                    status="critical"
                >
                    <p>To use the Manage Lock addon, please activate it in the Billing section first. <Link external url='https://support.digitalcoo.com/hc/en-us/articles/360054881511'>[View details]</Link> | <Link url={BUILD_VERSION_EXTENSION+"/update-plans"}>[Go to Billing section]</Link></p>
                </Banner>
                </div>
            }
            {
                this.props.productPermissionError &&
                <div style={{marginBottom: '1rem'}}>
                <Banner
                    title="Limited Access"
                    status="critical"
                >
                    <p>You cannot add products or collections because your account has limited access. <Link external url="https://support.digitalcoo.com/hc/en-us/articles/360044346372">Learn More</Link></p>
                </Banner>
                </div>
            }
              {page_content}
              {popup_modal}
              {toast}
              {
                  this.state.savingData && 
                  <LoadingOverlay
                    message="Saving Lock rule. Please wait..."
                  />
              }
            </div>
        );
      }
      else{
        return(
            <SkeletonPageComp></SkeletonPageComp>
        );
      }
  }
}