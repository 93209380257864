import React, { Component } from 'react';
import { TextStyle, Icon } from '@shopify/polaris';
import store from 'store2';
import { Redirect } from '@shopify/app-bridge/actions';
import { Context } from '@shopify/app-bridge-react';
import { ChevronRightMinor } from '@shopify/polaris-icons';

const BUILD_VERSION_EXTENSION = process.env.REACT_APP_BUILD_VERSION_EXTENSION;

class DiscountTypeModalForRD extends Component {
    static contextType = Context;

    handleNavigation = (url) => {
        const app = this.context;
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.APP, url); // Shopify App Bridge navigation
    };

    render() {
        const items = [
            {
                id: 1,
                name: 'Create a standard discount rule',
                description: "Apply a fixed discount to a selected group of products",
                type: 'common',
            },
            {
                id: 2,
                name: 'Create price list for individual products and variants',
                description: "Apply separate discounts or custom pricing to each selected product & variant",
                type: 'individual',
            }
        ];

        return (
            <div
                class="discount-type-modal"
                style={{
                    background: 'white',
                    borderRadius: '10px',
                }}
            >
                {items.map((item, index) => (
                    <>
                    <div
                        class="discount-type-modal-links"
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'block',
                            cursor: 'pointer',
                            textAlign: 'left',
                            padding: '18px',
                            borderTopLeftRadius: index === 0 ? '10px' : '0px',
                            borderTopRightRadius: index === 0 ? '10px' : '0px',
                            borderBottomLeftRadius: index === items.length - 1 ? '10px' : '0px',
                            borderBottomRightRadius: index === items.length - 1 ? '10px' : '0px',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            store('discount_type', item.type);
                            this.handleNavigation(BUILD_VERSION_EXTENSION + '/regular-discount/create');
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <TextStyle variation="strong">{item.name}</TextStyle><br />
                                <TextStyle variation="subdued">{item.description}</TextStyle>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon source={ChevronRightMinor} color="inkLighter" />
                            </div>
                        </div>
                    </div>
                    {
                        index === items.length - 1 ? null : 
                        <div
                            style={{
                                borderBottom: '1px solid #dfe3e8',
                                margin: '0'
                            }}
                        ></div>
                    }
                    </>
                ))}
            </div>
        );

    }
}

export default DiscountTypeModalForRD;