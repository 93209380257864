import React, { Component } from 'react';
import axios from '../Axios';
import Install from './Install';
import {  Banner, Spinner, TextStyle } from '@shopify/polaris';
import CommonHelper from '../../../Helpers/Common';
import store from 'store2';
import { Redirect } from "@shopify/app-bridge/actions";
import {Context} from '@shopify/app-bridge-react'


var shop = CommonHelper.getShop();
var host = CommonHelper.getHost();
var host_name = CommonHelper.getHostName();

const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class Home extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      host_name: shop,
      shop: host_name,
      server_error: false,
      method: 'install',

    };
  }
  componentDidMount() {
    if (shop) {
      let oauthLastcall = store("oauth_lastcall") || null;

            // We need to forceOauth when
      //    1) already stored shop is not matched with provided shop
      //    2) snippet_version is not matched with 2.3.8
      let ls_shop = store("shop") || null;
      let ls_snippet_version = store("snippet_version") || null;
      let forceOauth = false;
      
      if(ls_snippet_version && ls_snippet_version !== "2.3.8") forceOauth = true;
      else if(ls_shop && ls_shop !== shop) {
        forceOauth = true;
        // store current time and call oauth
        store("oauth_lastcall", Date.now());
      }
      else if(!ls_shop){
        // no shop stored, required auth
        forceOauth = true;
        store.clearAll();
      }



      // If not in iframe, no need to store anything
      if(this.inIframe()) store("shop", shop);
      else{
        forceOauth = true;
      }

      
      const urlParams = new URLSearchParams(window.location.search);
      const plan_version_updated = urlParams.get('plan_version_updated');
      // eslint-disable-next-line
      if(plan_version_updated && plan_version_updated==1){
        // Need to display success message as customer update the plan after app version was updated
        // store this in localstorage, so when after redirect flow is completed, we can show a banner
        // variable will be deleted as soon as banner is displayed so it would be one-timer
        store('plan_version_updated', 1);
      }

      const id_token = urlParams.get('id_token');
      const session = urlParams.get('session');
      const _host = urlParams.get('host');

      let allowOauth = false;

      if(!forceOauth){
      try{
        if(!oauthLastcall || oauthLastcall === ''){
          // No last call found, store current time and call oauth
          store("oauth_lastcall", Date.now());
          allowOauth = true;
        }
        else{
          // Compare last call with now and check if 5 min has passed
          // since stored time is in millisecconds, need to convert it to minutes after calculating difference
          oauthLastcall = parseInt(oauthLastcall) || null;
          if(oauthLastcall){

            const diff = Math.abs(Date.now() - Number(oauthLastcall)) / 36000;
            if(diff > 5) {
              allowOauth = true;

              // reset oauth to now
              oauthLastcall = Date.now();
            }

          }

          //restore oauth beacuase we cleared all data
          store("oauth_lastcall", Number(oauthLastcall));
        }
      } catch(e){ allowOauth = true; }
    }
    else{
      allowOauth = true;
    }

    // Match current shop with

    if(id_token && session && _host){
      // Means the app is already installed so we need to redirect to dashboard
      // redirecing to dashboard by Shopify App Bridge
      this.setState({method: 'login'});
      const app = this.context;
      const redirect = Redirect.create(app);
      redirect.dispatch(
        Redirect.Action.APP,
        BUILD_VERSION_EXTENSION+'/dashboard'
      );
      this.sendLogs("Logging to dashboard after successful login");
      return;
    }

    if(allowOauth){
      this.sendLogs("Redirecting to oAuth");
      // Redirect to oAuth

      const app = this.context;
      const redirect = Redirect.create(app);
        const isEmbedded = new URLSearchParams(window.location.search).get('embedded') || 0;
        const redirect_uri = host_name + '/shopapp/2.0?shop=' + shop + '&host=' + host + '&embedded=' + isEmbedded;

        

        if(this.inIframe()){
      // after 5 sec, if redirect still not happen, try to use native redirect instead of app bridge
      setTimeout(() => {
        window.top.location.href = redirect_uri;
      }, 5000);
      redirect.dispatch(
        Redirect.Action.REMOTE,
        redirect_uri
      );
    }
    else{
          window.location.href = redirect_uri;
        }
      }
      else{
      // redirect to dashboard
        
        this.props.linkOverride.push(BUILD_VERSION_EXTENSION+'/dashboard');
    }
  }
}

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  sendLogs(data){
    axios.post(host_name + '/logs?shop=' + shop, {data: data})
        .then(response => {
          // if (response.data.redirect_uri && response.data.shop === shop) {
          //   window.top.location.href = response.data.redirect_uri;
          // }
        })
        .catch(error => {
          // this.setState({server_error:true, serverErrorMessage: error});
        });
  }
  checkIfNavigating = () => {
    let ___shop = store("shop") || null;
    if(___shop && ___shop === shop) {
      return true;
    }
    return false;
  }
  render() {
    if(this.state.server_error){
      return (
        <div>
          <Banner
            status="critical"
            title="Wholesale - All in one server is down for maintenance"
            primaryAction={{
              content: "Refresh Page",
              onAction: () => {
                window.location.replace("?cache=clear&wspage=refresh");
              }
            }}
          >
            <p>We expect to be back in a couple hours.</p>
          </Banner>
          <pre>
            {this.state.serverErrorMessage.toString()}
          </pre>
        </div>
      );
    }
    else if (!shop) {
      return (
        <div>
          <Install shop={shop} host_name={host_name} shop_id={null} ></Install>
        </div>
      )
    }
    else {
      if(this.state.method === 'install' || this.checkIfNavigating()) {
        return (
          <div>
            <div className="spinner-container" style={{textAlign:"center", height:"50vh", padding: "2rem 0rem"}}>
              <span style={{marginBottom: "2rem"}}><Spinner color="teal" size="large" /></span>
            </div>
          </div>
        );
      }
      else {
        return (
          <div>
            <div className="spinner-container" style={{textAlign:"center", height:"50vh", padding: "2rem 0rem"}}>
              <span style={{marginBottom: "2rem"}}><Spinner color="teal" size="large" /></span>
              <h1>Logging you into <TextStyle variation="strong">Wholesale All In One</TextStyle>. Please wait...</h1>
            </div>
          </div>
        );
      }
    }
  }
}