import React, { Component } from 'react';
import { Card,DataTable,Layout,Checkbox,TextField, FormLayout, Button, Stack, RadioButton, Tag, ButtonGroup, EmptyState, Badge, Link, Banner } from '@shopify/polaris';
import store from 'store2';
import PageTitleBar from '../Common/TitleBar';
import ResourcePicker from '../Common/ResourcePicker';
import ToastMessage from '../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';
import axios from '../Axios';
import Common from '../../../Helpers/Common';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class QuantityControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            page_loaded: this.props.show === "add"?true:false,
            status: "enabled",
            default_quantity: 1,
            quantity_increment: 1,
            minimum_quantity: "",
            maximum_quantity: "",
            customer_group: "all",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            save_button_disabled: false,
            save_button_loading: false,
            applies_to: "entire_store",
            collection_modal_open: false,
            product_modal_open: false,
            variants: [],
            products: [],
            collections: [],
            update_products__modal_open: false,
            variant_modal_open: false,
            selected_products_variant_picker_open: false,
            product_modal_for_variant_open: false,
            update_variants__modal_open: false,
            allQCRules: [],
            show: this.props.show || "list",
            internal_name: "",
            message: "",
            minimum_quantity_method: "product_level",
            selector_for_individual_variant: false,
            showToast: false,
            toastMsg: '',
            toastError: false
        };
        store.remove('products');
        store.remove('variants');
        store.remove('selected_products');
        store.remove('collections');
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }

    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    componentDidMount(){
        if(this.state.show === "list"){
            this.getAllQC();
        }
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }
    }

    hasFeatures(value){
        var hasF = false;
        if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
          hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
        }
        return hasF;
      }

    validation(){
        var valid = true;
        var {excepted_tags,except_tags,customer_group,customer_tags,applies_to,products,collections,variants,maximum_quantity,minimum_quantity,default_quantity,quantity_increment} = this.state;
        if(except_tags && excepted_tags.length < 1) valid = false;
        if(customer_group === "tag_based" && customer_tags.length < 1) valid = false;
        if(applies_to === "products" && products.length < 1){valid=false;}
        if(applies_to === "collections" && collections.length < 1){valid=false;}
        if(applies_to === "variants" && variants.length < 1){valid=false;}
        if(maximum_quantity !== "" && Number(minimum_quantity) > Number(maximum_quantity)){valid=false;}
        if(minimum_quantity !== "" && parseInt(default_quantity) > parseInt(minimum_quantity)){valid=false;}
        if(minimum_quantity !== "" && parseInt(minimum_quantity) % parseInt(quantity_increment) !== 0){valid=false;}
        if(!this.hasFeatures("quantity_controller")) valid = false;
        return valid;
    }

    getAllQC(){
        axios.get('/app/qty-controllers/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allQCRules:r.data,page_loaded:true});
            }
        })
        .catch(error => {});
    }

    getARuleForEdit(){
        axios.get('/app/get-qty-controller/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    status,default_quantity,minimum_quantity,maximum_quantity,quantity_increment,applies_to,variants,products,collections,customer_tags,excepted_tags,except_tags,except_logged_in,customer_group,message,internal_name
                } = r.data;
                var minimum_quantity_method = r.data.minimum_quantity_method || this.state.minimum_quantity_method;
                var selector_for_individual_variant = r.data.selector_for_individual_variant || this.state.selector_for_individual_variant;
                this.setState({
                    status,default_quantity,minimum_quantity,maximum_quantity,quantity_increment,applies_to,variants,products,collections,customer_tags,excepted_tags,except_tags,except_logged_in,customer_group,message,internal_name, page_loaded: true, minimum_quantity_method, selector_for_individual_variant
                });
            }
        })
        .catch(error => { });
    }

    saveData(){
        if(!this.hasFeatures("quantity_controller")) {
            this.showToastMessage("Please subscribe to this addon to use it", true);
            return false;
        }
        if(this.validation()){
            this.setState({
                save_button_disabled: true,
                save_button_loading: true
            });
            var data = {
                shop: this.props.shop,
                status: this.state.status,
                customer_group: this.state.customer_group,
                customer_tags: this.state.customer_tags,
                except_logged_in: this.state.except_logged_in,
                except_tags: this.state.except_tags,
                excepted_tags: this.state.excepted_tags,
                default_quantity: this.state.default_quantity,
                quantity_increment: this.state.quantity_increment,
                minimum_quantity: this.state.minimum_quantity,
                maximum_quantity: this.state.maximum_quantity,
                variants: this.state.variants,
                products: this.state.products,
                collections: this.state.collections,
                applies_to: this.state.applies_to,
                message: this.state.message,
                internal_name: this.state.internal_name,
                minimum_quantity_method: this.state.minimum_quantity_method,
                selector_for_individual_variant: this.state.selector_for_individual_variant
            };
            if(this.state.show === "edit"){
                axios.put('/app/quantity-controller?id='+this.state.edit_rule_id, data)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.props.history.push(BUILD_VERSION_EXTENSION+'/add-quantity-controller');
                            this.getAllQC();
                            this.setState({show:"list"});
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                    this.setState({
                        save_button_disabled: false,
                        save_button_loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        save_button_disabled: false,
                        save_button_loading: false
                    });
                });
            }
            else{
                axios.post('/app/quantity-controller', data)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.props.history.push(BUILD_VERSION_EXTENSION+'/add-quantity-controller');
                            this.getAllQC();
                            this.setState({show:"list"});
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                    this.setState({
                        save_button_disabled: false,
                        save_button_loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        save_button_disabled: false,
                        save_button_loading: false
                    });
                });
            }
        }
        else{
            this.showToastMessage("Validation error!", true);
        }
    }

    renderCustomerTags(){
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    handleSubmitUpdateProducts = (selected) => {
        let products = store('products') || [];
        this.setState({
            products,
            update_products__modal_open: false
        })
    }

    handleSubmitUpdateCollections = (selected) => {
        let collections = store('collections') || [];
        this.setState({
            collections,
            update_collections__modal_open: false
        })
    }

    handleSubmitSelectedVariants = (selected) => {
        let variants = store('variants') || [];
        if(variants.length < 1){
            this.setState({
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
        else{
            this.setState({
                variants: variants,
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
    };

    handleSubmitUpdateVariants = (selected) => {
        let variants = store('variants') || [];
        this.setState({
            variants: variants,
            update_variants__modal_open: false,
        });
    }

    disableSaveButton(){
        return !this.validation();
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.internal_name}?`)){
            if(item.metafield_ids){
                axios.delete('/app/quantity-controller?id='+item._id+'&metafield_ids='+item.metafield_ids+'&shop='+ this.props.shop+'&keys='+item.meta_keys)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.getAllQC();
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                })
                .catch(error => { });
            }
            else{
                axios.delete('/app/quantity-controller?id='+item._id+'&shop='+ this.props.shop+'&keys='+item.meta_keys)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.getAllQC();
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                })
                .catch(error => { });
            }
        }
    }

    disableRule(item){
        if(window.confirm(`Unpublish ${item.internal_name}?`)){
            var data = {
                shop: this.props.shop,
                status: "disabled",
                customer_group: item.customer_group,
                customer_tags: item.customer_tags,
                except_logged_in: item.except_logged_in,
                except_tags: item.except_tags,
                excepted_tags: item.excepted_tags,
                default_quantity: item.default_quantity,
                quantity_increment: item.quantity_increment,
                minimum_quantity: item.minimum_quantity,
                maximum_quantity: item.maximum_quantity,
                variants: item.variants,
                products: item.products,
                collections: item.collections,
                applies_to: item.applies_to,
                message: item.message,
                internal_name: item.internal_name,
                minimum_quantity_method: item.minimum_quantity_method || this.state.minimum_quantity_method,
                selector_for_individual_variant: item.selector_for_individual_variant || this.state.selector_for_individual_variant
            };
            axios.put('/app/quantity-controller?id='+item._id, data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllQC();
                        this.setState({show:"list"});
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
            })
            .catch(error => {
            });
        }
    }

    enableRule(item){
        var data = {
            shop: this.props.shop,
            status: "enabled",
            customer_group: item.customer_group,
            customer_tags: item.customer_tags,
            except_logged_in: item.except_logged_in,
            except_tags: item.except_tags,
            excepted_tags: item.excepted_tags,
            default_quantity: item.default_quantity,
            quantity_increment: item.quantity_increment,
            minimum_quantity: item.minimum_quantity,
            maximum_quantity: item.maximum_quantity,
            variants: item.variants,
            products: item.products,
            collections: item.collections,
            applies_to: item.applies_to,
            message: item.message,
            internal_name: item.internal_name,
            minimum_quantity_method: item.minimum_quantity_method || this.state.minimum_quantity_method,
            selector_for_individual_variant: item.selector_for_individual_variant || this.state.selector_for_individual_variant
        };
        axios.put('/app/quantity-controller?id='+item._id, data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllQC();
                    this.setState({show:"list"});
                }
                else{
                    this.showToastMessage(r.data.message, true);
                }
            }
        })
        .catch(error => {
        });
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    getProductsAndVariantsIds(variants){
        let _products = [];
        variants.forEach((variant, index) => {
            if(variant && variant?.product_id) {
                if(_products.indexOf(variant?.product_id) === -1){
                    _products.push({
                        id: variant.product_id && variant.product_id !== null && typeof variant.product_id !== "undefined" ? "gid://shopify/Product/"+variant.product_id : null,
                        variants: [{
                            id: variant.id && variant.id !== null && typeof variant.id !== "undefined" ? "gid://shopify/ProductVariant/"+variant.id : null
                        }]
                    });
                }
                else {
                    _products[_products.indexOf(variant.product_id)].variants.push({
                        id: variant.id && variant.id !== null && typeof variant.id !== "undefined" ? "gid://shopify/ProductVariant/"+variant.id : null
                    });
                }
            }
        })
        return _products;
    }

    render() {

        const {
            status, default_quantity, minimum_quantity, maximum_quantity, quantity_increment, applies_to, internal_name, message, minimum_quantity_method, selector_for_individual_variant
        } = this.state;

        const {
            temp_customer_tags_value,temp_tag_value,excepted_tags,except_tags,except_logged_in,customer_group
        } = this.state;

        const resource_pikers = <span>
            {
                this.state.product_modal_for_variant_open && 
                <ResourcePicker
                    resourceType="Product"
                    showVariants={true}
                    open={this.state.product_modal_for_variant_open}
                    initialSelectionIds={this.getProductsAndVariantsIds(this.state.variants)}
                    onSelection={(resources) => {
                        if (resources && resources.selection) {
                            var variants = this.state.variants;
                            resources.selection.forEach((product, index) => {
                                if (product && product.variants) {
                                    product.variants.forEach((variant, vindex) => {
                                        var fvindex = -1;
                                        try {
                                            fvindex = variants.findIndex(x => x.id === variant.id);
                                        } catch (e) { }
                                        if(fvindex === -1){
                                            variants.push({
                                                id: variant.id,
                                                title: variant.title,
                                                product_id: product.id,
                                                product: [{
                                                    "id": product.id,
                                                    "title": product.title,
                                                    "handle": product.handle,
                                                    "image": product.image
                                                }]
                                            });
                                        }
                                    });
                                }
                                this.setState({
                                    variants: variants,
                                    product_modal_for_variant_open: false
                                });
                            });
                        }
                    }}
                    onCancel={() => this.setState({product_modal_for_variant_open: false})}
                />
            }
            {
                this.state.product_modal_open && 
                <ResourcePicker
                    resourceType="Product"
                    initialSelectionIds={Common.getProductsAndVariantsIds(this.state.products)}
                    onSelection={(resources) => {
                            var products = this.state.products;
                            for(var product of resources.selection){
                                // eslint-disable-next-line
                                if(products.findIndex(x=> x.id === product.id) === -1){
                                    try{
                                        product.variants = JSON.parse(JSON.stringify(product.variants)).map(x => {
                                            return {
                                                compare_at_price: x.compare_at_price,
                                                grams: x.grams,
                                                id: x.id,
                                                price: x.price,
                                                product_id: x.product_id,
                                                sku: x.sku,
                                                title: x.title,
                                                weight: x.weight,
                                                weight_unit: x.weight_unit
                                            }
                                        });
                                    }catch(e){}
                                    products.push({
                                        id: product.id,
                                        title: product.title,
                                        handle: product.handle,
                                        image: product.image,
                                        tags: product.tags,
                                        variants: product.variants
                                    });
                                }
                            }
                            this.setState({products,product_modal_open: false});
                        }
                    }
                    onCancel={() => this.setState({product_modal_open: false})}
                />
            }
            {
                this.state.collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    initialSelectionIds={Common.getCollectionIds(this.state.collections)}
                    onSelection={(resources) => {
                        var collections = this.state.collections;
                        for(var collection of resources.selection){
                            // eslint-disable-next-line
                            if(collections.findIndex(x=> x.id === collection.id) === -1){
                                collections.push({
                                    id: collection.id,
                                    title: collection.title,
                                    handle: collection.handle,
                                    image: collection.image
                                });
                            }
                        }
                        this.setState({collections,collection_modal_open: false});
                    }}
                    onCancel={() => this.setState({collection_modal_open: false})}
                />
            }
            {
                this.state.update_products__modal_open && 
                <Modal
                    size="Medium"
                    src={BUILD_VERSION_EXTENSION+'/update-products-modal'}
                    open={this.state.update_products__modal_open}
                    onClose={() => this.setState({update_products__modal_open: false})}
                    title={"Products"}
                    primaryAction={{
                        content: 'Update',
                        onAction: this.handleSubmitUpdateProducts,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {this.setState({update_products__modal_open: false})},
                        }
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.selected_products_variant_picker_open && 
                <Modal
                    size="Medium"
                    src={BUILD_VERSION_EXTENSION+'/selected-products-variant-picker'}
                    open={this.state.selected_products_variant_picker_open}
                    onClose={() => this.setState({
                        selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                    })}
                    title={'Select variants'}
                    primaryAction={{
                        content: 'Select variants',
                        onAction: this.handleSubmitSelectedVariants,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => { this.setState({
                                selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                            })},
                        },
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.update_variants__modal_open && 
                <Modal
                    size="Medium"
                    src={BUILD_VERSION_EXTENSION+'/update-variants-modal'}
                    open={this.state.update_variants__modal_open}
                    onClose={() => this.setState({update_variants__modal_open: false})}
                    title={'Variants'}
                    primaryAction={{
                        content: 'Update',
                        onAction: this.handleSubmitUpdateVariants,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {this.setState({update_variants__modal_open: false})},
                        }
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.update_collections__modal_open && 
                <Modal
                    size="Medium"
                    src={BUILD_VERSION_EXTENSION+'/update-collections-modal'}
                    open={this.state.update_collections__modal_open}
                    onClose={() => this.setState({update_collections__modal_open: false})}
                    title={'Collections'}
                    primaryAction={{
                        content: 'Update',
                        onAction: this.handleSubmitUpdateCollections,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {this.setState({update_collections__modal_open: false})},
                        }
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
        </span>;

        const configProductsSection = <div>
            <FormLayout fullWidth>
                <RadioButton
                    label="Entire store"
                    id="entire_store"
                    name={"applies_to"}
                    checked={applies_to === "entire_store"}
                    onChange={(v,id)=>{
                        this.setState({applies_to:id});
                    }}
                />
                <ButtonGroup>
                    <RadioButton
                        label="Specific collections"
                        id="collections"
                        name={"applies_to"}
                        checked={applies_to === "collections"}
                        onChange={(v,id)=>{
                            this.setState({applies_to:id,collection_modal_open:true});
                        }}
                    />
                    {
                        applies_to === "collections"?
                            <div>
                                <Button
                                    plain
                                    onClick={()=>{this.setState({collection_modal_open:true})}}
                                >[ +Add ]</Button>
                                {
                                    this.state.collections.length > 0 ?
                                    <span>
                                        &nbsp;&nbsp;
                                        <Button plain
                                            onClick={()=>{
                                                store('collections', this.state.collections);
                                                this.setState({update_collections__modal_open:true});
                                            }}
                                            >Edit <label style={{borderBottom:'1px dotted'}}>{this.state.collections.length}</label> ]
                                        </Button>
                                    </span>
                                    :null
                                }
                            </div>
                        :null
                    }
                </ButtonGroup>
                <ButtonGroup>
                    <RadioButton
                        label="Specific products"
                        id="products"
                        name={"applies_to"}
                        checked={applies_to === "products"}
                        onChange={(v,id)=>{
                            this.setState({applies_to:id,product_modal_open:true});
                        }}
                    />
                    {
                        applies_to === "products"?
                            <div>
                                <Button
                                    plain
                                    onClick={()=>{this.setState({product_modal_open:true})}}
                                >[ +Add ]</Button>
                                {
                                    this.state.products.length > 0 ?
                                    <span>
                                        &nbsp;&nbsp;
                                        <Button plain
                                            onClick={()=>{
                                                store('products', this.state.products);
                                                this.setState({update_products__modal_open:true});
                                            }}
                                            >Edit <label style={{borderBottom:'1px dotted'}}>{this.state.products.length}</label> ]
                                        </Button>

                                    </span>
                                    :null
                                }
                            </div>
                        :null
                    }
                </ButtonGroup>
                {
                 "variant_level" === minimum_quantity_method &&
                <ButtonGroup>
                    <RadioButton
                        label="Specific variants"
                        id="variants"
                        name={"variants"}
                        checked={applies_to === "variants"}
                        onChange={(v,id)=>{
                            this.setState({
                                applies_to:id,
                                product_modal_for_variant_open:true
                            });
                        }}
                    />
                    {
                        applies_to === "variants"?
                            <div>
                                <Button
                                    plain
                                    onClick={()=>{this.setState({product_modal_for_variant_open:true})}}
                                >[ +Add ]</Button>
                                {
                                    this.state.variants.length > 0 ?
                                    <span>
                                        &nbsp;&nbsp;
                                        <Button plain
                                            onClick={()=>{
                                                store('variants', this.state.variants);
                                                this.setState({update_variants__modal_open:true});
                                            }}
                                            >Edit <label style={{borderBottom:'1px dotted'}}>{this.state.variants.length}</label> ]
                                        </Button>
                                    </span>
                                    :null
                                }
                            </div>
                        :null
                    }
                </ButtonGroup>
                }
            </FormLayout>
        </div>;

        const except_logged_in_section = <div>
            <Checkbox
                label="Except all logged-in customers (Optional)"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked });
                }}
            />
        </div>;

        const except_taged_section = <Stack vertical>
            <Checkbox
                label="Except logged-in customers with matching tag (Optional)"
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {
                except_tags?
                    <div>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    label="Except customer tags"
                                    labelHidden
                                    autoFocus
                                    maxLength={255} showCharacterCount={true}
                                    placeholder="Enter comma separated tags"
                                    value={temp_tag_value}
                                    onChange={(v)=>{
                                        if(v && v.indexOf(",") >-1 ){
                                            v= v.replace(",","");
                                            v = v.trim();
                                            if(v === "") return false;
                                            var excepted_tags = this.state.excepted_tags;
                                            if(excepted_tags.indexOf(v) > -1){
                                                this.showToastMessage("Tag already exists", true);
                                                return false;
                                            }
                                            excepted_tags.push(v);
                                            this.setState({ excepted_tags, temp_tag_value: "" });
                                        }
                                        else{
                                            this.setState({temp_tag_value:v});
                                        }
                                    }}
                                    onBlur={()=>{
                                        var v = this.state.temp_tag_value;
                                        v = v.replace(",","");
                                        v = v.trim();
                                        if(v === "") return false;
                                        var excepted_tags = this.state.excepted_tags;
                                        if(excepted_tags.indexOf(v) > -1){
                                            return false;
                                        }
                                        excepted_tags.push(v);
                                        this.setState({ excepted_tags, temp_tag_value: "" });
                                    }}
                                ></TextField>
                                <div></div>
                                <div></div>
                            </FormLayout.Group>
                        </FormLayout>
                    </div>
                : null
            }
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;

        const Customer_section = <div>
            <Stack vertical spacing="tight">
                <RadioButton
                    label="All customers"
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "all" ?
                        <div className="ws-card">
                            <Stack vertical>
                                {except_logged_in_section}
                                {except_taged_section}
                            </Stack>
                        </div>
                    :null
                }
                <RadioButton
                    label="All logged-in customers"
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });
                    }}
                />
                {
                    customer_group === "logged_in" ?
                        <div className="ws-card">
                            {except_taged_section}
                        </div>
                    :null
                }

                <RadioButton
                    label="Logged-in customers with matching tag (Recommended)"
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "tag_based" && 
                    <div className="ws-card">
                        <Stack vertical>
                            <Stack.Item>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            label="Customer tags"
                                            labelHidden
                                            autoFocus
                                            value={temp_customer_tags_value}
                                            maxLength={255} showCharacterCount={true}
                                            placeholder="Enter comma separated tags"
                                            onChange={(v)=>{
                                                if(v && v.indexOf(",") >-1 ){
                                                    v= v.replace(",","");
                                                    v = v.trim();
                                                    if(v === "") return false;
                                                    var customer_tags = this.state.customer_tags;
                                                    if(customer_tags.indexOf(v) > -1){
                                                        this.showToastMessage("Tag already exists", true);
                                                        return false;
                                                    }
                                                    customer_tags.push(v);
                                                    this.setState({ customer_tags, temp_customer_tags_value: "" });
                                                }
                                                else{
                                                    this.setState({temp_customer_tags_value:v});
                                                }
                                            }}
                                            onBlur={()=>{
                                                var v = this.state.temp_customer_tags_value;
                                                v = v.replace(",","");
                                                v = v.trim();
                                                if(v === "") return false;
                                                var customer_tags = this.state.customer_tags;
                                                if(customer_tags.indexOf(v) > -1){
                                                    return false;
                                                }
                                                customer_tags.push(v);
                                                this.setState({ customer_tags, temp_customer_tags_value: "" });
                                            }}
                                        />
                                        <div></div>
                                        <div></div>
                                    </FormLayout.Group>
                                </FormLayout>
                            </Stack.Item>
                            <Stack.Item>
                            {
                                this.state.customer_tags.length > 0 && 
                                <Stack spacing="tight">
                                    {this.renderCustomerTags()}
                                </Stack>
                            }
                            </Stack.Item>
                        </Stack>
                    </div>
                }
            </Stack>
        </div>;

        var footerButtons = <div><div className="bottom-line"></div>
            <div className="bottom-save-buttons">
                {
                    this.props.is_basic_plan && <img style={{color:"#ffffff"}} width="36px" height="30px" src='/icons/lock.svg' alt="lock-icon" />
                }
                <Button
                    primary
                    disabled = { this.disableSaveButton() }
                    loading = { this.state.save_button_loading }
                    onClick = { () => { this.saveData() }}
                >Save</Button>
            </div>
        <div className="clearfix"></div></div>;

        var quantitiesSection = <div>
            <FormLayout>
                <FormLayout.Group fullWidth>
                    <TextField
                        label="Default Quantity"
                        helpText="The quantity set by default. Leave blank if not set"
                        type="number"
                        value={getValue(default_quantity)}
                        onChange={(default_quantity)=>{this.setState({ default_quantity })}}
                        min={1}
                        disabled={status === "disabled"}
                        onBlur={()=>{
                            if(Number(default_quantity) < 0){
                                this.setState({ default_quantity: 1 });
                            }
                        }}
                    />
                    <TextField
                        label="Quantity Increments"
                        helpText="Set a quantity quantity_increment value. For example, allow to buy packs of 25 items, i.e. (25,50,75, etc). Leave blank if not set"
                        type="number"
                        value={getValue(quantity_increment)}
                        onChange={(quantity_increment)=>{this.setState({ quantity_increment })}}
                        min={1}
                        disabled={status === "disabled"}
                        onBlur={()=>{
                            if(Number(quantity_increment) < 0){
                                this.setState({ quantity_increment: 1 });
                            }
                        }}
                    />
                </FormLayout.Group>
                <FormLayout.Group fullWidth>
                    <TextField
                        label="Minimum Quantity"
                        helpText="Set minimum quantity allowed to purchase. Leave blank if not set"
                        type="number"
                        value={getValue(minimum_quantity)}
                        onChange={(minimum_quantity)=>{this.setState({ minimum_quantity })}}
                        min={1}
                        disabled={status === "disabled"}
                        quantity_increment={quantity_increment}
                        error={minimum_quantity !== "" && parseInt(default_quantity) > parseInt(minimum_quantity) ?"Minimum quantity must be less than or equal to default quantity":(minimum_quantity !== "" && parseInt(minimum_quantity) % parseInt(quantity_increment) !== 0?"Minimum quantity must be multiple of "+quantity_increment:"")}
                        onBlur={()=>{
                            if(Number(minimum_quantity) < 0){
                                this.setState({ minimum_quantity: 1 });
                            }
                        }}
                    />
                    <TextField
                        label="Maximum Quantity"
                        helpText="Set maximum quantity allowed to purchase. Leave blank if not set"
                        type="number"
                        value={getValue(maximum_quantity)}
                        onChange={(maximum_quantity)=>{this.setState({ maximum_quantity })}}
                        min={1}
                        quantity_increment={quantity_increment}
                        disabled={status === "disabled"}
                        error={ maximum_quantity !== "" && Number(minimum_quantity) > Number(maximum_quantity) && "Maximum quantity must be equal to or greather than maximum quantity"}
                        onBlur={()=>{
                            if(Number(maximum_quantity) < 0){
                                this.setState({ maximum_quantity: 1 });
                            }
                        }}
                    />
                    </FormLayout.Group>
            </FormLayout>
        </div>;

        var basicInfoSection = <div>
            <FormLayout>
                <FormLayout.Group fullWidth>
                    <TextField
                        label="Title"
                        autoFocus
                        value={internal_name}
                        onChange={(internal_name) => { this.setState({internal_name})} }
                    />
                    <TextField
                        label="Message"
                        value={message}
                        placeholder="Please add atleast 5 items or multiple of 5."
                        onChange={(message) => { this.setState({message})} }
                    />
                </FormLayout.Group>
                <FormLayout.Group>
                    <Checkbox
                        label="Enable Quantity selector for individual variant on product page."
                        checked={selector_for_individual_variant}
                        id="selector-for-individual-variant"
                        onChange={(v,id) => {this.setState({selector_for_individual_variant:v});}}
                    />
                </FormLayout.Group>
            </FormLayout>
        </div>;

        var minQuantityMethodSection = <div>
            <FormLayout>
                    <RadioButton
                        label="Set minimum quantity limit at Product level"
                        helpText="Total quantity [6] can be  max of different variants of within a product like Small[2], Medium[3] and Large[1]"
                        checked={"product_level" === minimum_quantity_method}
                        onChange={ (v,id) => {
                            if(applies_to === 'variants'){
                                this.setState({applies_to: "products"});
                            }
                            this.setState({minimum_quantity_method:"product_level"});
                        }}
                    >
                    </RadioButton>
                    <RadioButton
                        label="Set minimum quantity limit at Variant level"
                        helpText="Each variants will have it's own minimum quantity limit like Small[6], Medium[6] and Large[6]"
                        checked={"variant_level" === minimum_quantity_method}
                        onChange={ (v,id) => {
                            if(applies_to === 'variants'){
                                this.setState({applies_to: "products"});
                            }
                            this.setState({minimum_quantity_method:"variant_level"});
                        }}
                    ></RadioButton>
                    <RadioButton
                        label="Set minimum quantity limit at Multiple Product level"
                        helpText="Total quantity [6] can be max of different variants of the selected products like Small[2] of product A, Medium[3] of Product B and Large[1] of Product C"
                        checked={"selected_product_level" === minimum_quantity_method}
                        onChange={ (v,id) => {
                            if(applies_to === 'variants'){
                                this.setState({applies_to: "products"});
                            }
                            this.setState({minimum_quantity_method:"selected_product_level"});
                        }}
                    >
                    </RadioButton>
            </FormLayout>
        </div>;

        var addEditRuleSection = <div>
            {resource_pikers}
            <Layout>
                <Layout.Section>
                    <Card sectioned title="Basic Info" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                        {basicInfoSection}
                    </Card>
                </Layout.Section>   
                <Layout.Section>
                    <Card sectioned title="Customer Groups" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                        {Customer_section}
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card sectioned title="Minimum Quantity Methods" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                        {minQuantityMethodSection}
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card sectioned title="Applies to" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                        {configProductsSection}
                    </Card>
                </Layout.Section>   
                <Layout.Section>
                    <Card sectioned title="Quantities" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                        {quantitiesSection}
                    </Card>
                </Layout.Section>   
                <Layout.Section>
                        {footerButtons}
                    </Layout.Section>
            </Layout>
        </div>;

        const qc_rows = this.state.allQCRules.map( (item,i) => [
            <Link url={BUILD_VERSION_EXTENSION+'/edit-quantity-controller/'+item._id} target="APP">
                {item.internal_name.substring(0,40)}{item.internal_name.length > 40?"...":null}
            </Link>,
            <span style={{textTransform:"capitalize"}}>
            {item.customer_group !== "tag_based" ?item.customer_group.replace("_"," "):null}
            {
                item.customer_group === "tag_based" ?
                    <span>
                        {this.showTag(item)}
                        {
                            item.customer_tags.length > 1?
                            <span className="and-more" style={{textTransform:"lowercase"}}> + {item.customer_tags.length - 1} more tags </span>
                            :null
                        }
                    </span>
                :null
            }
            </span>,
            <span style={{textTransform:"capitalize"}}>
                {
                    item.applies_to?
                    item.applies_to.replace("_"," "):"N/A"
                }
            </span>,
            <span>
                {
                    item.status === "enabled"?
                    <Badge status="success">Published</Badge>
                    :<Badge>Unpublished</Badge>
                }
            </span>,
            <ButtonGroup segmented>
                <Button
                    size="slim"
                    url={BUILD_VERSION_EXTENSION+'/edit-quantity-controller/'+item._id}
                    target="APP"
                >
                Edit
                </Button>
                {
                    item.status === "disabled"?
                    <Button
                        size="slim" 
                        onClick={() => {this.enableRule(item)}}
                    > Publish </Button>
                    :<Button
                        size="slim" 
                        onClick={() => {this.disableRule(item)}}
                    > Unpublish </Button>
                }
                <Button
                    size="slim" 
                    onClick={() => {this.deleteRule(item)}}
                > Delete </Button>
            </ButtonGroup>
        ]);
var toast = this.state.showToast? (
    <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
    />
  ): (null);
  
        if(this.state.show === "add" || this.state.show === "edit" ){
            return (
                <div>
                    <PageTitleBar
                        title={this.state.show === "add"?"Add":"Edit"}
                        breadcrumbs={[{content: 'Quantity controls', url: BUILD_VERSION_EXTENSION+'/quantity-controller', target: "APP"}]}
                        secondaryActions={[{content: 'Back', onAction: () => {this.props.history.push(BUILD_VERSION_EXTENSION+'/add-quantity-controller');this.getAllQC();this.setState({show:"list"})} }]}
                        primaryAction={{content:" Save ", onAction: () => this.saveData(), disabled: this.disableSaveButton()? true: this.state.save_button_loading }}
                    />
                    {
                        !this.hasFeatures("quantity_controller") &&
                        <div style={{marginBottom: '1rem'}}>
                        <Banner
                            title="Activation Required"
                            status="critical"
                        >
                            <p>To use the Advanced MOQ addon, please activate it in the Billing section first. <Link external url='https://support.digitalcoo.com/hc/en-us/articles/360045823331'>[View details]</Link> | <Link url={BUILD_VERSION_EXTENSION+"/update-plans"}>[Go to Billing section]</Link></p>
                        </Banner>
                        </div>
                    }
                    {
                        this.props.productPermissionError &&
                        <div style={{marginBottom: '1rem'}}>
                        <Banner
                            title="Limited Access"
                            status="critical"
                        >
                            <p>You cannot add products or collections because your account has limited access. <Link external url="https://support.digitalcoo.com/hc/en-us/articles/35435268538260">Learn More</Link></p>
                        </Banner>
                        </div>
                    }
                    {toast}
                    {addEditRuleSection}
                </div>
            );
        }
        else{
            if(this.state.page_loaded && this.state.allQCRules.length < 1){
                return (
                    <div>
                        <EmptyState
                                heading="Create a quantity control"
                                action={{content: 'Create a quantity control', url: BUILD_VERSION_EXTENSION+'/add-quantity-controller', target: "APP"}}
                                image="https://cdn.shopify.com/s/assets/admin/empty-states-fresh/emptystate-transfers-7caef90a7678b9dba63b068c63bc911796252c217b573d989bd4f0d9c15fee81.svg"
                            >
                            <p>
                                Force users to buy specific quantities
                            </p>
                        </EmptyState>
                    </div>
                );
            }
            else{
                return (
                    <div>
                        <PageTitleBar
                            title="Quantity controls"
                            breadcrumbs={[{content: 'Dashboard', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                            secondaryActions={[{content: 'Back', onAction: () => window.location.pathname = BUILD_VERSION_EXTENSION+"/"}]}
                        />
                        <div style={{ margin: "1rem 0rem" }}>
                            <ButtonGroup>
                                <Button primary size="slim" url={BUILD_VERSION_EXTENSION+"/add-quantity-controller"} target="APP">Create a quantity control</Button>
                            </ButtonGroup>
                        </div>
                        {toast}
                        {
                            this.state.allQCRules.length > 0?
                                <Card>
                                    <DataTable
                                        columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        ]}
                                        headings={[
                                        'Title',
                                        'Customer Group',
                                        'Applies to',
                                        'Status',
                                        'Actions',
                                        ]}
                                        rows={qc_rows}
                                    />
                                </Card>
                            :null
                        }
                    </div>
                );
            }
        }
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}