// docs: https://shopify.dev/tools/app-bridge/react-components/resourcepicker
import React from 'react';
import { ResourcePicker} from '@shopify/app-bridge-react';
import CommonHelper from '../Helpers/Common';

class PageResourcePicker extends React.Component {
  render() {
    return (
        <ResourcePicker
          open={!0}
          resourceType={this.props.resourceType||"Product"}
          // initialQuery={this.props.initialQuery||null}
          initialSelectionIds={this.props.initialSelectionIds||[]}
          showHidden={this.props.showHidden||false}
          allowMultiple={this.props.allowMultiple||!0}
          showVariants={this.props.showVariants||false}
          onSelection={(resources) => {
            resources.selection = resources.selection.map( x => {
              x["wsid"] = x.id; 
              x["id"] = CommonHelper.getId(x.id);
              try {
                x.tags = x.tags.toString();
              } catch (e) { }
              try {
                if(x && x.images && x.images.length > 0){
                  x["image"] = {
                    "src" : x.images[0].originalSrc,
                    "alt" : x.images[0].altText,
                    "altText": x.images[0].altText
                  }
                }
              } catch (e) { }
              try{
                if(this.props.resourceType !== "Collection"){
                  x["variants"] = x.variants.map(v => {
                    v["wsid"] = v.id;
                    try{
                      v["product_id"] = CommonHelper.getId(v.product.id);
                    }catch(e){}
                    v["id"] = CommonHelper.getId(v.id);
                    return {
                      id: v.id,
                      title: v.title,
                      sku: v.sku,
                      price: v.price,
                      compare_at_price: v.compareAtPrice,
                      product_id: v.product_id,
                      available: v.availableForSale
                    };
                  });
                }
              }catch(e){}
              return x;
            });
            if(typeof this.props.onSelection === "function"){
              this.props.onSelection(resources);
            }
          }}
          onCancel={this.props.onCancel}
        />
    );
  }
}

export default PageResourcePicker;