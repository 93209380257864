import React, { Component } from 'react';
import { InlineError, Card, Stack, Form, FormLayout, TextField, Checkbox, Tag, Select, Button, RadioButton, ButtonGroup, Badge, Link, Icon, Banner, TextStyle } from '@shopify/polaris';
import { DeleteMinor, LockMinor, MinusMinor, QuestionMarkMinor } from '@shopify/polaris-icons';
import store from 'store2';
import _Modules from '../Json/modules.json';
import waio from '../Helpers/functions';
import * as PropTypes from 'prop-types';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import { Modal } from '@shopify/app-bridge-react';
import Common from '../Helpers/Common';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var snippet_version = store('snippet_version') || "2.3.8";
// var store_currency = store('store_currency') || null;
const money_format = store('ws_money_format') || null;

// eslint-disable-next-line
Number.prototype.countDecimals = function () {
    if(isNaN(this))return 0;
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0; 
}

export default class CreateManageShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            rules: [{ "column": "cart_original_total_price", "relation": "greater_than_or_equal_to", "condition": "" }],
            rules_operator: "and",
            message: "",
            title: "",
            status: "active",
            customer_group: "tag_based",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            saveButtonLoading: false,
            allShippingRules: [],
            page_loaded: false,
            free_shipping: false,
            charges: 0,
            applies_to: 'entire_store',
            products: [],
            collections: [],
            variants: [],
            product_modal_open: false,
            update_collections__modal_open: false,
            update_products__modal_open: false,
            charges_type: "price_range",
            range_based_on: "cart_original_total_price",
            range: [{ "min": "", "max": "", "charges": "" }],
            ignore_rules: false,
            settings: [],
            showToast: false,
            toastMsg: '',
            toastError: false,
            selectCountryModalOpen: false,
            country_selection: "all",
            selected_countries: [],
            validation_error:null,
            
            // Default value of weight, older rule will not be effected
            weight_unit: "g",
            weight_unit_label: "Grams"
        };
        store.remove('products');
        store.remove('collections');
        store.remove("shipping_selected_countries");
        store.remove("all_shipping_rules");
        store.remove("shipping_state");
    }

    disableSave = false;

    static contextTypes = {
        easdk: PropTypes.object,
    };

    findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
                // eslint-disable-next-line
            } while (obj = obj.offsetParent);
            return curtop;
        }
    }
    scrollToTop(elem = null){
        let offset = 0;
        if(elem)offset = this.findPos(elem) - 30;
        window.scrollTo({top: offset, behavior: 'smooth'});
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }
    
    
    relation_options = [
        { label: "is greater than or equal to", value: "greater_than_or_equal_to", disabled: false },
        { label: "is less than or equal to", value: "less_than_or_equal_to", disabled: false },
        { label: "is equal to", value: "equal", disabled: false },
        { label: "is not equal to", value: "not_equal", disabled: false }
    ];

    needToUpgrade = false;

    componentDidMount(){
        this.getAllShippings();
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }
        else{
            // This is create page, get the weight dynamically
            let weight_unit = store('weight_unit') || "g";
            
            const weight_unit_label = weight_unit === "g" ? "Grams" :
            weight_unit === "lb" ? "Pounds" :
            weight_unit === "kg" ? "Kilograms" :
            weight_unit === "oz" ? "Ounce" :
            "";

            this.setState({
                weight_unit: weight_unit,
                weight_unit_label: weight_unit_label
            });
        }
        
        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }

        if(this.props.shop){
            this.getSettings();
        }
    }

    getSettings(){
        axios.get('/app/settings/'+this.props.shop )
            .then(r => {
                if (r && r.data) {
                    this.setState({settings:r.data});
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
    }

    getARuleForEdit(){
        axios.get('/app/get-shipping/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants,
                    charges_type,
                    range,
                    range_based_on,
                    ignore_rules
                } = r.data;

                // Since we removed free shipping option, move all rules having this type to fixed_based
                if(charges_type === "free"){
                    charges_type = "fixed_price";
                    charges = "0.00";
                }
      
                // Based on data fetched, generate the weight unit + label
                let weight_unit = this.state.weight_unit;
                if(r.data && typeof r.data.weight_unit !== "undefined"){
                    weight_unit = r.data.weight_unit;
                }

                const weight_unit_label = weight_unit === "g" ? "Grams" :
                weight_unit === "lb" ? "Pounds" :
                weight_unit === "kg" ? "Kilograms" :
                weight_unit === "oz" ? "Ounce" :
                "";

                var selected_countries = this.state.selected_countries;
                var country_selection = this.state.country_selection;
                if(r.data && r.data.selected_countries){
                    selected_countries = r.data.selected_countries;
                }
                if(r.data && r.data.country_selection){
                    country_selection = r.data.country_selection;
                }
                this.setState({
                    weight_unit,
                    weight_unit_label,
                    selected_countries,
                    country_selection,
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants,
                    charges_type,
                    range,
                    range_based_on,
                    ignore_rules
                });
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    getAllShippings(){
        axios.get('/app/all-shippings/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allShippingRules:r.data,page_loaded:true});
            }
            else {
                this.showToastMessage("Server error", true);
            }
            this.setState({page_loaded:true});
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
            this.setState({page_loaded:true});
        });
    }

    addCondition() {
        var rules = this.state.rules;
        rules.push(
            { "column": "cart_original_total_price", "relation": "greater_than_or_equal_to", "condition": "" }
        );
        this.setState({rules});
    }

    removeCondition(i) {
        var rules = this.state.rules;
        rules.splice(i, 1);
        this.setState({ rules });
    }

    changeConditionValue(value, id, i) {
        var rules = this.state.rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ rules });
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    saveRule(){
        this.setState({saveButtonLoading: true, validation_error:null});

        // ==============
        //  Validating...
        // ==============

        if(Common.getPlanID() === 1){
            this.showToastMessage("upgrade your plan to continue using shipping", true);
            return;
        }

        const error = this.validation();
        if(error){
            // Some error found, update state 
            this.setState({
                validation_error: error,
                saveButtonLoading: false
            });
            // Scroll to that elem
            const elem = document.getElementById(error.id);
            if(elem) this.scrollToTop(elem);
            return;
        }

        // ==============
        //  Save Rule
        // ==============
        const charges = !this.state.charges || this.state.charges === null || Number(this.state.charges) < 0 ? "0.00" : this.state.charges;
        let range = this.state.range.map(range => {
            return {
                min: range.min && Number(range.min) > 0 ? range.min : "0.00",
                max: range.max,
                charges: range.charges && Number(range.charges) > 0 ? range.charges : "0.00",
            };
        });

        // Sort range by starting value - ASC
        range = range.sort((a,b) => {
            const aMin = parseFloat(a.min) || 0;
            const bMin = parseFloat(b.min) || 0;

            return aMin - bMin;
        });
        const data = {
            shop: this.props.shop,
            rules: this.state.rules,
            rules_operator: this.state.rules_operator,
            message: this.state.message,
            title: this.state.title,
            charges: charges,
            free_shipping: Number(charges) === 0,
            status: this.state.status,
            customer_group: this.state.customer_group,
            customer_tags: this.state.customer_group === 'tag_based'? this.state.customer_tags:[],
            except_logged_in: this.state.except_logged_in,
            except_tags: this.state.except_tags,
            excepted_tags: this.state.excepted_tags,
            products: this.state.products,
            collections: this.state.collections,
            variants: [],
            applies_to: this.state.applies_to,
            charges_type: this.state.charges_type,
            range: range,
            range_based_on: this.state.range_based_on,
            ignore_rules: this.state.ignore_rules,
            country_selection: this.state.country_selection,
            selected_countries: this.state.selected_countries,
            weight_unit: this.state.weight_unit, // current weight unit values are stored in  
        };
        if(this.state.show === "edit"){
            // /update-order-control
            axios.put('/app/update-shipping/'+this.state.edit_rule_id, data)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.props.history.push(BUILD_VERSION_EXTENSION+'/manage-shipping');
                            this.getAllShippings();
                            this.setState({show:"list"});
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                    else {
                        this.showToastMessage("Server error", true);
                    }
                    this.setState({saveButtonLoading: false})
                })
                .catch(error => {
                    this.showToastMessage("Server error", true);
                    this.setState({saveButtonLoading: false});
                });
        }
        else{
            axios.post('/app/add-shipping', data)
                .then(r => {
                    if (r && r.data && r.data.message) {
                        if(r.data.status === 1){
                            this.showToastMessage(r.data.message);
                            this.props.history.push(BUILD_VERSION_EXTENSION+'/manage-shipping');
                            this.getAllShippings();
                            this.setState({show:"list"});
                        }
                        else{
                            this.showToastMessage(r.data.message, true);
                        }
                    }
                    else {
                        this.showToastMessage("Server error", true);
                    }
                    this.setState({saveButtonLoading: false})
                })
                .catch(error => {
                    this.showToastMessage("Server error", true);
                    this.setState({saveButtonLoading: false});
                });
        }
    }

    validation(){
        let error_field = null; // Will help show errors on specefic fields
        var {excepted_tags,except_tags,rules,customer_group,customer_tags,title,range,charges_type,ignore_rules} = this.state;
        
        // Validate Conditions & Ranges & Rules - Priority => Least
        if(charges_type === "price_range" || charges_type === "price_range_percentage"){
            let prev_max = null;
            
            range.forEach((a, index) => {
                if(charges_type === "price_range_percentage"){
                    if(Number(a.charges) > 100){
                        error_field = {
                            state: "range",
                            key: "charges",
                            id: "ms-range-charges-"+index,
                            index: index,
                            msg: "Value must be less than 100"
                        };
                    }
                }

                if(a.max && a.max !== ""){
                    if(Number(a.min) > Number(a.max)){
                        error_field = {
                            state: "range",
                            key: "max",
                            id: "ms-range-max-"+index,
                            index: index,
                            msg: "Maximum value must be greater than Minimum value"
                        };
                    }
                }

                if(prev_max !== null){
                    // current iteration is not the first
                    if(typeof prev_max === "number")prev_max = prev_max.toString();
                    if(typeof prev_max === "string" && prev_max.trim() === ""){
                        error_field = {
                            state: "range",
                            key: "max",
                            id: "ms-range-max-"+(index-1),
                            index: index-1, // previous row
                            msg: "Maximum value is required before adding range"
                        };
                    }

                    // Check if current range don't fall in another range
                    let fallenRange = null;
                    const isFallOnAntoher = range.some((x, xIndex) => {
                        // Exclude current range
                        if(xIndex === index) return false;

                        // iteration range values
                        const xMin = parseFloat(x.min) || 0;
                        const xMax = x.max === "" ? "" : parseFloat(x.max) || 0;

                        // current range values
                        const aMin = parseFloat(a.min) || 0;
                        const aMax = a.max === "" ? "" : parseFloat(a.max) || 0;

                        if(aMax === ""){
                            // Unlimited max value, just validate min value
                            if(aMin <= xMax){
                                // range is found,
                                fallenRange = {
                                    key: "min",
                                    id: "ms-range-min-"+index,
                                    index: index
                                };
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else{
                            // Validate max & min values

                            // Validate min value
                            if(aMin <= xMax && aMax >= xMin){

                                fallenRange = {
                                    key: "min_max", // will highlight both min & max
                                    id: "ms-range-min-"+index,
                                    index: index
                                };
                                return true;
                                
                            }
                            else {
                                return false;
                            }
                        }

                    });

                    if(isFallOnAntoher){
                        error_field = {
                            state: "range",
                            key: fallenRange.key,
                            id: fallenRange.id,
                            index: fallenRange.index,
                            msg: "Value already exists in another range"
                        };
                    }
                }
                prev_max = a.max ? (a.max).toString() : "";
            });
        }
        else {

            if(!ignore_rules){
                rules.forEach((rule, index) => {
                    if(!rule.condition || rule.condition.trim() === ""){
                        error_field = {
                            state: "rules",
                            key: "condition",
                            id: "ms-rules-condition-"+index,
                            index: index,
                            msg: "Value is required"
                        };
                    }
                });
            }

        }

        // Validate specific countries
        if(customer_group !== "all" && this.state.country_selection === "selected"){
            try {
                if(this.state.selected_countries.length < 1){
                    error_field = {
                        state: "selectCountryModalOpen",
                        id: "ms-selected_countries",
                        msg: "At least one country is required"
                    };
                }
            } catch (e) { }
        }

        // Validate customer except tags
        if(except_tags && excepted_tags.length < 1) {
            error_field = {
                state: "temp_tag_value",
                id: "exc-customer-tag-input",
                msg: "Tags are required"
            };
        }

        // Validate customer tags
        if(customer_group === "tag_based" && customer_tags.length < 1){
            error_field = {
                state: "temp_customer_tags_value",
                id: "customer-tag-input",
                msg: "Tags are required"
            };
        }

        // Validate Title - Priority => Most
        if(!title || title.trim() === ""){
            error_field = {
                state: "title",
                id: "ms-title",
                msg: "Title is required"
            };
        }

        return error_field;
    }

    renderCustomerTags(){
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });

        this.clearSelectedCountries();
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.title}?`)){
            axios.delete('/app/delete-shipping/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    handleSubmitUpdateProducts = (selected) => {
        let products = store('products') || [];
        this.setState({
            products,
            update_products__modal_open: false
        })
    }
    
    handleSubmitUpdateCollections = (selected) => {
        let collections = store('collections') || [];
        this.setState({
            collections,
            update_collections__modal_open: false
        })
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.title}?`)){
            var data = {
                _id: item._id,
                status: "disabled"
            };
            axios.put('/app/update-status-shipping/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    enableRule(item){
        var data = {
            _id: item._id,
            status: "active"
        };
        axios.put('/app/update-status-shipping/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllShippings();
                }
                else{
                    this.showToastMessage("Server error", true);
                }
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    removeShippingRange(i){
        var range = this.state.range;
        range.splice(i, 1);
        this.setState({ range });
    }

    addShippingRange() {
        var range = [...this.state.range];
        var range_based_on = this.state.range_based_on;
        // Add empty row - But not actually add it, need to validate it
        
        // Calculate next min value which will be small increamenet to previous max value
        const lastMax = range.length > 0 ? range[range.length-1].max : null;

        let incAmount = 1;
        if(range_based_on === "cart_original_total_price"){
            incAmount = Number(Common.getCurrency(0.01, money_format).amount); // let currency round the figure if not supporing decimals
            incAmount = incAmount === 0 ? 1: incAmount;

        }

        let newMin = (Number(lastMax)+incAmount).toString();
        if(range_based_on === "cart_total_weight"){
            // // last max decimal places count
            // const lastMaxDecimalCount = Number(lastMax).countDecimals();
            // // making a number to {n} decimal places that will match the decimal places of last max number,
            // // so increament will make sense
            // const nDiv = (1).toFixed(lastMaxDecimalCount).replace(/[.]/g, '');
            // incAmount = Number( 1 / Number(nDiv) );
            // newMin = (Number(lastMax)+incAmount).toFixed(lastMaxDecimalCount);

            // just fixed decimal places to 3 degit
            incAmount = 0.001;
            newMin = (Number(lastMax)+incAmount).toFixed(3);
            
        }

        
        range.push(
            { 
                "min": lastMax !== null && lastMax !== "" ? newMin : "", 
                "max": "", 
                "charges": ""
            }
        );

        // Basic validation
        let error_field = null; // Will help show errors on specefic fields
        let prev_max = null;
        
        range.forEach((a, index) => {
            
            if(prev_max !== null){
                if(typeof prev_max === "number")prev_max = prev_max.toString();
                if(typeof prev_max === "string" && prev_max.trim() === ""){
                    error_field = {
                        state: "range",
                        key: "max",
                        id: "ms-range-max-"+(index-1),
                        index: index-1, // previous row
                        msg: "Maximum value is required before adding new row"
                    };
                }
            }
            prev_max = a.max ? (a.max).toString() : "";
        });

        if(error_field !== null){
            // Some errors found, restrict from adding new row
            this.setState({
                validation_error: error_field
            });
        }
        else{

            this.setState({
                validation_error: null,
                range: range
            });
        }

    }

    changeShippingRangeValue(value, id, i) {
        var range = this.state.range;
        range[i] = { ...range[i], [id]: value };
        this.setState({ range, validation_error: null });
    }

    clearRange(){
        // Reset range to inital value
        this.setState({ range: [{ "min": "", "max": "", "charges": "" }], validation_error: null });
    }

    addCustomerTags(tags_key, temp_tags_key) {
        /* Fetch tags from state */
        var temp_customer_tags_value = this.state[temp_tags_key];
        try{
            temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
        }catch(e){}
        if(temp_customer_tags_value.trim() === "") return;

        /* Check if tags are not already exists */
        var customer_tags = this.state[tags_key];
        if(customer_tags.some(tag=>tag.toLowerCase() === temp_customer_tags_value.toLowerCase())){
            this.showToastMessage("Tag already exists",true);
            return false;
        }

        /* Update tags */
        customer_tags.push(temp_customer_tags_value);
        this.setState({ [tags_key]: customer_tags, [temp_tags_key]: "" });

        this.clearSelectedCountries();
    }

    // Shopify customer segment query based on current customer selection like all, logged_in, tag_based & country selection
    generateMatchingCustomerQuery(){
        let query = "customer_account_status = 'ENABLED'";

        const customer_group = this.state.customer_group;
        let contain_tags = [];
        let not_contain_tags = [];

        if(customer_group === "tag_based") contain_tags = this.state.customer_tags;
        else if(this.state.except_tags) not_contain_tags = this.state.excepted_tags;

        // Query agaisnt customer tags
        if(contain_tags.length > 0){
            if(query!=="")query+=" AND ";
            query+="(";
            contain_tags.forEach((tag, index) => {
                if(index!==0)query+=" OR ";
                query += `customer_tags CONTAINS '${tag}'`;
            });
            query+=")";
        }

        // Query agaisnt except tags
        if(not_contain_tags.length > 0){
            if(query!=="")query+=" AND ";
            query+="(";
            not_contain_tags.forEach((tag, index) => {
                if(index!==0)query+=" OR ";
                query += `customer_tags NOT CONTAINS '${tag}'`;
            });
            query+=")";
        }

        return `https://${this.props.shop}/admin/customers?segment_query=${query}`;
    }

        // Reset selected countries when some changes are made
        clearSelectedCountries(){

            this.setState({
                selected_countries: []
            });
    
        }

    render() {
        const {
            rules,rules_operator,message,title,customer_group,except_logged_in,except_tags,excepted_tags,temp_tag_value, temp_customer_tags_value,charges,charges_type,range,range_based_on,ignore_rules
        } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        const customerTagsInputField = <Form onSubmit={() => {
            this.addCustomerTags("customer_tags", "temp_customer_tags_value");
        }}>
            <TextField
            label="Customer tags"
            id="customer-tag-input"
            labelHidden
            value={temp_customer_tags_value}
            placeholder="Enter tags"
            onChange={(v)=>{
                this.setState({temp_customer_tags_value:v});
            }}
            connectedRight={
                <Button
                    primary
                    onClick={()=>{
                        this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                    }}
                >Add</Button>
            }
            error={this.state.customer_tags.length === 0 ? "Tags are required" : this.state.validation_error && this.state.validation_error.state === "temp_customer_tags_value" ? this.state.validation_error.msg : false}
        />
        </Form>;

        const tags_input = <Form onSubmit={() => {
            this.addCustomerTags("excepted_tags", "temp_tag_value");
        }}>
            <TextField
            label="Tags"
            labelHidden
            placeholder="Enter comma separated tags"
            id="exc-customer-tag-input"
            value={temp_tag_value}
            onChange={(v)=>{
                this.setState({temp_tag_value:v});
            }}
            connectedRight={
                <Button
                    primary
                    onClick={()=>{
                        this.addCustomerTags("excepted_tags", "temp_tag_value");
                    }}
                >Add</Button>
            }
            error={this.state.validation_error && this.state.validation_error.state === "temp_tag_value" ? this.state.validation_error.msg : false}
        ></TextField>
        </Form>;

        const except_logged_in_section = <>
            <Checkbox
                label="Except logged in"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked, except_tags: false });
                }}
            />
        </>;

        const except_taged_section = <Stack vertical spacing="extraTight">
            <Checkbox
                disabled={except_logged_in}
                label="Exclude customers based on matching tag (Optional)"
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {
                ( except_logged_in === false && except_tags ) &&
                <Stack>
                    <Stack.Item>{tags_input}</Stack.Item>
                    <Stack.Item fill><div></div></Stack.Item>
                </Stack>
            }
            {
                (except_logged_in === false && except_tags && excepted_tags.length > 0 ) &&
                <Stack spacing="tight">
                    {this.renderExceptedTags()}
                </Stack>
            }
        </Stack>;

        const customers_section = <>
            <Stack vertical spacing="extraTight">
                <RadioButton
                    label={<span>All customers {Common.getPlanID() !== 3 && <span>(<Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"} target="APP" monochrome={true}>Upgrade to Business plan</Link>)</span>}</span>}
                    disabled={Common.getPlanID() !== 3}
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });

                        this.clearSelectedCountries();
                    }}
                />
                {
                    customer_group === "all" &&
                    <div style={{marginLeft:"2rem"}}>
                        {except_logged_in_section}
                        {except_taged_section}
                    </div>
                }
                 <RadioButton
                    label={
                        <Stack spacing="extraTight">
                            <span>All logged-in customers</span>
                            {
                                (customer_group === "logged_in" && this.state.country_selection === 'all') && 
                                <span>
                                    
                                    <Stack spacing="extraTight">
                                        <span>[</span>
                                        <Stack spacing="extraTight">
                                            <Link url={this.generateMatchingCustomerQuery()} external>Matching customers</Link>
                                            {/* <Button plain url={this.generateMatchingCustomerQuery()} external>Matching customers</Button> */}
                                            <Link url="https://support.digitalcoo.com/hc/en-us/articles/360062402771" external>
                                                <Icon source={QuestionMarkMinor} />
                                            </Link>
                                        </Stack>
                                        <span>]</span>
                                        
                                    </Stack>
                                    
                                </span>
                            }
                        </Stack>
                    }
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });

                        this.clearSelectedCountries();
                    }}
                />
                {
                    customer_group === "logged_in" &&
                    <div style={{marginLeft:"2rem"}}>
                        {except_taged_section}
                    </div>
                }
                <RadioButton
                    label={
                        <Stack spacing="extraTight">
                            <span>Logged-in customers with matching tag (Recommended)</span>
                            {
                                (customer_group === "tag_based" && this.state.country_selection === 'all') && 
                                <span>
                                    
                                    <Stack spacing="extraTight">
                                        <span>[</span>
                                        <Stack spacing="extraTight">
                                            <Link url={this.generateMatchingCustomerQuery()} external>Matching customers</Link>
                                            {/* <Button plain url={this.generateMatchingCustomerQuery()} external>Matching customers</Button> */}
                                            <Link url="https://support.digitalcoo.com/hc/en-us/articles/360062402771" external>
                                                <Icon source={QuestionMarkMinor} />
                                            </Link>
                                        </Stack>
                                        <span>]</span>
                                        
                                    </Stack>
                                    
                                </span>
                            }
                        </Stack>
                    }
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });

                        this.clearSelectedCountries();
                    }}
                />
                {
                    customer_group === "tag_based" && 
                    <div style={{marginLeft: "2rem"}}>
                        <Stack>
                            <Stack.Item>
                                {customerTagsInputField}
                            </Stack.Item>
                            <Stack.Item fill><div></div></Stack.Item>
                        </Stack>
                    </div>
                }
                {
                    customer_group === "tag_based" &&
                    <div style={{marginLeft: "2rem"}}>
                        <Stack spacing="tight">
                            {this.renderCustomerTags()}
                        </Stack>
                    </div>
                }
            </Stack>
        </>;

        const shipp_charges_type_styles = {
            padding: "0 45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "12em",
            height: "6em"
        }

        const shipp_charges_type = <div>
            <Stack alignment="center">
                <div style={shipp_charges_type_styles} className={"shipp-charges-type " + (this.state.charges_type=== "price_range" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"price_range", ignore_rules: true})}}>
                    <p>Flat rate on entire order</p>
                </div>
                <div style={shipp_charges_type_styles} className={"shipp-charges-type " + (this.state.charges_type=== "price_range_percentage" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"price_range_percentage", ignore_rules: true})}}>
                    <p>Percentage of cart total</p>
                </div>
                <div style={shipp_charges_type_styles} className={"shipp-charges-type " + (this.state.charges_type=== "fixed_price" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"fixed_price", ignore_rules: false})}}>
                    <p>Conditional rates</p>
                </div>                
            </Stack>
        </div>;

         // For generating prefixes and placeholders
         const zeroCurrency = Common.getCurrency(0, money_format);

         const conditions_section = rules.map((el, i) => {
 
             /* Check Errors */
             let error = null;
             if(this.state.validation_error && this.state.validation_error.state === "rules" && this.state.validation_error.index === i) error = this.state.validation_error;
 
             let condition_error = error && error.key === "condition" ? error.msg : false;
 
             return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    options={[
                        { label: "Cart total amount (Subtotal)", value: "cart_original_total_price", disabled: false },
                        { label: "Cart total items (Quantity)", value: "cart_item_count", disabled: false },
                        { label: `Cart total weight (${this.state.weight_unit_label})`, value: "cart_total_weight", disabled: false }
                    ]}
                    onChange={(selected, id) => {
                        // Clear condition value
                        this.changeConditionValue("", "condition", i);

                        this.changeConditionValue(selected, id, i);
                    }}
                ></Select>
                <Select
                    label="Relation"
                    labelHidden={true}
                    id="relation"
                    name="relation"
                    value={el.relation}
                    options={this.relation_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <TextField
                    prefix={
                        el.column==="cart_original_total_price" ? zeroCurrency.symbol :
                        el.column==="cart_item_count" ? "Qty" :
                        ""
                    }
                    suffix={
                        el.column === "cart_total_weight" ? this.state.weight_unit:
                        null
                    }
                    placeholder={
                        el.column==="cart_original_total_price" ? zeroCurrency.amount : "0"
                    }
                    type="text"
                    label="Condition"
                    labelHidden={true}
                    id={"ms-rules-condition-"+i}
                    error={condition_error}
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, "condition", i) }}
                    onBlur={() => {
                        if(el.condition && el.condition !== ""){
                            let currency = null;

                            // If based on total_price, we need to format it
                            if(el.column==="cart_original_total_price"){
                                currency = Common.getCurrency(el.condition, money_format).amount;
                            }
                            else if(el.column==="cart_item_count"){
                                // Make it a round number since this is Qty
                                currency = Number(el.condition).toFixed(0);
                            }
                            else{
                                currency = Number(el.condition);
                                
                                // Convert to 3 decimal points since this is weight
                                if(currency.countDecimals() > 3){
                                    currency = currency.toFixed(3);
                                }
                                else{
                                    // Just convert to srting
                                    currency = currency.toString();
                                }
                            }

                            // Checking if wrong number (Skipping for formay_money case)
                            if(isNaN(currency) && el.column!=="cart_original_total_price")currency="0";

                            if(currency !== null)this.changeConditionValue(currency, "condition", i);
                        }
                    }}
                ></TextField>
                <Button
                    onClick={this.removeCondition.bind(this, i)}
                    disabled={rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const price_range_ui = range.map((el,i) => {
            
            /* Generating suffix */
            let price_suffix = null;
            if(!el.charges || Number(el.charges) === 0){
                price_suffix = <>
                    <Badge>Free</Badge>
                </>
            }
            else if(charges_type === "price_range_percentage"){
                price_suffix = "%";

                // Basic validate charges
                if( isNaN(Number(el.charges)) || Number(el.charges) > 100 ) el.charges = 100;

            }
            let min_suffix = range_based_on==="cart_total_weight" ? this.state.weight_unit : "";
            let max_suffix = range_based_on==="cart_total_weight" ? this.state.weight_unit : "";

            /* Generating prefix */
            let price_prefix = null;
            if(charges_type !== "price_range_percentage"){
                price_prefix = zeroCurrency.symbol;
            }
            let min_prefix = range_based_on==="cart_original_total_price" ? zeroCurrency.symbol :
            range_based_on==="cart_item_count" ? "" :
            "";
            let max_prefix = range_based_on==="cart_original_total_price" ? zeroCurrency.symbol :
            range_based_on==="cart_item_count" ? "" :
            "";

            /* Generating plaeholder */
            let price_placeholder = charges_type === "price_range_percentage" ? "0" : zeroCurrency.amount;
            let min_placeholder = range_based_on==="cart_original_total_price" ? zeroCurrency.amount : "0";
            let max_placeholder = i === range.length - 1 ? "No limit" : range_based_on==="cart_original_total_price" ? zeroCurrency.amount : "0";
            
            /* Check Errors */
            let error = null;
            if(this.state.validation_error && this.state.validation_error.state === "range" && this.state.validation_error.index === i) error = this.state.validation_error;

            let price_error =  error && error.key === "charges";
            let min_error =  error && error.key === "min";
            let max_error =  error && error.key === "max";
            let minmax_error =  error && error.key === "min_max";

            
            return <Stack vertical spacing="tight">
                <Stack key={i}>
                <div style={{width:"24.5rem"}}>
                    <TextField
                        autoComplete="off"
                        placeholder={price_placeholder}
                        label="Shipping charges"
                        labelHidden={i!==0}
                        value={getValue(el.charges)}
                        id={"ms-range-charges-"+i}
                        name="charges"
                        type="text"
                        min="0"
                        suffix={ price_suffix }
                        prefix={ price_prefix }
                        onChange={(selected, id) => { this.changeShippingRangeValue(selected, "charges", i) }}
                        error={ price_error }
                        onBlur={() => {
                            if(el.charges && el.charges !== ""){
                                let currency = Common.getCurrency(el.charges, money_format);
                                this.changeShippingRangeValue(currency.amount, "charges", i);
                            }
                        }}
                    />
                    </div>
                    <Stack spacing="extraTight">
                        <div style={{maxWidth:"14rem"}}>
                            <TextField
                                autoComplete="off"
                                placeholder={min_placeholder}
                                label={
                                    range_based_on==="cart_original_total_price" ? "Minimum amount" :
                                    range_based_on==="cart_item_count" ? "Minimum quantity" :
                                    "Minimum weight"
                                }
                                labelHidden={i!==0}
                                value={getValue(el.min)}
                                id={"ms-range-min-"+i}
                                name="min"
                                type="text"
                                min="0"
                                error={ min_error || minmax_error }
                                suffix={ min_suffix }
                                prefix={ min_prefix }
                                onChange={(selected, id) => { this.changeShippingRangeValue(selected, "min", i) }}
                                onBlur={() => {

                                    if(el.min && el.min !== ""){
                                        let currency = null;

                                        // If based on total_price, we need to format it
                                        if(range_based_on==="cart_original_total_price"){
                                            currency = Common.getCurrency(el.min, money_format).amount;
                                        }
                                        else if(range_based_on==="cart_item_count"){
                                            // Make it a round number since this is Qty
                                            currency = Number(el.min).toFixed(0);
                                        }
                                        else{
                                            currency = Number(el.min).toFixed(3);
                                            
                                            // Convert to 3 decimal points since this is weight
                                            // if(currency.countDecimals() > 3){
                                            //     currency = currency.toFixed(3);
                                            // }
                                        }

                                        // Checking if wrong number (Skipping for formay_money case)
                                        if(isNaN(currency) && range_based_on!=="cart_original_total_price")currency="0";

                                        if(currency !== null)this.changeShippingRangeValue(currency, "min", i);
                                    }

                                }}
                            />
                        </div>
                        <div style={i===0 ? {display: "flex", height: "100%", paddingTop: "2.2rem"} : {display: "flex", height: "100%"}}>
                            <Icon source={MinusMinor} color="subdued" />
                        </div>
                        <div style={{maxWidth:"14rem"}}>
                            <TextField
                                autoComplete="off"
                                placeholder={max_placeholder}
                                label={
                                    range_based_on==="cart_original_total_price" ? "Maximum amount" :
                                    range_based_on==="cart_item_count" ? "Maximum quantity" :
                                    "Maximum weight"
                                }
                                labelHidden={i!==0}
                                value={getValue(el.max)}
                                id={"ms-range-max-"+i}
                                name="max"
                                type="text"
                                min="0"
                                error={ max_error || minmax_error }
                                suffix={ max_suffix }
                                prefix={ max_prefix }
                                onChange={(selected, id) => { this.changeShippingRangeValue(selected, "max", i) }}
                                onBlur={() => {  
                                    
                                    if(el.max && el.max !== ""){
                                        let currency = null;

                                        // If based on total_price, we need to format it
                                        if(range_based_on==="cart_original_total_price"){
                                            currency = Common.getCurrency(el.max, money_format).amount;
                                        }
                                        else if(range_based_on==="cart_item_count"){
                                            // Make it a round number since this is Qty
                                            currency = Number(el.max).toFixed(0);
                                        }
                                        else{
                                            currency = Number(el.max).toFixed(3);
                                            
                                            // Convert to 3 decimal points since this is weight
                                            // if(currency.countDecimals() > 3){
                                            //     currency = currency.toFixed(3);
                                            // }
                                        }

                                        // Checking if wrong number (Skipping for formay_money case)
                                        if(isNaN(currency) && range_based_on!=="cart_original_total_price")currency="0";

                                        if(currency !== null)this.changeShippingRangeValue(currency, "max", i);
                                    }

                                }}
                            />
                        </div>
                    </Stack>
                    {
                        range.length > 1 ? 
                        <div style={i===0 ? {display: "flex", height: "100%", alignItems: "flex-end"} : {}}>
                            <Button
                                onClick={this.removeShippingRange.bind(this, i)}
                                disabled={range.length === 1}
                            ><Icon source={DeleteMinor}/></Button>
                        </div>
                        : <div></div>
                    }
                </Stack>
                {
                    error && <InlineError message={error.msg} fieldID={error.id} />
                }
            </Stack>;
        });
        
        const add_or_edit_section = <>
            <Card title="Manage Shipping" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                <Card.Section>
                    <FormLayout fullWidth>
                        <FormLayout.Group>
                            <TextField
                                label={<span>Shipping title <span className="required">*</span></span>}
                                value={title || ""}
                                onChange={(value) => {
                                    this.setState({ title: value });
                                }}
                                id="ms-title"
                                helpText="Title of shipping line. e.g Free shipping"
                                maxLength={80}
                                showCharacterCount={true}
                                clearButton={true}
                                onClearButtonClick={() => { 
                                    this.setState({ title: "" });
                                }}
                                error={this.state.validation_error && this.state.validation_error.state === "title" ? this.state.validation_error.msg : false}
                            />
                            <TextField
                                label="Shipping message (optional)"
                                value={message}
                                onChange={(v) => {
                                    this.setState({ message: v });
                                }}
                                maxLength={160}
                                showCharacterCount={true}
                                clearButton={true}
                                onClearButtonClick={() => { 
                                    this.setState({ message: "" });
                                }}
                                helpText="This will display at cart page next to cart subtotal price"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
            </Card>
            <Card title="Customer Selection">
                <Card.Section>
                    <div className="left_right_grid">
                        <div className="eligibility_customers">
                        <div className="left_ce" style={{width: "60%"}}>
                                {customers_section}
                            </div>
                            {
                                (customer_group === 'logged_in' || customer_group === 'tag_based') &&
                                <div className="right_side_bar" style={{width: "40%"}}>
                                    <Stack vertical spacing="extraTight">
                                        <RadioButton
                                        disabled={customer_group === "tag_based" && this.state.customer_tags.length === 0}
                                            label="Apply rule for all countries"
                                            checked={this.state.country_selection === 'all'}
                                            id="all-countries"
                                            name="country-selection"
                                            onChange={(id,value)=>{this.setState({country_selection:"all"})}}
                                        />
                                        <RadioButton
                                        disabled={customer_group === "tag_based" && this.state.customer_tags.length === 0}
                                            label={
                                                <Stack spacing="extraTight">
                                                    <span>Apply rule for specific countries</span>
                                                    {this.state.country_selection === "selected" &&
                                                        <>
                                                            (&nbsp;&nbsp;<Link url="https://support.digitalcoo.com/hc/en-us/articles/7193868793492" external>Learn More</Link>)
                                                        </>
                                                    }
                                                </Stack>
                                            }
                                            checked={this.state.country_selection === 'selected'}
                                            id="selected-countries"
                                            name="country-selection"
                                            onChange={(id,value)=>{this.setState({country_selection:"selected"})}}
                                        />
                                        {
                                            this.state.country_selection === "selected" &&
                                            <div className="select_country_checkbox">
                                                    <Checkbox
                                                    disabled={customer_group === "tag_based" && this.state.customer_tags.length === 0}
                                                        label={                                                            
                                                            <Button size="slim">
                                                                <Stack spacing="extraTight" alignment="center">
                                                                <TextStyle variation={customer_group === "tag_based" && this.state.customer_tags.length === 0 ? "subdued" : null}>Select Countries</TextStyle>
                                                                    <Badge status="info">{this.state.selected_countries.length}</Badge>
                                                                </Stack>
                                                            </Button> 
                                                        }
                                                        id="ms-selected_countries"
                                                        checked={this.state.selectCountryModalOpen}
                                                        onChange={()=>{
                                                            if(!this.state.selectCountryModalOpen){
                                                                store("shipping_selected_countries", JSON.stringify(this.state.selected_countries));
                                                                store("all_shipping_rules", JSON.stringify(this.state.allShippingRules));
                                                                store("shipping_state", JSON.stringify({
                                                                    show: this.state.show,
                                                                    edit_rule_id: this.state.edit_rule_id,
                                                                    customer_group: this.state.customer_group,
                                                                    customer_tags: this.state.customer_tags,
                                                                }));
                                                            }
                                                            this.setState({selectCountryModalOpen: !this.state.selectCountryModalOpen});
                                                        }}
                                                        error={this.state.selected_countries.length===0 && this.state.customer_tags.length > 0  ? "Select at least one country" : this.state.validation_error && this.state.validation_error.state === "selectCountryModalOpen" ? this.state.validation_error.msg : false}
                                                    />
                                            </div>
                                        }

                                        {customer_group === "tag_based" && this.state.customer_tags.length === 0 &&
                                            <InlineError message="Customer tags are required" fieldID="selected-countries" />
                                        }
                                    </Stack>
                                </div>
                            }
                        </div>
                    </div>
                </Card.Section>
            </Card>
            <Card title="Custom shipping charges">
                <Card.Section>
                    {shipp_charges_type}
                </Card.Section>
                {
                    (this.state.charges_type !== "price_range" && this.state.charges_type !== "price_range_percentage") && 
                    <Card.Section title="Minimum cart requirements">
                    <Stack vertical spacing="tight">
                        <Checkbox
                            label="Enable minimum cart requirements"
                            checked={!ignore_rules}
                            onChange={(v)=>{this.setState({ignore_rules:!ignore_rules})}}
                        />
                        {
                            !ignore_rules? 
                                <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                                    <FormLayout.Group>
                                        <div>
                                            Must match:&nbsp;&nbsp;&nbsp;
                                            <RadioButton
                                                name="condition_operator"
                                                checked={rules_operator === "and"}
                                                id="and"
                                                label="All conditions"
                                                onChange={(checked, value) => {
                                                    this.setState({ rules_operator: value });
                                                }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <RadioButton
                                                name="condition_operator"
                                                checked={rules_operator === "or"}
                                                id="or"
                                                label="Any condition"
                                                onChange={(checked, value) => {
                                                    this.setState({ rules_operator: value });
                                                }}
                                            />
                                        </div>
                                    </FormLayout.Group>
                                    {conditions_section}
                                    <FormLayout.Group fullWidth>
                                        <Button
                                            disabled={rules.length >= 3 ? true : false}
                                            onClick={this.addCondition.bind(this)}
                                        >
                                            Add another condition
                                        </Button>
                                    </FormLayout.Group>
                                </div>
                            :null
                        }
                    </Stack>
                </Card.Section>
                }
                {
                    this.state.charges_type === "fixed_price" &&
                    <Card.Section>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    label={<span>Shipping charges <span className="required">*</span></span>}
                                    type="text"
                                    placeholder={zeroCurrency ? zeroCurrency.amount : null}
                                    prefix={zeroCurrency ? zeroCurrency.symbol : null}
                                    suffix={!charges || Number(charges) === 0 ? <Badge>Free</Badge> : ""}
                                    value={getValue(charges)}
                                    onChange={(v) => {
                                        this.setState({ charges: v });
                                    }}
                                    onBlur={()=>{
                                        if(charges && charges !== ""){
                                            let currency = Common.getCurrency(charges, money_format);
                                            this.setState({ charges: currency.amount });
                                        }
                                    }}
                                />
                                <div></div>
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                }
                {
                    (charges_type === "price_range" || charges_type === "price_range_percentage" ) &&
                    <>
                        <Card.Section title="Price Range Based On">
                            <FormLayout>
                                <Stack vertical spacing="extraTight">
                                <RadioButton
                                    name="range_based_on"
                                    label="Cart Total Amount (Subtotal)"
                                    id="cart_original_total_price"
                                    checked={range_based_on === "cart_original_total_price"}
                                    onChange={(v,id) => {
                                        /* Resetting Range Values */
                                        this.clearRange();
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                <RadioButton
                                    name="range_based_on"
                                    label="Cart Total Items (Quantity)"
                                    id="cart_item_count"
                                    checked={range_based_on === "cart_item_count"}
                                    onChange={(v,id) => {
                                        /* Resetting Range Values */
                                        this.clearRange();
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                <RadioButton
                                    name="range_based_on"
                                    label={"Cart Total Weight ("+this.state.weight_unit_label+")"}
                                    id="cart_total_weight"
                                    checked={range_based_on === "cart_total_weight"}
                                    onChange={(v,id) => {
                                        /* Resetting Range Values */
                                        this.clearRange();
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                </Stack>
                            </FormLayout>
                        </Card.Section>
                        <Card.Section>
                            <FormLayout>
                                {price_range_ui}
                                <FormLayout.Group>
                                    <Button onClick={()=>{ this.addShippingRange() }}>Add more</Button>
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </>
                }
            </Card>
            <div className="bottom-line"></div>
            <div className="bottom-save-buttons">
                <ButtonGroup>
                    <Button
                        url={BUILD_VERSION_EXTENSION+"/shipping"}
                        target="APP"
                    >Cancel</Button>
                    <Button primary
                        icon={ Common.getPlanID() === 1 && LockMinor }
                        onClick={ () => this.saveRule() }
                        disabled={ Common.getPlanID() === 1?true:this.needToUpgrade?true:false }
                        loading={ this.state.saveButtonLoading }
                    >Save Shipping</Button>
                </ButtonGroup>
            </div>
            <div className="clearfix"></div>
        </>;

        var shippingNotWorkingBanner = null;
        if(this.state.settings){
            if(this.state.settings.discount_method && this.state.settings.discount_method === "coupon_code"){
                shippingNotWorkingBanner = <div style={{marginBottom: "2rem"}}><Banner status="critical">
                    Shipping doesn't work with Coupon Code discount method. <Link url={BUILD_VERSION_EXTENSION+"/settings"} target="APP">Change Discount Method</Link> Or Contact us
                </Banner></div>;
            }
        }

        const modal = <>
        <Modal
            size="Large"
            open={this.state.selectCountryModalOpen}
            title="Select Countries"
            src={BUILD_VERSION_EXTENSION+"/select-shipping-countries"}
            primaryAction={
                {
                    content: "Select",
                    onAction: () => {
                      var shipping_selected_countries = store("shipping_selected_countries") || [];
                      try {
                          if(shipping_selected_countries){
                          shipping_selected_countries = JSON.parse(shipping_selected_countries);
                          }
                      } catch (error) {}
  
                      // Remove all shipping rules from store
                      store.remove("all_shipping_rules");
                      store.remove("shipping_state");
  
                      this.setState({selectCountryModalOpen: false, selected_countries: shipping_selected_countries})
                    }
                  }
            }
            secondaryActions={[
              {
                  content: "Cancel",
                  onAction: () => {
                      this.setState({selectCountryModalOpen: false})
                  }
              }
            ]}
            onClose={()=>this.setState({selectCountryModalOpen:false})}
          >
        </Modal>
        </>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
        ): (null);

        if(this.state.page_loaded){
            return (
                <div className="waio--manage--shipping_grid">
                    <PageTitleBar
                        title={this.state.show === "add"?"Add":"Edit"}
                        breadcrumbs={[{content: 'Manage shipping', url: BUILD_VERSION_EXTENSION+'/manage-shipping', target: "APP"}]}
                        primaryAction={{icon: Common.getPlanID() === 1?LockMinor:false, content:" Save ", onAction: () => this.saveRule(), disabled: Common.getPlanID() === 1 ? true : this.state.saveButtonLoading }}
                        secondaryActions={[
                            {
                                content:"Cancel",
                                url: BUILD_VERSION_EXTENSION+"/shipping",
                                target: "APP"
                            }
                        ]}
                    />
                    {modal}
                    {toast}
                    {
                        Common.getPlanID() === 1 &&
                        <div style={{marginBottom: "2rem"}}>
                            <Banner status="critical" title="Action Required">
                                <p>Manage Shipping feature is not available in Basic plan. Please <Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"} target="APP" monochrome={true}>Upgrade plan</Link> to Professional to continue with Manage Shipping feature.</p>
                            </Banner>
                        </div>
                    }
                    {shippingNotWorkingBanner}
                    {
                        this.needToUpgrade ? UpgradeBanner: null
                    }
                    {add_or_edit_section}
                </div>
            );
        }
        else{
            return(<SkeletonPageComp/>);
        }
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}